import { BoxProps, Button, styled, Typography } from "@mui/material"
import { Box, SxProps } from "@mui/system"
import { CSSProperties } from "react"
import { rootImage } from "../../../core/rootImages"
import { rootRouters } from "../../../core/rootRouter"
import { BoxGradient } from "../../../Custom"
import { Clock } from "../clock/Index"

export const BannerPublicitario = () => {



  return (
    <Box sx={WrapperBoxStyles} className="banner-publicitario" mb="100px" mt={'75px'}>
      <Gradient>
        <ImgFloating src={rootImage.tikects.exclusive} className='img-floating-banner-publicitario' />

        <FlexItemsWrapper>
          <Box flex={{sm: "1 0", xs: '1 0 100%'}} display="flex" justifyContent="flex-end" sx={{ justifyContent: { xs: 'center', sm: 'right'}}}>
            <Box width={"100%"}>
              <Title variant="h5">Pre-sale of</Title>

              <PromotionText variant="h2">1.1111 NFT</PromotionText>

              <Box flex={{md: "0 1", xs: '1'}} display={{xs: 'none', sm: 'block'}}>
                <Typography
                  fontSize={{ xs: '12px', sm: '12px', md: "15px", lg: "18px", xl: "20px" }}
                  textAlign={{sm: "right", xs: 'left'}}
                  variant="body2"
                  ml={{ xs: '20px', sm: '0'}}
                  mt={{ xs: '10px', sm: '0'}}
                  lineHeight={{ xs: '1.7', sm: '2'}}
                  textTransform={"uppercase"}
                  fontWeight="500"
                >
                  Common 1000 nft at {" "} <span> 7 usd /</span> rare 100 nft at{" "}
                  <span>15 usd</span>
                </Typography>
                <Typography
                  fontSize={{ xs: '12px', sm: '12px', md: "15px", lg: "18px", xl: "20px" }}
                  textAlign={{sm: "right", xs: 'left'}}
                  ml={{ xs: '20px', md: '0'}}
                  lineHeight={{ xs: '1.7', md: '2'}}
                  variant="body2"
                  textTransform="uppercase"
                >
                  Exclusive 10 nft at <span>40 usd /</span> unique 1 nft at{" "}
                  <span>85 usd</span>
                </Typography>
              </Box>

              <Box flex={{md: "0 1", xs: '1'}} display={{xs: 'block', sm: 'none'}} maxWidth="300px" margin="auto">
                <Typography
                  fontSize={{ xs: '15px', sm: '12px', md: "15px", lg: "18px", xl: "20px" }}
                  textAlign={{sm: "right", xs: 'left'}}
                  variant="body2"
                  ml={{ xs: '20px', sm: '0'}}
                  mt={{ xs: '10px', sm: '0'}}
                  lineHeight={{ xs: '1.7', sm: '2'}}
                  textTransform={"uppercase"}
                  fontWeight="500"
                >
                  Common 1000 nft at {" "} <span> 15 usd </span> 
                </Typography>
                <Typography
                  fontSize={{ xs: '15px', sm: '12px', md: "15px", lg: "18px", xl: "20px" }}
                  textAlign={{sm: "right", xs: 'left'}}
                  variant="body2"
                  ml={{ xs: '20px', sm: '0'}}
                  lineHeight={{ xs: '1.7', sm: '2'}}
                  textTransform={"uppercase"}
                  fontWeight="500"
                >
                  rare 100 nft at{" "} <span>40 usd</span>
                </Typography>
                <Typography
                  fontSize={{ xs: '15px', sm: '12px', md: "15px", lg: "18px", xl: "20px" }}
                  textAlign={{sm: "right", xs: 'left'}}
                  ml={{ xs: '20px', md: '0'}}
                  lineHeight={{ xs: '1.7', md: '2'}}
                  variant="body2"
                  textTransform="uppercase"
                >
                  Exclusive 10 nft at <span>80 usd </span>
                </Typography>
                <Typography
                  fontSize={{ xs: '15px', sm: '12px', md: "15px", lg: "18px", xl: "20px" }}
                  textAlign={{sm: "right", xs: 'left'}}
                  ml={{ xs: '20px', md: '0'}}
                  lineHeight={{ xs: '1.7', md: '2'}}
                  variant="body2"
                  textTransform="uppercase"
                >
                   unique 1 nft at{" "}
                  <span>18 usd</span>
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            position={'relative'}
            flex={{ xs: '1' ,sm: "0 1 auto" , md: "0 1 25%" }}
            height="100%"
            display="flex"
            alignItems='center'
            justifyContent={'space-around'}
            flexDirection='column'
          >
            <Clock deadline={"June, 10, 2023"} />

            <Box display="flex" mt="18px">
              <Button color="secondary" variant="contained" sx={{ borderRadius: '25px' }} href={rootRouters.home.preSale}>Buy a NFanst</Button>
            </Box>
          </Box>
        </FlexItemsWrapper>
      </Gradient>
    </Box>
  )
}

const fontCommonStyles: CSSProperties = {
  textTransform: 'uppercase',
  fontWeight: '800',
}

const Gradient = styled(BoxGradient)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    padding: "20px 20px "
  },
  [theme.breakpoints.up("lg")]: {
    padding: "50px 50px "
  },
  [theme.breakpoints.up("md")]: {
    padding: "50px 30px"
  },
}))
const WrapperBoxStyles: SxProps = {
  width: "100%",
  position: "relative",
  overflow: "hidden",
  borderRadius: "25px",
}

const FlexItemsWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  gap: "15px",
  position: "relative",
  zIndex: "20",
  flex: '',
  alignItems: 'center',
  [theme.breakpoints.up("lg")]: {
    gap: "5%",
  },
  [theme.breakpoints.down("sm")]: {
    flexWrap: "wrap",
  },
}))

const ImgFloating = styled("img")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  [theme.breakpoints.up("xl")]: {
    maxWidth: "35%",
    left: "-13%",
  },
  [theme.breakpoints.down("xl")]: {
    maxWidth: "35%",
    left: "-16%",
  },
  [theme.breakpoints.down("sm")]: {
    display: 'none'
  },
}))

const Title = styled(Typography)(({ theme }) => ({
  ...fontCommonStyles,
  [theme.breakpoints.up('xs')]: {
    textAlign: 'center',
    marginRight: '0',
    fontSize: '30px',
  },
  [theme.breakpoints.up('sm')]: {
    textAlign: 'right',
    marginRight: '25px',
    fontSize: '25px',
  },
  [theme.breakpoints.up('lg')]: {
    marginRight: '55px',
    fontSize: '35px',
  },
}))

const PromotionText = styled(Typography)(({ theme }) => ({
  ...fontCommonStyles,
  color: theme.palette.secondary.main,
  [theme.breakpoints.up('xs')]: {
    textAlign: 'center',
    fontSize: '60px',
  },
  [theme.breakpoints.up('sm')]: {
    textAlign: 'right',
    fontSize: '55px',
  },
  [theme.breakpoints.up('md')]: {
    textAlign: 'right',
    fontSize: '65px',
  },
  [theme.breakpoints.up('lg')]: {
    textAlign: 'right',
    fontSize: '100px',
  },
}))