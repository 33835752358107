import { ChangeEvent } from "react"
import { Stack, Avatar, TextField } from "@mui/material"
import { CropOriginalRounded, InsertLinkRounded } from "@mui/icons-material"
import { Container } from "../../../../app/Custom"
import { useAppDispatch } from "../../../../app/hooks"
import { messageActions } from "../../../../store/slice/Message.slice"
import { rootImage } from "../../../../app/core/rootImages"

export const InputMessage = () => {
  const dispatch = useAppDispatch()

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const showBody = event.target.value.length > 0
    dispatch(messageActions.setShowBody(showBody))
  }

  return (
    <Container>
      <Stack direction={"row"} alignItems="center" spacing="2" gap="15px">
        <Avatar sx={{ width: "45px", height: "45px" }} alt="raul" src={rootImage.avatar.perfil} />
        <TextField
          hiddenLabel
          id="send-message"
          placeholder="Send a Message"
          fullWidth
          onChange={handleChange}
          size="small"
        />

        <Stack direction={"row"} spacing="2" gap="8px">
          <CropOriginalRounded />
          <InsertLinkRounded />
        </Stack>
      </Stack>
    </Container>
  )
}
