import { Box } from "@mui/material"
import { ToastContainer } from "react-toastify"

// My Imports
import { AppFooter, LayaoutSection } from "../../../app/components/layout"
import { BannerPublicitario, SideBar } from "../../../app/components/UI"
import { BannerTikect } from "./BannerTikect"
import { Contanto } from "./Contanto"
import { CustomHeader } from "./CustomHeader"
import { Faqs } from "./Faqs"
import { RoadMap } from "./RoadMap"
import { useClock } from "../../../app/hooks"
import { useMemo, useState } from "react"
import Banner from "./Banner"
import Benefits from "./Benefits"
import NFriends from "./NFriends"

export const LadingPage = () => {
  const DateMemo = useMemo(() => new Date(new Date().setMinutes(new Date().getMinutes() + 1)).toString(), [])

  const { seconds } = useClock(DateMemo)
  const [show, setShow] = useState(false)

  if (seconds === 50 && !show) {
    setShow(true)
  }

  if(seconds === 55) {
    (window as any)?.cookieconsent?.initialise({
      cookie: {
         domain : "/",
         name: "cookie_consent",
       },
       palette:{
         popup:  { background: "#e8ecf7"  },
         button: { background: "#181818"},
       },
       content:{
         message: "We use cookies to offer you a better browsing experience and analyze site traffic. If you continue to use this site, you consent to our use of cookies",
       },
    })
  }

  return (
    <Box position={'relative'}>
      <LayaoutSection bgColor="#f4f7fd" fullHeight={false}>
        <CustomHeader />
        <SideBar />
      </LayaoutSection>

      <LayaoutSection fullHeight={false}>
        <BannerPublicitario />
        <div id="about" />
        <NFriends />
      </LayaoutSection>

      <LayaoutSection
        bgColor="#f4f7fd"
        fullHeight={false}
        sx={{
          backgroundColor: "#f4f7fd",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <BannerTikect />

        <div id="membership-benefits" />
        <Benefits />

        <div id="roadmap" />
        <RoadMap />
      </LayaoutSection>

      <div id="FAQs" />
      <LayaoutSection fullHeight={false}>
        <Faqs />
      </LayaoutSection>

      <div id="contact" />
      <LayaoutSection bgColor="#f4f7fd" fullHeight={false}>
        <Contanto />
      </LayaoutSection>

      <div id="footer" />
      <AppFooter />

      <ToastContainer />
      <Banner setShow={setShow} show={show} />
    </Box>
  )
}
