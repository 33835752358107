import {
  Box,
  BoxProps,
  Grid,
  styled,
  SxProps,
} from "@mui/material"
import { CardImageItem } from "../../../app/components/UI/cards/CardImageItem"
import { rootImage } from "../../../app/core/rootImages"
import { BasicCardContainer } from "../../../app/Custom"
import { DescriptionPartido } from "./DescriptionPartido"
import { DuetoDetail } from "./DuetoDetail"

export const DuetosPage = () => {
  return (
    <BasicCardContainer sx={{ bgcolor: "#fff" }}>
      <DuetoDetail />

      <Grid container spacing={2} my="25px">
        <Grid item xs={12} sm={3}>
          <CardImageItem
            sx={{
              height: "100% !important",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            url={rootImage.camisas.barca1}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <CardImageItem
            sx={{
              height: "100% !important",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            url={rootImage.camisas.barca2}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <CardImageItem
            sx={{
              height: "100% !important",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            url={rootImage.camisas.barca1}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <CardImageItem
            sx={{
              height: "100% !important",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            url={rootImage.camisas.barca3}
          />
        </Grid>
      </Grid>

      <BoxBanner>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} sx={GridCenterContiner}>
            <img
             
              style={{ maxWidth: '100%', maxHeight: '200px' }}
              src={rootImage.logo.football.barcelonaWithe}
            />
          </Grid>

          <Grid item xs={12} md={6} >
            <DescriptionPartido />
          </Grid>

          <Grid item xs={12} md={3} sx={GridCenterContiner}>
            <img
              style={{ maxWidth: '100%', maxHeight: '200px' }}
              src={rootImage.logo.football.realmadritWithe}
            />
          </Grid>
        </Grid>
      </BoxBanner>
    </BasicCardContainer>
  )
}

const GridCenterContiner: SxProps = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}

const BoxBanner = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundImage: `url( ${rootImage.banner.estadioLiso} )`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
  backgroundSize: "116% 130%",
  borderRadius: "23px",
  padding: "33px 55px",
  position: "relative",
  [theme.breakpoints.down("lg")]: {
    padding: "33px 15px",
  },
}))
