import { Button, ButtonProps, colors, darken, styled } from "@mui/material"
import LoadingButton from '@mui/lab/LoadingButton'

export const BtnPrimary = styled(Button)<ButtonProps>(({ theme }) => ({
  background: theme.palette.secondary.main,
  borderRadius: '15px',
  width: '150px',
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
  },
  '&:disabled': {
    backgroundColor: theme.palette.secondary.dark,
    color: '#c2c2c2',
  },
}))

export const BtnSecondary = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: '#fff',
  borderRadius: '30px',
  width: '200px',
  height: '34px',
  '&:hover': {
    backgroundColor: 'rgb(52 144 214 / 75%)',
  },
}))

export const FormButton = styled(LoadingButton)(({ theme }) => ({
  color: "#fff",
  marginTop: "8px"
}))

export const ButtonBuy = styled(Button)<ButtonProps>(({ theme }) => ({
  background: theme.palette.primary.main,
  color: '#fff',
  borderRadius: '45px',
  fontSize: '24px',
  height: '41px',
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
}))

export const ButtonCreateNew = styled(Button)<ButtonProps>(({ theme }) => ({
  background: theme.palette.secondary.main,
  color: '##fff',
  borderRadius: '10px',
  fontSize: '32px',
  maxWidth: '20rem',
  display: 'flex',
  padding: '16px',
  justifyContent: 'center',
  textTransform: 'capitalize',
  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
  },
}))
export const ButtonWhite = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: colors.common.white,
  color: colors.common.black,
  '&:hover, &:disabled': {
    backgroundColor: darken(colors.common.white, 0.3),
    color: darken(colors.common.black, 0.3),
  },
  '&:active': {
    transform: 'translateY(2px)',
  },
}))
