import { Box, BoxProps, Grid, styled } from "@mui/material"

// My imports
import { rootImage } from "../../../app/core/rootImages"
import {
  BtnPrimary,
  ResponsiveImage,
  SubTitle,
  TituloPrincipal,
} from "../../../app/Custom"

import { GridProps } from "@mui/system"
import { rootRouters } from "../../../app/core/rootRouter"

export const BannerPrincipal = () => {
  return (
    <GridHome container  spacing={2}>
      <Grid item xs={12} md={6}>
        <BoxTextWrapper>
          <TituloPrincipal variant="h1" fontWeight={500}>
            The Exclusive and limited <strong>Fan Club</strong>
          </TituloPrincipal>
          <Box>
            <SubTitle variant="body1">
              Join our fan club by purchasing an NFT from our exclusive collection
              and start enjoying the privileges of being a part.
            </SubTitle>
            <SubTitle mt={'15px'}>
              Your NFT doubles as a VIP card to an exclusive and limited web 3.0
              community, and grants access to members-only benefits and the
              opportunity to discover a host of new VIP experiences and content
              that they would not be able to enjoy otherwise.
            </SubTitle>
            <SubTitle mt={'15px'}>
              NFansT is developed through Polygon network blockchain and created
              to have governance as a club-member, it's personal and
              referenceable.
            </SubTitle>
          </Box>
            <BtnPrimary variant="contained" size="small" href={rootRouters.home.BuyNFT}>
              Buy NFansT
            </BtnPrimary>
        </BoxTextWrapper>
      </Grid>

      <Grid item xs={12} md={6}>
        <div className="floating-continer">
          <div className="floating-avatar">
            <ResponsiveImage
              src={rootImage.tiket}
              sx={{ display: { xs: "none", md: "block" }, margin: "auto"}}
            />
          </div>
          <BoxWithBlur />
        </div>
      </Grid>
    </GridHome>
  )
}

const GridHome = styled(Grid)<GridProps>(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    padding: "0",
    marginTop: "80px",
    marginBottom: "150px",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "0",
    marginTop: "25px",
    marginBottom: "50px",
  },
}))

const BoxTextWrapper = styled(Box)<BoxProps>(({ theme}) => ({ 
  display: 'flex',
  flexDirection: "column",
  marginTop: "25px",
  height: "100%",
  [theme.breakpoints.up("lg")]: {
    gap: "55px",
  },
  [theme.breakpoints.down("lg")]: {
    gap: "25px",
  },
}))

const BoxWithBlur = styled(Box)<BoxProps>(({ theme }: any) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundImage: `url(${rootImage.blurAzul})`,
  backgroundSize: 'cover',
  backgroundPosition: "center center",
  backgroundRepeat: "no-repeat",
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "90%",
  height: '90%',
  zIndex: '1',
  [theme.breakpoints.up("md")]: {
  },
  "&.is-floating": {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}))