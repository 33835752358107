export const rootRouters = {
  auth: {
    login: 'auth/login',
    register: 'auth/register',
  },
  home: {
    ladingPage: '/',
    BuyNFT: '/buy-nft',
    preSale: '/pre-sale',
    claim: '/claim-nft'
  },
  dashboard: {
    index: '/dashboard/*',
    home: '/',
    shop: {
      index: '/shop',
      item: '/shop/:itemId',
    },
    live: '/live',
    news: '/news',
    duetos: '/duetos',
    wallet: '/wallet',
    survey: '/survey',
    specialEvent: '/special-event',
    myProfile: '/my-profile',
    raffles: '/raffles',
  },
  admin: {
    home: '/',
    permissions: '/permissions',
    news: '/news',
    shop: '/shop',
    users: '/users',
    messages: '/messages',
    wallet: '/wallet',
    marketplace: '/marketplace',
    specials: '/special-events',
    liveshopping: '/live-shopping',
    raffles: '/raffles',
    surveys: '/surveys',
    duets: '/duets',
  },
  duet: {
    index: '/dashboard-duete/*',
    home: '/'
  }
}
