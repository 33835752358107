import { useState, ChangeEvent, FormEvent } from "react"
import { LoadingButton } from "@mui/lab"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography } from "@mui/material"
import { apiConfig } from "../../../api/Api.config"
import { Container } from "../../../app/Custom"
import { useAppSelector, useRequest } from "../../../app/hooks"
import { AnnouncementOutlined, CheckCircleOutline, Warning } from "@mui/icons-material"
import { LoadingModal } from "../../../app/components/UI"
import { toast } from "react-toastify"

export const DataBasePage = () => {
  const { wallet } = useAppSelector(state => state.auth)
  const { sendRequest, isLoading } = useRequest()

  const [password, setPassword] = useState('')
  const [deleteDb, setDeleteDb] = useState(false)
  const [error,setError] = useState(false)
  const [sucess, setSuccess] = useState(false)

  const handleSummit = () => {
    setDeleteDb(true)
  }

  const onChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPassword(event.target.value)
  }

  const onSumit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    sendRequest(apiConfig.post('/reset', {
      code: password
    })).then((res) => {
      console.log(res)
      toast.success('Data base clean')
    }).catch((err) => {
      if(err.response?.data?.message === 'Code is not match') {
        toast.error('Password not valid')
        setError(true)
        return
      }
    })
  }

  return (
    <>
      <Box>
        <Container>
          <Stack direction='row' justifyContent={'space-between'} alignItems="center">
            <Typography variant='subtitle1'>Clean Data Base</Typography>
            <LoadingButton color='primary' variant='contained' onClick={handleSummit}>Clean Data Base</LoadingButton>
          </Stack>
        </Container>

        {
          deleteDb && <Container mt="15px">
            <Stack direction='row' alignItems={'center'} spacing={3}>
              <Warning color="warning" fontSize="medium" />
              <Typography variant='h6'>To delete the database you must enter the password</Typography>
            </Stack>

            <Box mt="10px">
              <form action="#" onSubmit={onSumit}>
                <Typography variant='subtitle1'>Password</Typography>
                <Stack alignItems={'center'} direction='row' spacing={2}>
                  <TextField fullWidth type={'password'} size='small' onChange={onChange } required  value={password} />
                  <Button variant='contained' color='secondary' type="submit" sx={{ borderRadius: '0' }} >Submit</Button>
                </Stack>
              </form>
            </Box>
          </Container>
        }
      </Box>
{/* 
      <Dialog open={false}>
        <LoadingModal title={"Verificando..."} />
      </Dialog>

      <Dialog open={isLoading}>
          <DialogTitle>
            <Stack direction='row' alignItems={'center'} spacing={4} gap='10px'>
              <AnnouncementOutlined color='warning' fontSize="large" />
              Password invalid
            </Stack>
          </DialogTitle>

          <DialogContent>
            <Typography variant='subtitle1'>to delete the database you must enter the valid password</Typography>
          </DialogContent>

          <DialogActions>
            <Button variant='contained' onClick={()=> setError(false)} color='error'>Close</Button>
            <Button variant='contained' onClick={()=> setError(false)} color='secondary'>ok</Button>
          </DialogActions>
        </Dialog>
      

      <Dialog open={false}>
        <Box>
          <DialogTitle>
            <Stack direction='row' alignItems={'center'} spacing={4} gap='10px'>
              <CheckCircleOutline color='success' fontSize="large" />
              The database has been cleaned
            </Stack>
          </DialogTitle>

          <DialogContent>
            <Typography variant='subtitle1'>Once the database is cleaned, you will not be able to recover the previous data</Typography>
          </DialogContent>

          <DialogActions>
            <Button variant='contained' onClick={() => setSuccess(false)} color='error'>Close</Button>
            <Button variant='contained' onClick={() => setSuccess(false)} color='secondary'>Ok!</Button>
          </DialogActions>
        </Box>
      </Dialog> */}
    </>
  )
}
