import {
  Box,
  Stack,
  Typography,
  Chip,
  styled,
  Grid,
  Button
} from "@mui/material"
import {
  EmailOutlined,
  GradeTwoTone,
  KeyboardArrowDownOutlined,
  MarkAsUnreadOutlined,
  ViewStreamOutlined
} from "@mui/icons-material"

import { rootImage } from "../../../app/core/rootImages"
import { Container } from "../../../app/Custom"
import { MessageCard } from "./MessageCard"
import { InputMessage } from "./MessageInputs/InputMessage"
import TextEditor from "./MessageInputs/TextEditor"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { messageActions } from "../../../store/slice/Message.slice"


export const MessagePage = () => {
  const dispatch = useAppDispatch()
  const { messages, view } = useAppSelector(state => state.message)

  const filterMessage = messages.filter((message) => {
    if(view === 'all') {
      return true
    }

    if(view === 'unread') {
      return message.isNew
    }

    if(view === 'favorite') {
      return message.isFavorite
    }
  })
  
  const renderMessages = filterMessage.map(({ body, title, user, isNew, isFavorite, posted }, idx) => <MessageCard key={idx} user={user} isNew={isNew} isFavorite={isFavorite} posted={posted} body={body} title={title} />)

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={7}>
          <InputMessage />
          <TextEditor />


          <Container mt='15px'>
            <Stack direction='row' alignItems={'center'} spacing={2} overflow={{ xs: 'scroll', md: 'hidden' }} pb={{ xs: '10px', md: '0' }}>

              <CustomChip icon={<EmailOutlined fontSize="medium" />} onClick={() => dispatch(messageActions.sortFor('all'))} color='secondary' label="All Messages" variant={view === 'all' ? 'filled' : 'outlined'} />
              <CustomChip icon={<MarkAsUnreadOutlined fontSize="medium" />} onClick={() => dispatch(messageActions.sortFor('unread'))} color='secondary' label="Unread" variant={view === 'unread' ? 'filled' : 'outlined'} />
              <CustomChip icon={<GradeTwoTone fontSize="medium" />} onClick={() => dispatch(messageActions.sortFor('favorite'))} color='secondary' label="Important" variant={view === 'favorite' ? 'filled' : 'outlined'} />

              <Box display={'flex'}>
                <ViewStreamOutlined />
                <KeyboardArrowDownOutlined />
                {/* <ViewListOutlined /> */}
              </Box>
            </Stack>
          </Container>

          <Box mt="15px">
            {renderMessages}
          </Box>

        </Grid>
        <Grid item xs={12} md={5}>
          <Container>
            <Typography variant='h5' sx={{ display: 'flex', alignItems: 'center', gap: '10px' }} fontWeight={'800'}><img src={rootImage.logo.discord} height='25px' /> Discord</Typography>
            <Typography variant='body1'>Join our community on discord</Typography>
            <Button variant='contained' fullWidth sx={{ borderRadius: '5px', mt: '8px' }}>Discord</Button>
          </Container>

          <Container mt='15px'>
            <Typography variant='h5' fontWeight={'800'}>Your opinion matters</Typography>
            <Typography variant='body1' mt='5px'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sint, et.</Typography>
            <Typography variant='h5' fontWeight={'800'} mt='8px'>Coming soon general chat and private chat</Typography>
            <Typography variant='body1' mt='5px'>delectus praesentium nulla illum sequi exercitationem reiciendis sapiente eum dicta minima numquam maxime omnis voluptatem placeat cumque amet minus reprehenderit, voluptas fuga facere? Cupiditate omnis repudiandae facilis voluptas animi reiciendis eius molestias earum dolore explicabo velit saepe voluptates quod, officiis accusantium!</Typography>
          </Container>
        </Grid>
      </Grid>
    </Box>
  )
}


const CustomChip = styled(Chip)(({ theme }) => ({
  padding: theme.spacing(2, 1),
  color: '#000',
  fontWeight: '500',
}))