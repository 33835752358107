import {
  Paper,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material"
import { useDispatch } from "react-redux"

import { rootImage } from "../../../../app/core/rootImages"
import { CardBuyActions } from "../../../../store/slice/CardBuyNFT"

export const Boxdrop = () => {
  const dispatch = useDispatch()

  const selectNewOption = (type: TypeNFT) => {
    return () => {
      dispatch(CardBuyActions.changeNFT({ type }))
      dispatch(CardBuyActions.toggleBoxdrop())
    }
  }

  return (
    <Paper sx={{ m: 1 }} elevation={4}>
      <List sx={{ paddingTop: "26px;" }}>
        <ListItem disablePadding onClick={(selectNewOption("common"))}>
          <ListItemButton>
            <ListItemIcon>
              <img src={rootImage.icons.common} width="25px" />
            </ListItemIcon>
            <ListItemText primary="Common" />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding onClick={(selectNewOption("rare"))}>
          <ListItemButton>
            <ListItemIcon>
              <img src={rootImage.icons.rare} width="25px" />
            </ListItemIcon>
            <ListItemText primary="Rare" />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding onClick={(selectNewOption("exclusive"))}>
          <ListItemButton>
            <ListItemIcon>
              <img src={rootImage.icons.exclusive} width="25px" />
            </ListItemIcon>
            <ListItemText primary="Exclusive" />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding onClick={(selectNewOption("unique"))}>
          <ListItemButton>
            <ListItemIcon>
              <img src={rootImage.icons.unique} width="25px" />
            </ListItemIcon>
            <ListItemText primary="Unique" />
          </ListItemButton>
        </ListItem>
      </List>
    </Paper>
  )
}

type TypeNFT = "common" | "rare" | "exclusive" | "unique"
