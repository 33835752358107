import { Box, Typography } from "@mui/material"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Pagination, Autoplay } from "swiper"

import "swiper/swiper-bundle.css"
import "swiper/css"

// My imports ----------------------------------------
import { RoadChip } from "../../../app/components/UI"
import { rootImage } from "../../../app/core/rootImages"

const DUMMY_DATA = [
  {
    icon: rootImage.icons.tokens,
    number: "I",
    text: "Pre-sale of 1.111 Nfts."
  },
  {
    icon: rootImage.icons.sales,
    number: "II",
    text: "Launch First Edition NFTS Collection: 9.999 NFT"
  },
  {
    icon: rootImage.icons.equipo,
    number: "III",
    text: "Pre-sale of new items before they hit the market"
  },
  {
    icon: rootImage.icons.nft,
    number: "IV",
    text: "Exclusive auction for members once a week starting one months after official Launch"
  },
  {
    icon: rootImage.icons.exclusiveIcon,
    number: "V",
    text: "Official merchandising Launch for Members"
  },
  {
    icon: rootImage.icons.trading,
    number: "VI",
    text: "Complementary NFT Airdrop gift for all NFT Owners"
  },
  {
    icon: rootImage.icons.acquisition,
    number: "VII",
    text: "Pre-sale of Second Edition Collection of 1.111 NFTs"
  },
  {
    icon: rootImage.icons.consult,
    number: "VIII",
    text: "Launch of Second Edition Collection of 9.999 NFTs"
  },
  {
    text: "Launch of the collaborative project for community members",
    number: "IX",
    icon: rootImage.icons.collaboration
  },
  {
    icon: rootImage.icons.token,
    number: "X",
    text: "TOKEN Airdrop for members + ICO Lauch"
  }
]

export const RoadMap = () => {
  SwiperCore.use([Autoplay])

  return (
    <Box marginTop={"80px"} paddingBottom="80px">
      <Typography textAlign={"center"} variant={"h3"} fontWeight={"bolder"}>
        Roadmap
      </Typography>

      <Swiper
        autoplay={{ delay: 3000 }}
        style={{ marginTop: "15px", height: "350px" }}
        slidesPerView={5}
        pagination={{ clickable: true }}
        modules={[Pagination]}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          900: {
            slidesPerView: 3,
          },
          1280: {
            slidesPerView: 5,
          },
        }}
      >
        {DUMMY_DATA.map(({ icon, text, number }, index) => (
          <SwiperSlide key={index}>
            <RoadChip number={number} icon={icon}>
              <Typography
                textAlign={"center"}
                variant="subtitle2"
                fontSize={"16px"}
                fontWeight={"500"}
                lineHeight="1.2"
              >
                {text}
              </Typography>
            </RoadChip>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  )
}
