import { Box, styled,  } from "@mui/material"
import { BoxProps } from "@mui/system/Box/Box"
import { Link } from "react-router-dom"
import { rootImage } from "../../../../app/core/rootImages"
import { rootRouters } from "../../../../app/core/rootRouter"
import { BtnPrimary } from "../../../../app/Custom"

export const NFT = () => {
  return (
    <BoxWrapper>
      <Box
        sx={{
          backgroundColor: "#fff",
          padding: "15px",
          borderRadius: "8px",
          display: "flex",
          maxWidth: "450px",
          margin: "auto",
        }}
      >
        <ImageResponsive src={rootImage.tikects.exclusive} />
      </Box>
      <BtnPrimary
        variant="contained"
        sx={{ backgroundColor: "primary.main", mt: "10px", mx: 'auto' }}
      >
        <Link to={`/dashboard${rootRouters.dashboard.wallet}`} style={{ color: "#fff", textDecoration: 'none' }}>
          My NFTs
        </Link>
      </BtnPrimary>
    </BoxWrapper>
  )
}

const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  height: "100%",
  display: "flex",
  justifyContent: "space-around",
  flexDirection: "column",
  padding: "15px 25px",
  borderRadius: '15px',
  [theme.breakpoints.up("sm")]: {
    padding: "15px 8px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "25px 10px",
  },
}))

const ImageResponsive = styled("img")(({ theme }) => ({
  margin: "auto",
  [theme.breakpoints.up("lg")]: {
    maxWidth: "80%",
  },
  [theme.breakpoints.down("lg")]: {
    maxWidth: "100%",
  },
}))
