import { configureStore } from '@reduxjs/toolkit'
import { CardBuyReducer } from './slice/CardBuyNFT'
import { UIReducer } from './slice/UI.slice'
import { shopReducer } from './slice/shop.slice'
import { tableReducer } from './slice/table.slice'
import { UserReducer } from './slice/User.slice'
import { messageReducer } from './slice/Message.slice'
import { notificationReducer } from './slice/notification.slice'
import { authReducer } from './slice/auth.slice'

export const store = configureStore({
  reducer: {
    ui: UIReducer,
    cardBuyNFT: CardBuyReducer,
    shop: shopReducer,
    table: tableReducer,
    user: UserReducer,
    message: messageReducer,
    notification: notificationReducer,
    auth: authReducer
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
