import { Alert, AlertColor, AlertTitle } from "@mui/material" 

export const AlertMessage = ({ severity, alertTitle, children }: Props) => {
  return (
    <Alert severity={severity}>
      <AlertTitle>{alertTitle}</AlertTitle>
      {children}
    </Alert>
  )
}

interface Props { 
  severity: AlertColor
  alertTitle: string
  children: JSX.Element | JSX.Element[]
}
