import { useWeb3React } from "@web3-react/core"
import Web3 from "web3"
import { injectedConnector } from "./connect"


// blockchain network info
// const chain = {
//   chainId: 137,
//   chainName: CHAIN_NAME_MAINNET,
//   rpcUrls: [RPC_URL_MAINNET],
//   blockExplorerUrls: [BLOCK_EXPLORER_URL_MAINNET],
//   nativeCurrency: {
//     name: CURRENCY_NAME,
//     symbol: CURRENCY_NAME,
//     decimals: CURRENCY_DECIMALS,
//   },
// }

export const checkChainId = async () => {
  const { ethereum } = window as any

  if (ethereum.chainId !== "0x13881") {
    const params = [{ chainId: "0x13881" }]
    await ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [{
        chainId: "0x13881",
        chainName: "Mumbai Testnet",
        rpcUrls: ['https://rpc.ankr.com/polygon_mumbai'],
        blockExplorerUrls: ['https://explorer-mumbai.maticvigil.com/'],
        nativeCurrency: {
          name: "MATIC",
          symbol: "MATIC", // 2-6 characters long
          decimals: 18,
        }}],
    })
    await ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: params,
    })
  }
}



/**
 * Connecting a wallet and handling exceptions 
 * @returns
 */
export function ConnectWallet() {
  const { activate, active, account, library, chainId, error } = useWeb3React()

  console.log({active, account, library, chainId, error})
  async function connect() {
    try {
      const res = await activate(injectedConnector, undefined, true)
      console.log({ res })
    } catch (e) {
      console.log(e)
    }
  }

  return [active, account, connect, library]
}

export function DisconnectWallet(setAuth: any) {
  const { deactivate, account } = useWeb3React()

  async function disconnect() {
    if (account !== localStorage.getItem("tokenData"))
      try {
        deactivate()
        localStorage.setItem("token", "")
        setAuth(false)
      } catch (ex) {
        console.log(ex)
      }
  }

  return [disconnect]
}


export const isValidAddress = (adr: string) => {
  try {
    const web3 = new Web3()
    web3.utils.toChecksumAddress(adr)
    return true
  } catch (e) {
    return false
  }
}

/**
 * Recorta una wallet de tamano original a una con tres puntos sumpensivos mas corta
 * @param wallet debe ser una wallet de metamask completa
 * @returns { string } version corta de la wallet original
 */
export const shortWallet = (wallet: string): string => {
  return wallet?.substring(0, 7) + '...' + wallet?.slice(-3)
}