import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  openSideBar: false,
}

const UISlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    toggleSideBar(state) {
      state.openSideBar = !state.openSideBar
    },
  },
})

export const UIActions = UISlice.actions
export const UIReducer = UISlice.reducer
