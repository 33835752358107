import { useMemo, useCallback } from "react"
import { createEditor, Editor, Transforms } from "slate"
import { Slate, Editable, withReact, useSlate } from "slate-react"
import { withHistory } from "slate-history"
import isHotkey from "is-hotkey"
import { Box, Button, Stack } from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import { messageActions } from "../../../../store/slice/Message.slice"
import { toast } from "react-toastify"
import { Container } from "../../../../app/Custom"

const listType: any = ["numbered-list", "bulleted-list"]
const hotKey: any = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
}
const TextEditor = () => {
  const { slate, showBody } = useAppSelector((state) => state.message)
  const dispatch = useAppDispatch()

  const editor = useMemo(() => withHistory(withReact(createEditor())), [])

  const renderElement = useCallback((props: any) => <Element {...props} />, [])
  const renderLeaf = useCallback((props: any) => <Leaf {...props} />, [])

  const handleOnClick = () => {
    toast.success("Message sent")
    dispatch(messageActions.cleanEditor())
  }

  return (
    <Container mt="5px" className={`text-editor ${showBody ? 'show-text-editor' : 'hidden-text-editor'} `}>
      <Slate
        editor={editor}
        value={slate}
        onChange={(value: any) => {
          dispatch(messageActions.textEditor(value))
        }}
      >
        <div className="toolbar">
          <ButtonBlock format="heading1" icon="fas fa-heading" />
          <ButtonBlock format="numbered-list" icon="fas fa-list-ol" />
          <ButtonBlock format="bulleted-list" icon="fas fa-list-ul" />
          <ButtonMark format="bold" icon="fas fa-bold" />
          <ButtonMark format="italic" icon="fas fa-italic" />
          <ButtonMark format="underline" icon="fas fa-underline" />
        </div>

        <Box
          sx={{
            border: "1px solid #ccc",
            borderRadius: "5px",
            mt: "5px",
            padding: "5px 10px",
          }}
        >
          <Editable
            placeholder="Say something..."
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            onKeyDown={(event) => {
              for (const key in hotKey) {
                if (isHotkey(key, event)) {
                  event.preventDefault()
                  toggleMark(editor, hotKey[key])
                }
              }
            }}
          />
        </Box>

        <Stack direction="row-reverse">
          <Button
            variant="contained"
            color="secondary"
            sx={{ mt: "5px" }}
            onClick={handleOnClick}
          >
            Send Message
          </Button>
        </Stack>
      </Slate>
    </Container>
  )
}

const toggleBlock = (editor: any, format: any) => {
  const isActive = isBlockActive(editor, format)
  const isList = listType.includes(format)

  Transforms.unwrapNodes(editor, {
    match: (n: any) => listType.includes(n.type),
    split: true,
  })

  const newType: string = isActive ? "paragraph" : isList ? "list-item" : format

  const type: any = {
    type: newType,
  }

  Transforms.setNodes(editor, type)

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const Element = ({ attributes, element, children }: any) => {
  switch (element.type) {
    case "heading1":
      return <h1 {...attributes}>{children}</h1>
    case "numbered-list":
      return <ol {...attributes}> {children} </ol>
    case "bulleted-list":
      return <ul {...attributes}> {children} </ul>
    case "list-item":
      return <li {...attributes}>{children}</li>
    default:
      return <p {...attributes}>{children}</p>
  }
}

const Leaf = ({ attributes, leaf, children }: any) => {
  if (leaf.bold) children = <strong>{children}</strong>
  if (leaf.italic) children = <em>{children}</em>
  if (leaf.underline) children = <u>{children}</u>

  return <span {...attributes}>{children}</span>
}

const isBlockActive = (editor: any, format: any) => {
  const [match] = Editor.nodes(editor, {
    match: (n: any) => n.type === format,
  }) as any
  return !!match
}

const toggleMark = (editor: any, format: any) => {
  const isActive = isMarkActive(editor, format)
  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

const isMarkActive = (editor: any, format: any) => {
  const marks: any = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const ButtonBlock = ({ format, icon }: any) => {
  const editor = useSlate()
  return (
    <Button
      sx={{ color: "#ccc" }}
      className="buttonBlock"
      onClick={() => {
        toggleBlock(editor, format)
      }}
    >
      <i className={icon}></i>
    </Button>
  )
}

const ButtonMark = ({ format, icon }: any) => {
  const editor = useSlate()
  return (
    <Button
      sx={{ color: "#ccc" }}
      onClick={() => {
        toggleMark(editor, format)
      }}
    >
      <i className={icon}></i>
    </Button>
  )
}

export default TextEditor
