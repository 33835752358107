import { Box, Fade, Grid, Typography } from "@mui/material"
  
// My Imports ================================================
import { BoxChip } from "../../../app/components/UI/BoxChip"
import { BtnSecondary } from "../../../app/Custom"
import { useHidden } from "../../../app/hooks/hiddenHooks"

export default function Benefits() {
  const {checked, handleChange} = useHidden()

  return (
    <Box>
      <Typography textAlign={"center"} variant={"h3"}>
        Benefits of
      </Typography>
      <Typography textAlign={"center"} variant={"h3"}>
        <strong> Being a member </strong>
      </Typography>

      <Box mt={"35px"}>
        <Grid container spacing={4}>
          {DUMMY_DATA.slice(0, 9).map((text, index) => (
            <Grid key={index} item xs={12} md={6} lg={4}>
              <BoxChip text={text.text} />
            </Grid>
          ))}
        </Grid>

        <Box sx={{ display: "flex" }} mt={"35px"}>
          <Fade in={checked} style={{ height: checked ? "100%" : "0" }}>
            <Grid container spacing={4}>
              {DUMMY_DATA.slice(9).map((text, index) => (
                <Grid key={index} item xs={12} md={6} lg={4}>
                  <BoxChip text={text.text} />
                </Grid>
              ))}
            </Grid>
          </Fade>
        </Box>
      </Box>

      <Box
        display={"flex"}
        mt="35px"
        alignItems="center"
        justifyContent={"center"}
      >
        <BtnSecondary size="large" onClick={handleChange}>
          <strong>{checked ? 'see less' : 'see more'}</strong>
        </BtnSecondary>
      </Box>
    </Box>
  )
}

const DUMMY_DATA = [
  {
    text: "All NFTs are a unique asset and act as ID cards to access an exclusive CLUB limited in its number of members",
  },
  {
    text: "Owning an NFT allows access to airdrops of complementary NFTs which will also have a resale value.",
  },
  {
    text: "Discounts and early access to other NFT releases from us or members of the community",
  },
  {
    text: "Discounts on official merchandise",
  },
  {
    text: "Early access to official products before their official launched",
  },
  {
    text: "Early access on official product launches or exclusive offers",
  },
  {
    text: "Being able to participate in exclusive VIP experience",
  },
  {
    text: "Being able to participate in exclusive auctions	",
  },
  {
    text: "Possibility of participating in a collaborative project among all members",
  },
  {
    text: "To receive gifts from Sponsors of the Content Owner	",
  },
  {
    text: "Access to the PFP version of the membership NFT linked to it (as a digital membership card)",
  },
  {
    text: "Access to private content",
  },
  {
    text: "Access to limited edition products",
  },
  {
    text: "Possibility of participating in decisions",
  },
  {
    text: "Launch their own NFT for the community",
  },
  {
    text: "Write message on a general board",
  },
  {
    text: "Be part of games",
  },
  {
    text: "Much and Much more",
  },
]
