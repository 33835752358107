import { useEffect, useRef, useState } from "react"

import { AutoStoriesSharp } from "@mui/icons-material"
import {
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  SxProps,
} from "@mui/material"

// My Imports
import { TermsCondition } from "../../../app/components/UI"

export const TerminosCondiciones = (props: Props) => {
  const descriptionElementRef: any = useRef<HTMLElement>(null)
  const check = useRef<any>()

  const [open, setOpen] = useState(false)
  const [isFieldRead, setIsFieldRead] = useState(false)
  
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  const onScroll = (e: any) => {
    const parrafo = document.querySelector("#scroll-div")

    const positionBottomContainer = Number(e.target.getBoundingClientRect().bottom)
    const positionBottomText = Number(parrafo?.getBoundingClientRect().bottom)


    if (Math.abs(positionBottomText - positionBottomContainer) < 2) {
      setIsFieldRead(true)
    } else {
      setIsFieldRead(false)
    }
    
  }

  const closeModal = () => {
    setIsFieldRead(false)
    setOpen(false)
  }

  const aceptTerminos = () => {
    props.setAccepTerminos(true)
    setOpen(false)
  }

  return (
    <>
      <Box sx={boxStyles}>
        <FormGroup onClick={() => setOpen(true)}>
          <Box display="flex" alignItems="center">
            <FormControlLabel
              control={<Checkbox ref={check} checked={props.acceptTerminos}  />}
              label={"I agree to terms and conditions"}
            />
            <AutoStoriesSharp />
          </Box>
        </FormGroup>

      </Box>
  
      <TermsCondition closeModal={closeModal} onScroll={onScroll} open={open} isFieldRead={isFieldRead} ref={descriptionElementRef} setOpen={setOpen} acept={aceptTerminos}/>
    </>
  )
}

interface Props {
  acceptTerminos: boolean
  setAccepTerminos: any
}

const boxStyles: SxProps = {
  display: "flex",
  alignItems: "center",
  margin: "auto",
  justifyContent: "center",
  width: "100%",
  flexDirection: "column",
}
