import { CSSProperties } from "react"
import { Box } from "@mui/material"
import { ContenedorCentrado } from "./ContenedorCentrado"

interface Props {
  children: JSX.Element | JSX.Element[]
  fullHeight?: boolean
  bgColor?: string
  sx?: CSSProperties
}

const styles: CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "25px 0",
  flexDirection: "column",
  width: '100%'
}

export const LayaoutSection = ({
  children,
  fullHeight = true,
  bgColor = "#fff",
  sx = styles,
}: Props) => {
  return (
    <Box
      sx={{
        backgroundColor: bgColor,
        minHeight: fullHeight ? "100vh" : "auto",
        display: "flex",
        alignItem: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
      className="about"
    >
      <ContenedorCentrado sx={sx}>{children}</ContenedorCentrado>
    </Box>
  )
}
