export const findErros = (errors: any): boolean => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  for (const [key, value] of Object.entries(errors)) {
    if (value) {
      return true
    }
  }
  return false
}

export const getMean = function (data: number[]) {
  return data.reduce(function (a, b) {
      return Number(a) + Number(b)
  }) / data.length
}

export const getStdDeviation = function (data: number[]) {
  const m = getMean(data)
  return Math.sqrt(data.reduce(function (sq, n) {
      return sq + Math.pow(n - m, 2)
  }, 0) / (data.length - 1))
}

export const saveLocalStorage = (name: string, data: any) => {
  localStorage.setItem(name, JSON.stringify(data))
}

export const getLocalStorage = (name: string): any => {
  const res = localStorage.getItem(name) || '{}'
  return JSON.parse(res)
}
