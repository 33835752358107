import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { UIActions } from "../../../../store/slice/UI.slice"

interface Props {
  Icon: any
  text: string
  url?: string
  id?: string | null
}

export const CustomItem = ({ Icon, text, url="#", id = null }: Props) => {
  const dispatch = useDispatch()

  const scroll = (event: React.MouseEvent) => {
    const element = document.getElementById(String(id))
    dispatch(UIActions.toggleSideBar())

    setTimeout(() => {
      element?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    })
  } 

  return (
    <ListItem onClick={scroll}>
      <ListItemButton>
        <ListItemIcon>
          <Icon />
        </ListItemIcon>

        <a href={url} style={{ textDecoration: 'none', color: '#000' }}>{text}</a>

        <ListItemText />
      </ListItemButton>
    </ListItem>
  )
}
