import { Typography, Grid, CardActionArea, styled } from "@mui/material"
import { Link } from "react-router-dom"

export const CardTegnology = ({ text, url, link = '#', target = '_self'}: Props) => {
  return (
    <Grid item xs={6} sm={2.4} pl="0" pt="0">
      <Link to={link} target={target} style={{ textDecoration: 'none', color: '#fff'}}>
        <CustomCard>
          <ImageResponsive src={url} />
          <Typography
            variant="h6"
            fontWeight={"800"}
            marginTop={{ xs: "5px", md: "10px" }}
            color="black"
            fontSize={{ xs: "12px", md: "18px" }}
          >
            {text}
          </Typography>
        </CustomCard>
      </Link>
    </Grid>
  )
}

interface Props {
  url: string;
  text: string;
  link?: string;
  target?: '_blank' | '_self'
}

const CustomCard = styled(CardActionArea)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  display: "flex",
  width: "90%",
  margin: "0 auto",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  transition: "all .2s",
  borderRadius: "25px",
  cursor: "pointer",
  height: "100%",
  ":hover": {
    transform: "scale(1.1)",
  },
  [theme.breakpoints.up("sm")]: {
    padding: "15px 25px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "10px",
    textAling: "center",
    borderRadius: "12px",
  },
}))

const ImageResponsive = styled("img")(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    maxWidth: "55px",
  },
  [theme.breakpoints.down("sm")]: {
    maxWidth: "32px",
  },
}))
