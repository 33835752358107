import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Button } from '@mui/material'

export default function DialogAlert({ open, onClose, access }: any ) {
    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Sorry, you do not have access yet.
            </DialogTitle>
            <DialogContent sx={{ display: 'flex' }}>
                To have access to this event you must purch the NFT ticket
            </DialogContent>
            <DialogActions>
                <Button size="small" variant='contained' color="error" onClick={() => onClose(false) }>Close</Button>
                <Button sx={{ ml: '10px' }} size="small" variant='contained' color='secondary' onClick={access}>Buy NFT</Button>
            </DialogActions>
        </Dialog>
    )
}

interface Props {
    open: boolean
    onClose(param: boolean): void
    access(): void

}