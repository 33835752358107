import React, { useEffect } from "react"
import { rootImage } from "../../../app/core/rootImages"

const slides = [
  {
    eachSlide: rootImage.tikects.home.common,
  },
  {
    eachSlide: rootImage.tikects.home.rare,
  },
  {
    eachSlide: rootImage.tikects.home.exclusive,
  },
  {
    eachSlide: rootImage.tikects.home.unique,
  },
]

export const Slider = ({ sliderWith }: { sliderWith: number }) => {
  const [active, setActive] = React.useState(0)
  const max = slides.length

  useEffect(() => {
    const interval = setInterval(() => {
      setActive(active === max - 1 ? 0 : active + 1)
    }, 5000)
    return () => clearInterval(interval)
  })

  const calculateScrollY = (): React.CSSProperties => {
    const totalWidth = slides.length * sliderWith

    return {
      width: `${totalWidth}px`,
      transform: `translateX(-${active * sliderWith}px)`,
    }
  }

  return (
    <div className="slider" style={calculateScrollY()}>
      {slides.map((item, i) => (
        <div
          key={i}
          style={{ ...styles, backgroundImage: `url(${item.eachSlide})` }}
        />
      ))}
    </div>
  )
}

const styles: React.CSSProperties = {
  width: "100%",
  height: "100%",
  backgroundPosition: "center center",
  backgroundSize: "cover",
}
