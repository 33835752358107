import { Box, Grid, styled } from "@mui/material"

// My Imports --------------------------------------------------------------
import { ContenedorCentrado, LayoutHomePage } from "../../../app/components/layout"
import SectionBuyNFT from "./SectionBuyNFT"
import CardBuyNFt from "./CardBuyNFt"

export function BuyNFTPage() {
  return (
    <Box  mt={'25px'}>
      <LayoutHomePage>
        <ContenedorCentrado>
          <GridPadding container spacing={6}>
            <Grid item xs={12} lg={5}>
              <SectionBuyNFT />
            </Grid>
            <Grid item xs={12} lg={7}>
              <CardBuyNFt />
            </Grid>
          </GridPadding>
        </ContenedorCentrado>
      </LayoutHomePage>
    </Box>
  )
}

const GridPadding = styled(Grid)(({theme})=> ({
  padding: '108px 0',
  [theme.breakpoints.down('md')]: {
    padding: '50px 0'
  }
}) )