/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  SxProps,
  Box,
  BoxProps,
  Grid,
  styled,
  Typography,
  TypographyProps,
  Link,
} from "@mui/material"
import { rootImage } from "../../../../app/core/rootImages"

export const FotCombination = () => {

  return (
    <BoxFootBallContainer>
      <Link href ='/dashboard-duete' target='_blank' style={{ textDecoration: 'none'}}>
        <Grid
          container
          sx={{ position: "relative", zIndex: "25", padding: "25px 15px" }}
        >
          <Grid item xs={12} mb="10px">
            <TextHeader variant="h6" color={"white"}>
              The Perfect Duets
            </TextHeader>
          </Grid>
          <Grid item sx={gridStyles} xs={6} md={6}>
            <ImageResponsive src={rootImage.avatar.default} height="150px" />
          </Grid>
          <Grid item sx={gridStyles} xs={6} md={6}>
            <ImageResponsive src={rootImage.moneda} height="150px" />
          </Grid>
        </Grid>
      </Link>
      <BoxFloat>
        <Typography
          variant="h5"
          fontSize={{ xs: "25px", sm: "35px" }}
          fontWeight={800}
        >
          &
        </Typography>
      </BoxFloat>
    </BoxFootBallContainer>
  )
}

const BoxFootBallContainer = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: "white",
  position: "relative",
  padding: "0px",
  maxHeight: "100%",
  overflow: "hidden",
  cursor: 'pointer',
  borderRadius: "25px",
  "&:before": {
    content: '""',
    display: "block",
    width: "500px",
    height: "466px",
    background: theme.palette.custom.blueBarca,
    position: "absolute",
    transform: "skewX(-21deg)",
    zIndex: "1",
  },
  [theme.breakpoints.up("sm")]: {
    "&:before": {
      left: "43%",
    },
  },
  [theme.breakpoints.down("sm")]: {
    "&:before": {
      left: "33%",
    },
  },
}))

const TextHeader = styled(Typography)<TypographyProps>(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  borderRadius: "25px",
  padding: "5px",
  textAlign: "center",
  maxWidth: "350px",
  textTransform: "uppercase",
  margin: "0 auto",
  fontWeight: "900",
  [theme.breakpoints.up("sm")]: {

  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
  },
}))

const ImageResponsive = styled("img")(({ theme }) => ({
  height: "150px",
  [theme.breakpoints.down("sm")]: {
    maxHeight: "80px",
  },
}))

const BoxFloat = styled(Box)<BoxProps>(({ theme }) => ({
  position: "absolute",
  top: "58%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  color: "#fff",
  backgroundColor: theme.palette.secondary.main,
  borderRadius: "50%",
  height: "65px",
  width: "65px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  zIndex: "99",
  [theme.breakpoints.down("sm")]: {
    height: "35px",
    width: "35px",
  },
}))

const gridStyles: SxProps = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}
