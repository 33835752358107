import { Sidebar, ImageLogo } from "../../../Custom"

export const SideBarComponent = ({image, children}: Props) => {
    return (
        <Sidebar>
            <header>
                <ImageLogo src={image} />
            </header>

            {children}
        </Sidebar>
    )
}

interface Props {
    image: string
    children: JSX.Element | JSX.Element[]
}