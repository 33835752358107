import { Typography } from "@mui/material"
import { useSelector } from "react-redux"


import { BasicCardContainer } from "../../../app/Custom"
import { RootState } from "../../../store"
import { CardDescription } from "./CardComponents/cardDescription"
import { SeleccionarCalidad } from "./CardComponents/SeleccionarCalidad"

export default function CardBuyNFt() {
  const { type } = useSelector((state: RootState) => state.cardBuyNFT)

  return (
    <BasicCardContainer>
      <Typography variant="h4">
        NFanTs <strong>{type}</strong>
      </Typography>

      <CardDescription />
      <SeleccionarCalidad />
    </BasicCardContainer>
  )
}
