import { StarBorderOutlined, FavoriteBorderOutlined } from '@mui/icons-material'
import  { Stack, Avatar, Typography, IconButton, Chip } from '@mui/material'

import { Container } from "../../../app/Custom"

export const MessageCard = ({  body, title, user, isNew, isFavorite, posted  }: Props) => {
    return (
        <Container mt='5px'>
            <Stack direction='row' alignItems={'center'} mb='8px' justifyContent='space-between'>
                <Stack direction='row' alignItems={'center'} spacing={1} >
                    <Avatar src={user.icon} alt="oli" sx={{ widht: '30px', height: '35px', flex: '0 1 35px' }} />
                    <Typography>{user.name}</Typography>
                </Stack>

                {isNew && <Chip variant='outlined' color='primary' label='new'  size='small' sx={{ color: '#000' }}/>}
            </Stack>

            <Typography variant="subtitle1">{title}</Typography>
            <Typography variant="body1">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla, vitae! </Typography>

            <Stack direction={"row"} alignItems="center" justifyContent={'space-between'}>
                <Stack direction={'row'} spacing={2}>
                    <IconButton size='small'>
                        <StarBorderOutlined />
                    </IconButton>

                    <IconButton size='small'>
                        <FavoriteBorderOutlined color={isFavorite ? 'error' : 'inherit'}/>
                    </IconButton>
                </Stack>

                <Typography variant="body2">Posted on <strong>{posted}</strong></Typography>

            </Stack>
        </Container>
    )
}

interface Props {
    user: {
        icon: string
        name: string
    }
    title: string
    body: string
    isFavorite: boolean
    isNew: boolean
    posted: string
}