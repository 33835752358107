import { Modal, Typography, Box, styled } from "@mui/material"

export const VideoModal = ({ url, open, setOpen }: Props) => {
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        border: "none !important",
      }}
    >
      <VideoContainer>
        <Box display={"flex"} justifyContent="flex-end" mx={'10px'}>
          <Typography
            color="#fff"
            fontWeight={"800"}
            sx={{ cursor: "pointer" }}
            onClick={() => setOpen(false)}
          >
            X
          </Typography>
        </Box>
        <EmbedContainer>
          <Iframe
            src={url ? url : "https://www.youtube.com/embed/-ZvsGmYKhcU?start=69"}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          ></Iframe>
        </EmbedContainer>
      </VideoContainer>
    </Modal>
  )
}

interface Props {
  url?: string;
  open: boolean;
  setOpen(param: boolean): void;
}

const VideoContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  border: "none",
  [theme.breakpoints.down('sm')]: {
    width: '95%',
  }
}))

const EmbedContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  paddingBottom: "56.25%",
  height: "0",
  overFlow: "hidden",
}))

const Iframe = styled("iframe")(({ theme }) => ({
  position: "absolute",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
}))
