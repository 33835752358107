import { Box, Grid, Stack, styled, Typography } from "@mui/material"
import { rootImage } from "../../../../app/core/rootImages"
import { BreakLine, Container } from "../../../../app/Custom"

export const Noticia = () => {
    return (
        <Grid container spacing={3}>
            <Grid item md={8}>
                <Container>
                    <Typography variant="h3" fontWeight={800}>
                        News Heading Here
                    </Typography>
                    <BreakLine
                        sx={{
                            background: "#00b8ff",
                            mt: "5px",
                            mb: "15px",
                            height: "18px",
                        }}
                    />

                    <Box>
                        <img src={rootImage.news.news1} width="100%" />
                    </Box>

                    <Box mt="15px" mb={"25px"}>
                        <Typography variant="h5" fontWeight={"800"}>
                            Lorem ipsum dolor sit amet.
                        </Typography>
                        <Typography variant="subtitle1">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. In,
                            similique.
                        </Typography>
                        <Typography variant="body1" mt="15px">
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                            Voluptates explicabo amet sapiente provident odio ullam enim!
                            Aperiam, hic tempore molestiae omnis cupiditate consequatur
                            eveniet placeat error porro dolorem fugiat voluptatem dicta non
                            recusandae dolorum illo soluta, incidunt natus nisi nostrum illum
                            iure corporis tenetur in. Ex in sunt sequi! Velit distinctio
                            repudiandae id officiis reiciendis ipsam, animi voluptas iste
                            voluptatum repellat, neque vitae suscipit eligendi quae maxime
                            omnis magnam, perferendis in quasi et quo? Nemo pariatur
                            reiciendis quibusdam laboriosam nulla error optio. Necessitatibus
                            eligendi ratione itaque voluptas commodi reprehenderit aut
                            voluptatem doloremque harum, earum accusamus minima! Distinctio
                            facilis ipsum dolorem.
                        </Typography>
                    </Box>

                    <EmbedContainer
                        mt="25px"
                    >
                        <Iframe
                            src="https://www.youtube.com/embed/pLyLQZNV_Rc"
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        />
                    </EmbedContainer>

                    <Box mt="15px">
                        <Typography variant="body1" mt="15px">
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                            Voluptates explicabo amet sapiente provident odio ullam enim!
                            Aperiam, hic tempore molestiae omnis cupiditate consequatur
                            eveniet placeat error porro dolorem fugiat voluptatem dicta non
                            recusandae dolorum illo soluta, incidunt natus nisi nostrum illum
                            iure corporis tenetur in. Ex in sunt sequi! Velit distinctio
                            repudiandae id officiis reiciendis ipsam, animi voluptas iste
                            voluptatum repellat, neque vitae suscipit eligendi quae maxime
                            omnis magnam, perferendis in quasi et quo? Nemo pariatur
                            reiciendis quibusdam laboriosam nulla error optio. Necessitatibus
                            eligendi ratione itaque voluptas commodi reprehenderit aut
                            voluptatem doloremque harum, earum accusamus minima! Distinctio
                            facilis ipsum dolorem.
                        </Typography>
                        <Typography variant="body1" mt="15px">
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                            Voluptates explicabo amet sapiente provident odio ullam enim!
                            Aperiam, hic tempore molestiae omnis cupiditate consequatur
                            eveniet placeat error porro dolorem fugiat voluptatem dicta non
                            recusandae dolorum illo soluta, incidunt natus nisi nostrum illum
                            iure corporis tenetur in. Ex in sunt sequi! Velit distinctio
                            repudiandae id officiis reiciendis ipsam, animi voluptas iste
                            voluptatum repellat, neque vitae suscipit eligendi quae maxime
                            omnis magnam, perferendis in quasi et quo? Nemo pariatur
                            reiciendis quibusdam laboriosam nulla error optio. Necessitatibus
                            eligendi ratione itaque voluptas commodi reprehenderit aut
                            voluptatem doloremque harum, earum accusamus minima! Distinctio
                            facilis ipsum dolorem.
                        </Typography>
                        <Typography variant="body1" mt="15px">
                            Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                            Voluptates explicabo amet sapiente provident odio ullam enim!
                            Aperiam, hic tempore molestiae omnis cupiditate consequatur
                            eveniet placeat error porro dolorem fugiat voluptatem dicta non
                            recusandae dolorum illo soluta, incidunt natus nisi nostrum illum
                            iure corporis tenetur in. Ex in sunt sequi! Velit distinctio
                            repudiandae id officiis reiciendis ipsam, animi voluptas iste
                            voluptatum repellat, neque vitae suscipit eligendi quae maxime
                            omnis magnam, perferendis in quasi et quo? Nemo pariatur
                            reiciendis quibusdam laboriosam nulla error optio. Necessitatibus
                            eligendi ratione itaque voluptas commodi reprehenderit aut
                            voluptatem doloremque harum, earum accusamus minima! Distinctio
                            facilis ipsum dolorem.
                        </Typography>
                    </Box>

                    <Stack mt="15px">
                        <Typography variant="body2">
                            Posted on <strong>01/02/22</strong>
                        </Typography>
                    </Stack>
                </Container>
            </Grid>

            <Grid item md={4}>
                <Typography variant="h5" fontWeight={"800"} textTransform="uppercase">
                    other publications
                </Typography>

                <Container mt="5px">
                    <Typography variant="h6">Lorem, ipsum dolor.</Typography>
                    <img src={rootImage.news.news2} width="100%" />
                    <Typography variant="subtitle2">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </Typography>
                    <Typography variant="body2" mt="5px">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem
                        veritatis voluptates nemo doloribus dicta reiciendis error voluptas
                        accusamus labore nobis!
                    </Typography>
                    <Typography variant="body2" mt="5px">
                        Posted on <strong>01/02/22</strong>
                    </Typography>
                </Container>

                <Container mt="15px">
                    <Typography variant="h6">Lorem, ipsum dolor.</Typography>
                    <img src={rootImage.news.news3} width="100%" />
                    <Typography variant="subtitle2">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </Typography>
                    <Typography variant="body2" mt="5px">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem
                        veritatis voluptates nemo doloribus dicta reiciendis error voluptas
                        accusamus labore nobis!
                    </Typography>
                    <Typography variant="body2" mt="5px">
                        Posted on <strong>01/02/22</strong>
                    </Typography>
                </Container>

                <Container mt="15px">
                    <Typography variant="h6">Lorem, ipsum dolor.</Typography>
                    <img src={rootImage.news.news4} width="100%" />
                    <Typography variant="subtitle2">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    </Typography>
                    <Typography variant="body2" mt="5px">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem
                        veritatis voluptates nemo doloribus dicta reiciendis error voluptas
                        accusamus labore nobis!
                    </Typography>
                    <Typography variant="body2" mt="5px">
                        Posted on <strong>01/02/22</strong>
                    </Typography>
                </Container>
            </Grid>
        </Grid>
    )
}

const Iframe = styled("iframe")(({ theme }) => ({
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
}))

const EmbedContainer = styled(Box)(({ theme }) => ({
    position: "relative",
    paddingBottom: "56.25%",
    height: "0",
    overFlow: "hidden",
}))
