import { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Drawer } from '@mui/material'

import { BoxContainerDashboard, BoxScroll, DashboardContainer } from '../../app/Custom'
import { DrawerDashboardItems } from './DrawerDashboardItems'
import { DuetosPage } from './duetos/DuetosPage'
import { Home } from './home/Home'
import { ItemDetails } from './shop/ItemDetails'
import { LifePage } from './life-shopping/LifePage'
import { MessagePage } from './message/MessagePage'
import { MintPage } from './mint/MintPage'
import { NewsPage } from './news/NewsPage'
import { rootRouters } from '../../app/core/rootRouter'
import { ShopPage } from './shop/ShopPage'
import { SideBarComponent } from './SideBarComponent'
import { SpecialEvent } from './special-event/LifePage'
import { SurveysPage } from './surveys/SurveysPage'
import { MyProfile } from './my-profile/MyProfile'
import { RafflesPage } from './raffles/RafflesPage'
import { NavDashboard } from '../../app/components/UI'
import { Noticia } from './news/New/Noticia'
import { NotificationPage } from './notification/NotificationPage'
import { DataBasePage } from './database/DataBasePage'

export const Dashboard = () => {
  const [open, setOpen] = useState(false)

  return (
    <DashboardContainer>
      <SideBarComponent />
      <Drawer anchor={'left'} open={open} onClose={() => setOpen(false)}>
        <DrawerDashboardItems />
      </Drawer>

      <BoxContainerDashboard>
        <NavDashboard setOpen={setOpen} />

        <BoxScroll>
          <Routes>
            <Route path={rootRouters.dashboard.home} element={<Home />} />
            <Route path={rootRouters.dashboard.shop.index} element={<ShopPage />} />
            <Route path={rootRouters.dashboard.shop.item} element={<ItemDetails />} />
            <Route path={rootRouters.dashboard.specialEvent} element={<SpecialEvent />} />
            <Route path={rootRouters.dashboard.live} element={<LifePage />} />
            <Route path={rootRouters.dashboard.news} element={<NewsPage />} />
            <Route path={rootRouters.dashboard.duetos} element={<DuetosPage />} />
            <Route path={rootRouters.dashboard.wallet} element={<MintPage />} />
            <Route path={rootRouters.dashboard.survey} element={<SurveysPage />} />
            <Route path={rootRouters.dashboard.myProfile} element={<MyProfile />} />
            <Route path={rootRouters.dashboard.raffles} element={<RafflesPage />} />
            <Route path={"/news/:id"} element={<Noticia />} />
            <Route path={"/notification"} element={<NotificationPage />} />
            <Route path={"/messages"} element={<MessagePage />} />
            <Route path={"/data-base"} element={<DataBasePage />} />
          </Routes>
        </BoxScroll>
      </BoxContainerDashboard>
    </DashboardContainer>
  )
}
