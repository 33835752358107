import { AxiosResponse } from 'axios'
import { useCallback, useReducer } from 'react'
import { toast } from 'react-toastify'
import { RequestAction, RequestState } from '../interfaces'

const initState: RequestState = {
  isLoading: false,
  error: null,
  requestResponse: null,
}

const actionsReducer = (state: RequestState, actions: RequestAction) => {
  switch (actions.type) {
    case 'loading':
      return {
        ...state,
        isLoading: actions.payload,
      }
    case 'error':
      return {
        ...state,
        error: actions.payload,
      }
    case 'request':
      return {
        ...state,
        requestResponse: actions.payload,
      }
    default:
      return state
  }
}

export const useRequest = () => {
  const [state, dispatch] = useReducer(actionsReducer, initState)
  const { isLoading, error, requestResponse } = state

  const sendRequest = useCallback(async (request: Promise<any>) => {
    dispatch({ type: 'loading', payload: true })
    dispatch({ type: 'error', payload: null })

    return new Promise<AxiosResponse>((resolve, rej) => {
        request.then( res => {
            resolve(res)
            dispatch({ type: 'request', payload: res})
          
        }).catch( (err: any) => {
            const { response } = err
            if( response?.status >= 400 ||response?.status < 500 ) {
               return rej(err)
            }
            
            if( response?.status >= 500 ){
                toast.error(err.response?.data?.message || 'Ups, algo salio mal')
                return rej(err)
            }

            toast.error(err?.message || 'Ups, el servidor no a respondido')
            dispatch({ type: 'error', payload: true})
            rej(err)
        }).finally( () => {
            dispatch({ type: 'loading', payload: false})
        })
    })
  }, [])

  return {
    isLoading,
    error,
    requestResponse,
    sendRequest,
  }
}
