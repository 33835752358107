import { useState } from "react"
import { toast } from "react-toastify"
import {
  Typography,
  Grid,
  Collapse,
  SxProps,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Stack,
} from "@mui/material"
import { CheckCircleOutlineOutlined, KeyboardArrowDownOutlined, KeyboardArrowUp } from "@mui/icons-material"

// My Imports===============================================
import { ChipBox, ButtonBuy } from "../../../../app/Custom"
import { Boxdrop } from "./Boxdrop"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../../store"
import { CardBuyActions } from "../../../../store/slice/CardBuyNFT"
import { apiConfig } from "../../../../api/Api.config"
import { useRequest } from "../../../../app/hooks"
import { useWallet } from "../../../../app/hooks/useWallet"
import { authActions } from "../../../../store/slice/auth.slice"

export const SeleccionarCalidad = () => {
  const { type, checked } = useSelector((state: RootState) => state.cardBuyNFT)
  const dispatch = useDispatch()

  const { active, connect, loading } = useWallet()
  const { sendRequest, isLoading } = useRequest()

  const [isBuy, setIsBuy] = useState(false)

  const handleBuyNFT = async () => {
    if (!active) {
      await connect().then(async (res) => {
        dispatch(authActions.setWallet(res))

        const promise = new Promise((resolve) => {
          setTimeout(() => resolve(true), 5000)
        })

        sendRequest(promise).then(() => {
          setIsBuy(true)
        })
      })
    } else {
      const promise = new Promise((resolve) => {
        setTimeout(() => resolve(true), 5000)
      })

      sendRequest(promise).then(() => {
        setIsBuy(true)
      })
    }
  }

  return (
    <>
      <Grid container mt="15px" spacing={2}>
        <Grid item xs={8} md={5} position="relative">
          <ChipBox
            bgcolor={"secondary.main"}
            onClick={() => dispatch(CardBuyActions.toggleBoxdrop())}
            sx={{ position: "relative", zIndex: "10", cursor: "pointer" }}
          >
            <Typography variant="h5" color={"white"} fontWeight="800" textAlign={'center'} ml='10px'>
              {type}
            </Typography>

            {checked ? <KeyboardArrowUp fontSize="large" sx={{ color: "#fff" }} /> : <KeyboardArrowDownOutlined fontSize="large" sx={{ color: "#fff" }} />}

          </ChipBox>
          <Collapse in={checked} sx={styles}>
            <Boxdrop />
          </Collapse>
        </Grid>
        <Grid item xs={0} md={2}></Grid>
        <Grid item xs={12} md={5}>
          <ButtonBuy fullWidth sx={{ textAlign: 'center' }} onClick={handleBuyNFT}>
            {isLoading || loading ? <CircularProgress color='secondary' /> : 'Buy'}
          </ButtonBuy>
        </Grid>
      </Grid>

      <Dialog
        open={isBuy}
        onClose={() => setIsBuy(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Stack >
            <CheckCircleOutlineOutlined color='success' fontSize="large" sx={{ fontSize: '48px', margin: 'auto' }} />
            NFasT purchased successfully, when the official release arrives in 60 days, you will be able to access the exclusive community
          </Stack>
        </DialogTitle>
        <DialogActions>
          <Button  variant='contained' color='secondary' onClick={() => setIsBuy(false)}>Ok!</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const styles: SxProps = {
  top: "20px",
  left: "12px",
  position: "absolute",
  zIndex: "5",
  width: "90%",
}


