import { Box, Typography } from "@mui/material"
import { BtnPrimary } from "../../../app/Custom"

export const DescriptionPartido = () => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box width="100%" display="flex" justifyContent={"center"} alignItems='center' flexDirection='column'>
        <Typography variant="body1" color="#fff" fontSize={"45px"} fontWeight='400'>
          15/02
        </Typography>
        <Box sx={{ width: "60%", height: "1px", bgcolor: "#fff" }} />
        <Typography variant="body1" color="#fff" fontSize={"45px"} fontWeight={"800"}>
          20:00
        </Typography>
      </Box>

      <BtnPrimary  sx={{ width: '70%'}}>
        Buy Tickets
      </BtnPrimary>
    </Box>
  )
}
