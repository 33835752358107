import { useState, useEffect, useCallback } from "react"

export const useClock = (deadline: string) => {
  const [{ days, hours, minutes, seconds }, setDate] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 10,
  })

  const leading0 = (num: number) => {
    return num < 10 ? "0" + num : num
  }

  const getTimeUntil = useCallback(
    (deadline: string) => {
      const time = Date.parse(deadline) - Date.parse(new Date().toString())
      if (time < 0) {
        const date = {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        }
        setDate(date)
        return
      }
      const date = {
        days: Math.floor(time / (1000 * 60 * 60 * 24)),
        hours: Math.floor((time / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((time / 1000 / 60) % 60),
        seconds: Math.floor((time / 1000) % 60),
      }
      setDate(date)
    },
    [deadline]
  )

  useEffect(() => {
    setInterval(() => getTimeUntil(deadline), 1000)

    return () => getTimeUntil(deadline)
  }, [deadline])

  return {
    days,
    hours,
    minutes,
    seconds,
    leading0,
  }
}
