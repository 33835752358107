import { Box, Stack, styled, Typography } from "@mui/material"
import { rootImage } from "../../../app/core/rootImages"

export default function SectionBuyNFT() {
  return (
    <Stack spacing={2} >
      <Typography variant="h2">
        Claim your Free <strong>NFanTs</strong>
      </Typography>

      <Box>
        <Typography variant="body1">
        Your fanaticism for us and your activity on the Club's official channels have their reward.
        </Typography>
        <Typography mt="15px" variant="body1">
          Claim your place on the whitelist for the Airdrop (An NFT Airdrop, or free drop, is a way we use to distribute Common Nfanst randomly to multiple wallets once as a rewards for their commitment.) that will take place in 60 days between all those who have claimed their place.
        </Typography>
        <Typography mt="15px" variant='body1'>
        If the whitelist is made up of more than 500 fans, the airdrop will be random, so you should keep an eye on our announcements and on the day the Free drop is launched check your wallet and evr if you are one of the lucky ones. If you are, you are already part of the community and you can start enjoying its benefits, if you are not, we recommend that you stay tuned for future announcements.
        </Typography>
        <Typography mt="15px" variant='body1'>
          Thanks and Good Luck!
        </Typography>
      </Box>
    </Stack>
  )
}
