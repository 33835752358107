import { MutableRefObject, useEffect, useRef, useState } from "react"
import { Grid, Stack, Typography, Box, SxProps } from "@mui/material"

// My imports ------------------------------------------------------
import CartContainer from "../../../app/components/layout/CartContainer"
import { rootImage } from "../../../app/core/rootImages"
import { BtnPrimary, SubTitle } from "../../../app/Custom"
import { Slider } from "./SliderImage"

export const BannerTikect = () => {
  const [widthImg, setWidthImg] = useState<number>(0)
  const refImage: MutableRefObject<any> = useRef()

  return (
    <CartContainer>
      <Grid container spacing={4} maxWidth="100%">
        <Grid item xs={12} lg={6}>
          <Stack spacing={2}>
            <Typography variant="h3">
              Buy <strong>NFTs</strong>
            </Typography>
            <SubTitle variant="body1" fontSize={"18px"}>
              NFansT is a collection of 11,110 NFTs—unique digital collectibles
              living on the Polygon blockchain. Your NFansT doubles as your
              Community membership card, and grants access to members-only
              benefits and service. Future areas and perks can be unlocked by
              the community through roadmap activation.
            </SubTitle>
   
            <SubTitle variant="body1" fontSize={"18px"} mt='15px'>
              When you buy a NFansT, you’re not simply buying an avatar or a
              provably-rare piece of art. You are gaining membership access to a
              club whose benefits and offerings will increase over time. Your
              NFansT can serve as your digital identity, and open digital doors
              for you.
            </SubTitle>
          </Stack>
        </Grid>

        <Grid
          item
          xs={12}
          lg={6}
          display="flex"
          alignItems={"center"}
          justifyContent="center"
        >
          <Box sx={styles}>
            <Box mb="25px" position={"relative"} overflow="hidden">
              <Slider sliderWith={widthImg} />
              <img
                ref={refImage}
                onLoad={(e: any) => setWidthImg(e.target?.width)}
                src={rootImage.tikects.common}
                style={{ width: "100%", visibility: "hidden" }}
              />
            </Box>

            <BtnPrimary
              sx={{ margin: "auto", color: "#fff" }}
              href="/buy-nft"
              size="large"
            >
              Buy a NFansT
            </BtnPrimary>
          </Box>
        </Grid>
      </Grid>
    </CartContainer>
  )
}

const styles: SxProps = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}
