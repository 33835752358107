import { Box, Button, Stack, Typography } from '@mui/material'

function MessagesCard({ img, message, name, time }: MessagesProps) {
  return (
    <Box bgcolor={'white'} borderRadius={'12px'} border={'1px solid lightgray'} p={1}>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Stack direction={'row'}>
          <Stack justifyContent={'center'} px={2}>
            <img src={img} height="40" alt={`${name}-avatar`} />
          </Stack>
          <Stack>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <Typography fontSize={'1.25em'} fontWeight={'bold'}>
                {name}
              </Typography>
              <Typography color={'lightgray'} fontSize={'1em'}>
                {time}
              </Typography>
            </Stack>
            <Typography fontSize={'1em'}>{message}</Typography>
          </Stack>
        </Stack>
        <Button sx={{ border: '1px solid lightgray', borderRadius: '12px' }}>
          <Typography fontSize={'1.1em'} fontWeight={'bold'} color={'black'}>
            Reply
          </Typography>
        </Button>
      </Stack>
    </Box>
  )
}
export default MessagesCard

interface MessagesProps {
  img: string
  name: string
  time: string
  message: string
}
