/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  BoxProps,
  Stack,
  Switch,
  SwitchProps,
  TableCell,
  TableRow,
  styled,
  tableCellClasses,
} from '@mui/material'
import { StackProps } from '@mui/system'

export const CircularBox = styled(Box)<BoxProps>(({ theme }) => ({
  width: '39px',
  height: '39px',
  borderRadius: '50%',
  backgroundColor: '#00b8ff',
  flex: '1 0 auto',
}))

export const BasicCardContainer = styled(Box)<BoxProps>(({ theme }) => ({
  background: theme.palette.business.background,
  borderRadius: '23px',
  padding: '33px 55px',
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    padding: '33px 35px',
  },
}))

export const ChipBox = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  borderRadius: '45px',
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.up('xl')]: {
    padding: '3px 25px;',
  },
  [theme.breakpoints.down('xl')]: {
    padding: '3px 11px',
  },
}))

export const BoxBgImage = (url: string) => {
  return styled(Box)<BoxProps>(({ theme }) => ({
    backgroundImage: `url( ${url} )`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    borderRadius: '23px',
    padding: '33px 55px',
    position: 'relative',
    [theme.breakpoints.down('lg')]: {
      padding: '33px 15px',
    },
  }))
}

export const BoxGradient = styled(Box)<BoxProps>(({ theme }) => ({
  background:
    'linear-gradient(125deg, rgba(0,184,255,1) 0%, rgba(19,1,123,1) 41%, rgba(19,1,123,1) 81%, rgba(0,184,255,1) 100%)',
  borderRadius: '25px',
  padding: '55px 50px',
  color: '#fff',
  width: '100%',
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {
    padding: '55px 22px',
  },
}))

export const BreakLine = styled(Box)<BoxProps>(({ theme }) => ({
  borderRadius: '33px',
  height: '25px',
  width: '100px',
  my: '25px',
}))

export const SwitchStyled = styled(Switch)<SwitchProps>(({ theme }) => ({
  width: 60,
  height: 30,
  padding: 0,
  display: 'flex',
  justifyContent: 'flex-end',
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(31px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#f4b40a',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 25,
    height: 25,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}))

export const StackContainerStyled = styled(Stack)<StackProps>(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  mx: 10,
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    mx: 10,
  },
}))

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  '&:first-of-type th': {
    backgroundColor: 'rgba(170,200,230,1)',
    fontWeight: 'bold',
    fontSize: 16,
  },
}))

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

export const DashboardContainer = styled(Box)<BoxProps>(({ theme }) => ({
  height: '100vh',
  overflow: 'hidden',
  background: theme.palette.business.background,
  display: 'flex',
}))

export const BoxContainerDashboard = styled(Box)<BoxProps>(({ theme }) => ({
  maxWidth: '1640px',
  margin: '0 auto',
  [theme.breakpoints.up('xl')]: {
    padding: '25px 65px',
  },
  [theme.breakpoints.down('xl')]: {
    padding: '25px 35px',
  },
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
  [theme.breakpoints.down('md')]: {
    padding: '25px 15px',
  },
  [theme.breakpoints.up('lg')]: {
    width: 'calc(100vw - 221px)',
  },
}))

export const BoxScroll = styled(Box)<BoxProps>(({ theme }) => ({
  height: 'calc(100vh - 75px)',
  overflow: 'scroll',
  padding: '25px 15px',
  paddingTop: '0',
  '::-webkit-scrollbar': {
    width: '5px',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '4px',
  },
}))

export const Sidebar = styled(Box)<BoxProps>(({ theme }) => ({
  minHeight: '100vh',
  background: '#fff',
  display: 'flex',
  flexDirection: 'column',
  padding: '25px 45px 25px 25px',
  [theme.breakpoints.up('xl')]: {
    padding: '25px 45px 10px 25px',
  },
  [theme.breakpoints.down('xl')]: {
    padding: '25px 25px 0px 25px',
  },
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
  },
}))


export const Container = styled(Box)(({ theme }) => ({
  background: '#fff',
  border: '1px solid #ccc',
  padding: theme.spacing(2),
  borderRadius: '5px'
}))

export const BannerFloating = styled(Box)(({ theme }) => ({
  position: "fixed",
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  transition: "all 1.5s",
  zIndex: '999999999',
  [theme.breakpoints.down('sm')]: {
      height: "90vh",
      width: "90vw",
      transform: "translate(0%, 1%)"
  }
}))


export const BannerLive = styled(Box)(({ theme }) => ({
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
  padding: "55px 45px",
  color: "#fff",
  minHeight: "650px",
  [theme.breakpoints.up("md")]: {
    padding: "55px 35px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "25px 10px",
  },
}))
