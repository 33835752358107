import { Route, Routes } from "react-router-dom"

// My Imports ---------------------------------------------
import { LoginPage, RegisterPage } from "../../views/Auth"
import { Dashboard } from "../../views/Dashboard/Dashboard"
import { BuyNFTPage } from "../../views/home/BuyNFT/BuyNFTPage"
import { LadingPage } from "../../views/home/LandingPage/LadingPage"
import { AdminPage } from "../../views/Admin/AdminPage"
import { rootRouters } from "../core/rootRouter"
import { VerifyWallet } from "../components/UI/auth/VerifyWallet"
import { PresalePage } from "../../views/home/BuyNFT copy/Presale"
import { DuetePage } from "../../views/Duets/DuetPage"
import { VerifyAdmin } from "../components/UI/auth/VerifyAdmin"
import { ClaimPage } from "../../views/home/BuyNFT copy 2/ClaimPage"

export const AppRouter = () => {
  console.log('renderizdo desde app Router')
  return (
    <Routes>
      <Route element={<LoginPage />} path={rootRouters.auth.login} />
      <Route element={<RegisterPage />} path={rootRouters.auth.register} />
      <Route element={<LadingPage />} path={rootRouters.home.ladingPage} />
      <Route element={<BuyNFTPage />} path={rootRouters.home.BuyNFT} />
      <Route element={<PresalePage />} path={rootRouters.home.preSale} />
      <Route element={<ClaimPage />} path={rootRouters.home.claim} />
      <Route
        element={
          <VerifyWallet>
            <Dashboard />
          </VerifyWallet>
        }
        path={rootRouters.dashboard.index}
      />
      <Route element={
         <VerifyWallet>
           <DuetePage />
         </VerifyWallet>
      } path={rootRouters.duet.index} />
      <Route element={
        <VerifyAdmin>
          <AdminPage />
        </VerifyAdmin>
      } path={"/admin/*"} />
    </Routes>
  )
}
