import { TextField, Box } from "@mui/material"


export const NormalInput = (props: Props) => {
  const { Icon, label, type } = props

  return (
    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
      <Icon 
        sx={{ color: props.error ? "error.light" : "action.active", mr: 1, my: 0.5, mb: props.error && '29px' }}
        fontSize="large"
         />
      <TextField 
        fullWidth 
        size="medium" 
        type={type} 
        label={label} 
        name={label.toLowerCase()}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        error={props.error}
        helperText={props.error && props.helperText}
        />
    </Box>
  )
}

interface Props {
  Icon: any
  label: string
  type: string
  value: string
  onChange: any
  onBlur: any
  error: boolean | undefined
  helperText: string | undefined
}
