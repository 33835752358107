import { Box, Stack, styled, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import { rootImage } from "../../../app/core/rootImages"

export default function SectionBuyNFT() {
  return (
    <Stack spacing={2} >
      <Typography variant="h2">
        BUY <strong>NFanTs</strong>
      </Typography>

      <Box>
        <Typography variant="body1">
          You can choose between 4 tiers of membership: COMMON, RARE, EXCLUSIVE and UNIQUES.
        </Typography>
        <Typography mt="15px" variant="body1">
          The membership tier will allow you to have access to or not to certain content or benefits. But if you own a NFT of lower tier, you will have the chances to access to punctual benefits or auctions exclusive of higher tiers by paying a one time extra FEE.-
        </Typography>
        <Typography mt="15px" variant='body1'>

          Each NFT, regardless of its membership level, is made up of 5 distinctive features and where the combination of features is what allows determining which one has unique characteristics and is different from the rest, even beyond the membership level. The scarcity value of a trait is defined based on the number of NFTs that possess that trait. So the total rarity of a particular NFT is the sum of the value of each trait.
        </Typography>
        <Typography mt="15px" variant='body1'>

          Once you buy a NFT, you can check it rarity on <Link to='https://rarity.tools/' target={'_blank'}>https://rarity.tools/</Link>
        </Typography>
      </Box>

      <Stack direction="row" spacing={{ md: 4, xs: 2 }} py="25px">
        <CoinsIcon src={rootImage.icons.common} />
        <CoinsIcon src={rootImage.icons.rare} />
        <CoinsIcon src={rootImage.icons.exclusive} />
        <CoinsIcon src={rootImage.icons.unique} />
      </Stack>
    </Stack>
  )
}

const CoinsIcon = styled("img")(({ theme }) => ({
  width: "19%",
}))
