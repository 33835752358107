import { BannerPrincipal } from "./BannerPrincipal"

// My Imports ----------------------------------
import { Navbar } from "../../../app/components/UI/Navbar/Navbar"

export function CustomHeader() {
  return (
    <>
      <Navbar />
      <BannerPrincipal />
    </>
  )
}
