import { useState } from "react"
import { toast } from "react-toastify"
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import { Box, Button, Divider, FormControl, FormControlLabel, Paper, Radio, RadioGroup, Typography } from "@mui/material"

import { BasicCardContainer } from "../../../app/Custom"
import { Grafic } from './Grafic'
import { useRequest } from "../../../app/hooks"
import CircularProgress from "@mui/material/CircularProgress"

export const SurveysPage = () => {
  const [isSend, setIsSend] = useState(false)
  const { isLoading, sendRequest } = useRequest()

  const summitButton = () => {
    const promise = new Promise((resolve) => {
      setTimeout(()=> {
        resolve(true)
      }, 3000)
    })

    sendRequest(promise).then(() => {
      setIsSend(true)
      toast.success('Thank you for participating in our survey. Your opinion is very valuable to us.')
    })
  }
  return (
    <BasicCardContainer sx={{ background: '#fff' }} display='flex' alignItems="center" justifyContent={'center'}>
      {!isSend && <Paper elevation={3} sx={{ maxWidth: '600px', padding: '25px', borderRadius: '20px', }}>
        <header>
          <Box display={"flex"} mb="25px" flexDirection={"column"} >
            <InsertDriveFileOutlinedIcon sx={{ fontSize: '85px', color: 'secondary.dark', margin: '0 auto' }} />
            <Typography variant='body1' fontWeight={'600'} textAlign="center">Your opinion matters</Typography>
          </Box>
          <Typography variant='body1' mb="15px">Have some ideas how to improve our product? <Typography fontWeight={'600'} component="span">
            Give us your feedback.</Typography></Typography>
        </header>

        <Divider />
        <Typography variant='body1' textAlign={"center"} mt="15px" fontWeight={'600'}>Your Rating:</Typography>

        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="Very-good"
            name="radio-buttons-group"
            color="secondary"
          >
            <FormControlLabel value="Very-good" control={<Radio color="secondary" />} label="Very good" />
            <FormControlLabel value="Good" control={<Radio color="secondary" />} label="Good" />
            <FormControlLabel value="Medicore" control={<Radio color="secondary" />} label="Medicore" />
            <FormControlLabel value="Bad" control={<Radio color="secondary" />} label="Bad" />
            <FormControlLabel value="Very-bad" control={<Radio color="secondary" />} label="Very bad" />
          </RadioGroup>
        </FormControl>

        <Divider />
        <Box mt="15px" display="flex" justifyContent={'flex-end'}>
          {
            isLoading ? <CircularProgress color="secondary" /> :<Button variant="contained" color="secondary" onClick={summitButton}>Sumbit</Button> 
          }
        </Box>
      </Paper>}

      { isSend && <Grafic />}
    </BasicCardContainer>
  )
}
