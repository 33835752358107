import { Box, Grid, Stack, SxProps, Typography } from "@mui/material"
import { useMemo, useState } from "react"
import { toast } from "react-toastify"
import { rootImage } from "../../../../app/core/rootImages"
import { BannerLive, ButtonWhite } from "../../../../app/Custom"
import { useClock, useRequest } from "../../../../app/hooks"
import { BasicCardContainer } from "../../my-profile/MyProfile"
import DialogAlert from "../DialogAlert"
import DialogError from "../DialogError"
import { VideoModal } from "../VideoModal"

export const Event_01 = () => {

    const DateMemo = useMemo(() => new Date(new Date().setSeconds(new Date().getSeconds() + 30)).toString(), [])
    const { minutes, seconds, leading0 } = useClock(DateMemo)

    const { isLoading, sendRequest } = useRequest()

    const [show, setShow] = useState(false)
    const [open, setOpen] = useState(false)
    const [error, setError] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleClick = () => {
        verificarAccess()
    }

    function verificarAccess() {
        if (loading) 
            return toast.success('please wait 10 and try again, while we verify that you have access')
        
        const promise = new Promise(res => {
            setTimeout(() => {
                res(disabled)
            }, 5000)
        })

        sendRequest(promise).then((res) => {

            if (res) {
                setOpen(true)
            } else {
                toast.error('Sorry, no tienes acceso')
                setError(true)
            }
        })
    }

    function darAccesso () {
        window.open('https://marketplacenfanst.devtop.online/')
        if( loading ) return
        
        setLoading(true)
        setError(false)
        toast.success('After 10s you will have access to the event, pleas wait')

        setTimeout(() => {
            setDisabled(true)
            setLoading(false)
            setError(false)
            toast.success('Now you have access!')
        }, 10000)
    }

    if (seconds === 50 && !show) {
        setShow(true)
    }
    return (
        <>
            <BasicCardContainer sx={styles}>
                <BannerLive sx={{ backgroundImage: `url( ${rootImage.banner.bannerLive} )`, minHeight: '700px' }}>
                    <Grid container>
                        <Grid item md={6}>
                            <img src={rootImage.logo.logo2} width="150px" />

                            <Typography
                                variant="h4"
                                lineHeight={1}
                                fontSize={{ md: "60px" }}
                                fontWeight={"800"}
                                my="45px"
                            >
                                Presentation of the NFansT Team to the community
                            </Typography>

                            <Stack direction='row' spacing={1}  >
                                <ButtonWhite
                                    variant="contained"
                                    sx={{ mb: "25px" }}
                                    onClick={handleClick}
                                    disabled={minutes === 0 && seconds === 0 ? false : true}
                                >
                                    <Typography
                                        className="button-active"
                                        display={minutes === 0 && seconds === 0 ? "block" : "none"}
                                    >
                                        <span id={"letter-1"}>A</span>
                                        <span id={"letter-2"}>c</span>
                                        <span id={"letter-3"}>c</span>
                                        <span id={"letter-4"}>e</span>
                                        <span id={"letter-5"}>s</span>
                                        <span id={"letter-6"}>s </span>
                                        <span id={"letter-7"}>t</span>
                                        <span id={"letter-8"}>o </span>
                                        <span id={"letter-9"}>t</span>
                                        <span id={"letter-10"}>h</span>
                                        <span id={"letter-11"}>e </span>
                                        <span id={"letter-12"}>E</span>
                                        <span id={"letter-13"}>v</span>
                                        <span id={"letter-14"}>e</span>
                                        <span id={"letter-15"}>n</span>
                                        <span id={"letter-16"}>t</span>
                                    </Typography>

                                    {minutes === 0 && seconds === 0
                                        ? ""
                                        : `Stream Start in ${leading0(minutes)}:${leading0(
                                            seconds
                                        )} min`}
                                </ButtonWhite>
                                
                                <Box display={disabled ? 'none' : 'block'}>
                                    <ButtonWhite onClick={() => darAccesso()}>
                                        { loading ? "verifying...": "Buy Ticket"}
                                    </ButtonWhite>
                                </Box>
                            </Stack>

                            <Typography variant="body1" lineHeight={1.3} fontSize="20px">
                                Exclusively for the NFansT community, it will be presented to the
                                team and we will talk about the special benefits that they can
                                access for being members, events that will be announced and many
                                surprises.
                            </Typography>
                        </Grid>
                    </Grid>
                </BannerLive>
            </BasicCardContainer>

            <VideoModal setOpen={setOpen} open={open} />


            <DialogAlert open={isLoading} />
            <DialogError open={error} onClose={setError} access={darAccesso}/>
        </>
    )
}

const styles: SxProps = {
    background: "#fff",
    height: "auto",
    padding: {
        md: "25px 45px",
        xs: "25px 10px",
    },
}