import { CheckCircleOutline  } from '@mui/icons-material'
import { Box, DialogTitle, Stack, DialogContent, DialogActions, Button, Typography } from '@mui/material'
import { Container } from '../../../../../app/Custom'
import { Clock } from '../Clock'

export const SuccessModal = ({ seconds, day, hrs, min, onClose }: Props) => {
    return (
        <Box>
            <DialogTitle>
                <Stack direction='row' alignItems={'center'} spacing={4} gap='10px'>
                    <CheckCircleOutline color='success' fontSize="large" />
                    congratulations you have bid successfully
                </Stack>
            </DialogTitle>

            <DialogContent>
                <Typography variant='subtitle1'>With your bid you are in position 1 of the auction</Typography>

                <Typography variant='body2' mt='10px'>time left:</Typography>
                <Container sx={{ background: '#ebe9e9'}}>
                    <Clock seconds={(seconds)} day={(day)} hrs={(hrs)} min={(min)} />
                </Container>
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose} variant='contained' color='error'>Close</Button>
                <Button onClick={onClose} variant='contained' color='secondary'>Ok!</Button>
            </DialogActions>
        </Box>
    )
}

interface Props {
    seconds: string | number 
    day: string| number 
    hrs: string | number
    min: string | number
    onClose(): void
}
