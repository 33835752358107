import { useState } from "react"
import { useFormik } from "formik"
import { Link, useNavigate } from "react-router-dom"
import { Box, Stack, Typography } from "@mui/material"
import { EmailOutlined } from "@mui/icons-material"
import { toast } from "react-toastify"

// My Imports
import { FormContainer } from "../../../app/components/layout/"
import { FormButton } from "../../../app/Custom"
import {
  Captcha,
  NormalInput,
  PasswordInput,
  validationSchemaLogin
} from "../../../app/components/Forms"
import { findErros } from "../../../app/utils"

import { useRequest } from "../../../app/hooks"
import { AuthLogin } from "../../../app/interfaces"
import { apiConfig } from "../../../api/Api.config"

import "react-phone-number-input/style.css"

export const LoginPage = () => {
  const navigate = useNavigate()

  const [captchaToken, setCaptchaToken] = useState("")
  const { isLoading,  sendRequest } = useRequest()

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      captchaToken: "",
    },
    validationSchema: validationSchemaLogin,
    onSubmit: async (values: AuthLogin) => {
      const sendData = {
        email: values.email,
        password: values.password,
        captcha: getCaptchaToken(),
      }
      alert(JSON.stringify(sendData))
      
      await sendRequest(apiConfig.post('/signin', sendData)).then(() => {
        toast.success('The section has been started successfully')
      })
      navigate('/dashboard')
    },
  })

  const isInvalidForm: boolean = findErros(formik.errors)
  const isTochedForm: boolean = findErros(formik.touched)
  const getCaptchaToken = (): string => {
    return captchaToken
  }

  return (
    <FormContainer
      onSumit={formik.handleSubmit}
      extraStyles={{ width: "530px", p: '35px, 48px' }}
    >
      <Stack spacing={2} padding="25px 0">
        <NormalInput
          Icon={EmailOutlined}
          label={"Email"}
          type={"Email"}
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched["email"] && Boolean(formik.errors["email"])}
          helperText={formik.errors.email}
        />

        <PasswordInput
          label={"Password"}
          id="1"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.errors["password"]}
        />
      </Stack>

      <Box
        display={"flex"}
        alignContent="center"
        justifyContent={"center"}
        flexDirection="column"
        textAlign={"center"}
      >
        {/* <Captcha value={captchaToken} setValue={setCaptchaToken} /> */}
   
        <FormButton
          disabled={
          isTochedForm 
              ? isInvalidForm
              : true
          }
          size="large"
          type="submit"
          sx={{
            marginTop: '15px'
          }}
          loading={isLoading}
          loadingPosition="end"
          variant="contained"
          color="secondary"
          fullWidth
        >
          Log in
        </FormButton>

        <Typography
          variant="body2"
          fontSize={"16px"}
          mt="15px"
          fontWeight="500"
          sx={{ display: "flex", margin: "8px auto", gap: "5px" }}
        >
          Don't have an account?
          <Link to={"/auth/register"} style={{ textDecoration: "none" }}>
            <Typography
              component={"strong"}
              variant="body2"
              fontSize={"16px"}
              fontWeight="500"
              color="secondary"
            >
              create an account
            </Typography>
          </Link>
        </Typography>
      </Box>
    </FormContainer>
  )
}
