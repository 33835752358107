import { Circle } from '@mui/icons-material'

function DashboardStats({ barColor }: Props) {
  return (
    <div className="stats-container">
      <div className="stats-wrap">
        <div className="stats-bar" style={{ height: '30%' }} />
      </div>
      <div className="stats-wrap">
        <div className="stats-bar" style={{ height: '40%' }} />
      </div>
      <div className="stats-wrap">
        <div className="stats-bar" style={{ height: '30%' }} />
      </div>
      <div className="stats-wrap">
        <div className="stats-bar" style={{ height: '60%' }} />
      </div>
      <div className="stats-wrap">
        <div className="stats-bar" style={{ height: '25%' }} />
      </div>
      <div className="stats-wrap">
        <div className="stats-bar" style={{ height: '50%' }} />
      </div>
      <div className="stats-wrap">
        <div className="icon-wrapper">
          <Circle className="icon" color="action" />
        </div>
        <div className="stats-bar" style={{ height: '55%', backgroundColor: barColor }} />
      </div>
      <div className="stats-wrap">
        <div className="stats-bar" style={{ height: '35%' }} />
      </div>
      <div className="stats-wrap">
        <div className="stats-bar" style={{ height: '35%' }} />
      </div>
      <div className="stats-wrap">
        <div className="stats-bar" style={{ height: '85%' }} />
      </div>
      <div className="stats-wrap">
        <div className="stats-bar" style={{ height: '20%' }} />
      </div>
      <div className="stats-wrap">
        <div className="stats-bar" />
      </div>
    </div>
  )
}
export default DashboardStats

interface Props {
  barColor: string
}
