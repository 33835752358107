import { Grid } from '@mui/material'
import { rootImage } from '../../../app/core/rootImages'
import { SectionNFT } from './SectionNFT'


export const MintPage = () => {

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={6}>
        <SectionNFT imgRote={rootImage.tikects.exclusive} nftWallet={{
          id: '001',
          rarity: 'Exclusive',
          background: 'Gold',
          badge: 'Unique',
          title: 'NFansT Exclusive membership'
        }} />
      </Grid>
    
      <Grid item xs={12} md={6}>
        <SectionNFT buttonText='0x2953...4963' url={"https://polygonscan.com/address/0x2953399124f0cbb46d2cbacd8a89cf0599974963"} imgRote={rootImage.unique} nftWallet={{
          id: '018',
          rarity: 'Unique',
          background: 'Unique',
          badge: 'Bronze',
          title: 'Fanaticoins UNIQUE'
        }} />
      </Grid>

      <Grid item xs={12} md={6}>
        <SectionNFT imgRote={rootImage.tikects.especialEvent} nftWallet={{
          id: '001',
          rarity: 'Exclusive',
          background: 'Gold',
          badge: 'Unique',
          title: 'Exclusive stream with the NFants community'
        }} />
      </Grid>
    </Grid>
  )
}
