/* eslint-disable @typescript-eslint/no-unused-vars */
import { useFormik } from "formik"
import { Box, BoxProps, styled, Stack, colors, SxProps } from "@mui/material"
import { rootImage } from "../../core/rootImages"

export const FormContainer = ({ children, extraStyles, onSumit }: Props) => {

  return (
    <BoxStyles className="auth-login">
      <BoxForm sx={{...extraStyles}}>
        <header
          style={{
            display: "flex",
            alignItems: "ceter",
            justifyContent: "center",
          }}
        >
          <ImageHeader src={rootImage.logo.logo1} />
        </header>
        <form onSubmit={onSumit}>{children}</form>
      </BoxForm>
    </BoxStyles>
  )
}

interface Props {
  children: JSX.Element | JSX.Element[]
  extraStyles?: SxProps
  onSumit(): void
}

const BoxStyles = styled(Box)<BoxProps>(({ theme }) => ({
  minHeight: "100vh",
  window: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: colors.grey[200],
  [theme.breakpoints.down('sm')]: {
  }
}))

const BoxForm = styled(Box)<BoxProps>(({ theme }) => ({
  background: "#fff",
  borderRadius: "5px",
  padding: "16px 48px",
  width: "650px",
  margin: "15px 22px",
  boxShadow: "3px 6px 13px -1px rgb(124 124 124)",
  [theme.breakpoints.down('sm')]: {
    padding: '16px 16px',
    width: '100%',
    flexDirection: 'column',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }
}))

const ImageHeader = styled('img')(({theme}) => ({
  [theme.breakpoints.down('sm')]: {
    width: "250px"
  }
}))