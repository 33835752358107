/* eslint-disable no-debugger */
import { FC, useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { toast } from "react-toastify"

import { authActions } from "../../../../store/slice/auth.slice"
import { useAppDispatch, useAppSelector } from "../../../hooks"
import { useWallet } from "../../../hooks/useWallet"
import { isValidAddress } from "../../../../wallet/ConnectWallet"
import AlertDialogSlide from "../Modal/ModalAlert"


export const VerifyWallet: FC<Props> = ({ children }) => {
  const { whiteList } = useAppSelector((state) => state.auth)
  const { active, connect, verificarWallet } = useWallet()

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [loading, setLoading]  = useState(false)

  const connectarWalletAlCargarLaPagina = useCallback(async () => {
    setLoading(true)

    const res = await connect()

    if (isValidAddress( String(res) )) {
      dispatch(authActions.setWallet(res))

      await verificarWallet(String(res)).then((res) => {
        if (res.auth) {
          toast.success("Loggin Success")
          dispatch(authActions.setWhiteList(res.auth))
          dispatch(authActions.setId(res._id))
        } else {
          toast.error("Loggin Error")
          dispatch(authActions.setWhiteList(false))
        }
      })
    } else {
      dispatch(authActions.setWhiteList(false))
    }

    setLoading(false)
  } ,[])


  useEffect(() => {
    connectarWalletAlCargarLaPagina()
  }, [])

  if (!active) {
    return (
      <AlertDialogSlide
        title="In order to access to the exclusive community of NFansT connect your wallet"
        loading={loading}
        onClick={connectarWalletAlCargarLaPagina}
      />
    )
  }

  if (!whiteList)
    return (
      <AlertDialogSlide
        subTitle={
          whiteList === undefined
          ? "pending..."
          : "Buy NFansT"
        }
        loading={loading}
        title={
          whiteList === undefined
            ? "Verifying if you are one of the exclusive member of our community..."
            : "Access denied, in order to access web 3.0 you must purchase an NFT from the NFansT collection."
        }
        onClick={() => navigate('/buy-nft')}
      />
    )

  return <div>{children}</div>
}

interface Props {
  children: JSX.Element | JSX.Element[]
}
