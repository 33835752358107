import { createSlice } from '@reduxjs/toolkit'
import { tableStateI } from '../../app/interfaces'

const initialState: tableStateI = {
  selected: [],
  page: 0,
}

const tableSlice = createSlice({
  name: 'tableSlice',
  initialState,
  reducers: {
    selectNewRow(state, actions) {
      const { name } = actions.payload
      const selectedIndex = state.selected.indexOf(name)
      let newSelected: string[] = []

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(state.selected, name)
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(state.selected.slice(1))
      } else if (selectedIndex === state.selected.length - 1) {
        newSelected = newSelected.concat(state.selected.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          state.selected.slice(0, selectedIndex),
          state.selected.slice(selectedIndex + 1)
        )
      }

      state.selected = newSelected
    },
    handlePage(state, actions) {
      const { newPage } = actions.payload

      state.page = newPage
    },
    handleSelectAllClick(state, actions) {
      const { checked, rows } = actions.payload

      if (checked) {
        const newSelected = rows.map((n: any) => n.name)
        state.selected = newSelected
        return
      }
      state.selected = []
    },
  },
})

export const tableActions = tableSlice.actions
export const tableReducer = tableSlice.reducer
