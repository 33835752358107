import React, { MouseEvent } from "react"
import { Box, styled, Typography } from "@mui/material"

import { rootImage } from "../../../app/core/rootImages"

const Banner = ({ setShow, show }: Props) => {

    const closeBanner = (event: MouseEvent<any>) => {
        event.preventDefault()
        setShow(false)
    }
    return (
        <BannerFloating className={show ? 'mostrar-banner-life-event' : 'ocultar-banner-life-event'}>
            <a href="#footer">

                <Box display="block" width="100%" height={"100%"} position="relative">
                    <Typography
                        sx={{
                            position: "absolute",
                            top: "20px",
                            right: "25px",
                            cursor: "pointer",
                        }}
                        component="span"
                        fontWeight={"800"}
                        fontSize="18px"
                        color="#fff"
                        onClick={closeBanner}
                    >
                        X
                    </Typography>
                </Box>
            </a>
        </BannerFloating>
    )
}

interface Props {
    setShow(param: boolean): void
    show: boolean
}

const BannerFloating = styled(Box)(({ theme }) => ({
    position: "fixed",
    backgroundImage: `url( ${rootImage.banner.presale} )`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: "481px",
    width: "300px",
    transition: "all 1.5s",
    zIndex: '999999999',
    [theme.breakpoints.down('sm')]: {
        transform: "translate(0%, 1%)"
    }
}))

export default React.memo(Banner)