import React from 'react'
import { Box } from '@mui/material'
import { rootImage } from '../../../app/core/rootImages'

export const HeaderImages = () => {
  return (
    <Box sx={stylesBox}>
        <img src={rootImage.avatar.default} style={styleImg1} />
        <img src={rootImage.avatar.shakira} style={styleImg2} />
        <img src={rootImage.avatar.default} style={{ visibility: 'hidden' }} width="150px"/>
    </Box>
  )
}

const stylesBox: React.CSSProperties = {
    width: '100%',
    height: '100%',
    display: 'block',
    position: 'relative'
}

const styleImg1 : React.CSSProperties = {
    width: '150px',
    height: '150px',
    position: 'absolute',
    top: '80px',
    left: '-3%',
    transform: 'translate(0%, -50%)',
}

const styleImg2 : React.CSSProperties = {
    width: '150px',
    height: '150px',
    position: 'absolute',
    top: '60%',
    left: '23%',
    transform: 'translate(0%, -50%)',
}


