import { createTheme } from "@mui/material"

// 4a126b
export const lightTheme = createTheme({
  palette: {
    primary: {
      main: "#13017b",
    },
    secondary: {
      main: "#00b8ff",
    },
    business: {
      background: "#f4f7fd",
      lightBackground: '#ebe9e9'
    },
    custom: {
      blueBarca: "#1B3466",
      purple: "#A31ACB",
    }
  },
  typography: {
    fontFamily: "Hurme Geometric",
    body2: {
      
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          color: "#fff",
          fontWeight: "600",
          "&:hover": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: "55px",
          background: "#fff",
          fontWeight: "600",
          color: "#000",
        },
      },
    },
    MuiTypography: {
     styleOverrides: {
      body1: {
        fontSize: '18px',
        fontWeight: '500'
      }
     }
    },
    MuiBadge: {
      styleOverrides: {
        colorSecondary: {
          color: '#fff'
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        filled: {
          color: '#fff'
        },
        outlined: {
          color: '#fff'
        }
      }
    }
  },
})



declare module '@mui/material/styles' {

  interface Palette {
    business: {
      background: string
      lightBackground: string
    }
    custom: {
      blueBarca: string
      purple: string
    }
  }

  interface PaletteOptions {
    business: {
      background: string
      lightBackground: string
    }
    custom: {
      blueBarca: string
      purple: string
    }
  }

  // interface PaletteColor {
  //   darker?: string;
  // }

  // interface SimplePaletteColorOptions {
  //   background?: string;
  // }

  // interface ThemeOptions {
  //   status: {
  //     danger: React.CSSProperties['color'];
  //   };
  // }
}