import { Grid, GridProps, styled } from "@mui/material"
import { rootImage } from "../../../../app/core/rootImages"
import { CardTegnology } from "./CardTecnology"

export const TegnologyContainer = () => {
  return (
    <GridCustom container>
      <CardTegnology link="/dashboard/my-profile" url={rootImage.icons.user} text={"My Profile"} />
      <CardTegnology link="/dashboard/news" url={rootImage.icons.dasboard.news} text={"News"} />
      <CardTegnology link="/dashboard/live" url={rootImage.icons["life-shoping-icon"]} text={"Live shopping"} />
      <CardTegnology link="/dashboard/survey" url={rootImage.icons["surveys-icon"]} text={"surveys"} />
      <CardTegnology link="https://marketplacenfanst.devtop.online/" target="_blank" url={rootImage.icons.dasboard.onlineStore} text={"MARKETPLACE"} />
    </GridCustom>
  )
}

const GridCustom = styled(Grid)<GridProps>(({ theme }) => ({
  backgroundColor: "#fff",
  padding: "25px 50px",
  borderRadius: "25px",
  marginTop: "25px",
  rowGap: "25px",
  [theme.breakpoints.up("md")]: {
    padding: "25px 50px",
  },
  [theme.breakpoints.up("sm")]: {
    padding: "25px 10px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "10px  5px",
  },
}))
