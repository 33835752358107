import React from "react"
import { Box, Grid, TablePagination } from "@mui/material"
import { rootImage } from "../../../app/core/rootImages"
import { ShopItem } from "./item/ShopItem"

export const ShopPage = () => {
  const [page, setPage] = React.useState(2)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <Box>
      <Grid container spacing={2}>
        {DUMMY_DATA.map(({ id, title, images, price }, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            lg={2.4}
            key={index}
            sx={{ display: "flex", justifyContent: "center" }}
          > 

            <ShopItem url={images} text={title} price={price} id={id} isNew={index === 2 || index === 5 || index  === 7} descuento={index ===2 }/>
          </Grid>
        ))}
      </Grid>

      <TablePagination
        component="div"
        count={100}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  )
}


const DUMMY_DATA = [
  {
    id: 1,
    title: "SHIRT NFANST",
    price: 15,
    images: rootImage.camisas.women,
  },
  {
    id: 2,
    title: "SHIRT NFANST",
    price: 15,
    images: rootImage.camisas.soccer2,
  },
  {
    id: 3,
    title: "SHIRT NFANST",
    price: 15,
    images: rootImage.camisas.soccer3,
  },
  {
    id: 4,
    title: "SHIRT NFANST",
    price: 15,
    images: rootImage.camisas.soccer4,
  },
  {
    id: 5,
    title: "SHIRT NFANST",
    price: 15,
    images: rootImage.camisas.soccer5,
  },
  {
    id: 6,
    title: "SHIRT NFANST",
    price: 15,
    images: rootImage.camisas.soccer2,
  },
  {
    id: 1,
    title: "SHIRT NFANST",
    price: 15,
    images: rootImage.camisas.women,
  },
  {
    id: 2,
    title: "SHIRT NFANST",
    price: 15,
    images: rootImage.camisas.soccer2,
  },
  {
    id: 3,
    title: "SHIRT NFANST",
    price: 15,
    images: rootImage.camisas.soccer3,
  },
  {
    id: 4,
    title: "SHIRT NFANST",
    price: 15,
    images: rootImage.camisas.soccer4,
  },
  {
    id: 5,
    title: "SHIRT NFANST",
    price: 15,
    images: rootImage.camisas.soccer5,
  },
  {
    id: 6,
    title: "SHIRT NFANST",
    price: 15,
    images: rootImage.camisas.soccer2,
  },
  {
    id: 1,
    title: "SHIRT NFANST",
    price: 15,
    images: rootImage.camisas.women,
  },
  {
    id: 2,
    title: "SHIRT NFANST",
    price: 15,
    images: rootImage.camisas.soccer2,
  },
  {
    id: 3,
    title: "SHIRT NFANST",
    price: 15,
    images: rootImage.camisas.soccer3,
  },
]
