import { Divider, List, SxProps } from "@mui/material"
import { AvatarOlineUser, CustomListItem } from "../../app/components/UI"
import { rootImage } from "../../app/core/rootImages"

export const DrawerItems = () => {
  return (
    <>
      <AvatarOlineUser size={100} />

      <Divider sx={{ my: '10px' }} />
      <List sx={stlyed}>
        <CustomListItem
          text="Dashboard"
          url="/dashboard-duete"
          icon={<img src={rootImage.icons.dasboard.home} />}
        />
        <CustomListItem
          text="My Profile"
          url="/dashboard-duete/my-profile"
          icon={<img src={rootImage.icons.dasboard.myProfile} width="19px" />}
        />
        <CustomListItem
          text="Wallet"
          url="/dashboard-duete/my-profile"
          icon={<img src={rootImage.icons.dasboard.wallet} />}
        />
        <CustomListItem
          text="Marketplace"
          url="/dashboard-duete/my-profile"
          target="_blank"
          icon={<img src={rootImage.icons.dasboard.nftIcon} />}
        />
        <CustomListItem
          text="Store"
          url="/dashboard-duete/my-profile"
          icon={<img src={rootImage.icons.dasboard.shop} />}
        />
        <CustomListItem
          text="Special Events"
          url="/dashboard-duete/my-profile"
          icon={<img src={rootImage.icons.dasboard.lifeShoping} />}
        />
        <CustomListItem
          text="Live Shopping"
          url="/dashboard-duete/my-profile"
          icon={<img src={rootImage.icons.dasboard.history} />}
        />
        <CustomListItem
          text="News"
          url="/dashboard-duete/my-profile"
          icon={<img src={rootImage.icons.dasboard.newsIcon} width='19px' height={'19px'} />}
        />
        <CustomListItem
          text="Survey"
          url="/dashboard-duete/my-profile"
          icon={<img src={rootImage.icons.dasboard.surveys} />}
        />
        <CustomListItem
          text="Raffles"
          url="/dashboard-duete/my-profile"
          icon={<img src={rootImage.icons.dasboard.tickets} />}
        />
      </List>
      <Divider />

      <List>
        <CustomListItem text="log out" icon={<img src={rootImage.icons.dasboard.logout} />} />
      </List>
    </>
  )
}

const stlyed: SxProps = {
  flex: '1 1',
  overflowY: 'scroll',
  '::-webkit-scrollbar': {
    width: '5px',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: 'gray',
    borderRadius: '4px',
  },
}
