import { Box, Typography } from "@mui/material"
import { CSSProperties } from "@mui/styled-engine"

interface Props {
  number: string
  children: JSX.Element | JSX.Element[]
  icon: string 
}

const styles: CSSProperties = {
  width: "144px",
  height: "238px",
  borderRadius: "90px",
  boxShadow: "5px 5px 15px #bebebe",
  padding: "25px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  border: "1px solid  #bebebe63",
  margin: "auto",
}

export const RoadChip = ({ number, children, icon }: Props) => {
  return (
    <Box p={"15px 0"}>
      <Box sx={styles}>
        <Box
          display={"flex"}
          alignItems="center"
          justifyContent="center"
          mt="10px"
        >
          <img src={icon} style={{ width: "55px", height: "55px" }} />
        </Box>
        <Box>{children}</Box>

        <Box>
          <Box
            sx={{
              borderRadius: "50%",
              backgroundColor: "#00b8ff",
              color: "#fff",
              display: "flex",
              width: "40px",
              height: "40px",
              justifyContent: "center",
              margin: "auto",
              alignItems: "center",
              fontFamily: "monospace",
            }}
          >
            <Typography
              className={"font-famyli-roman-letters"}
              fontSize={"16px"}
              textAlign={"center"}
              lineHeight="auto"
            >
              {number.toUpperCase()}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
