/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  BoxProps,
  styled,
} from "@mui/material"

import { rootImage } from "../../app/core/rootImages"
import { ImageLogo } from "../../app/Custom"
import { DrawerDashboardItems } from "./DrawerDashboardItems"

export const SideBarComponent = () => {
  return (
    <Sidebar display={{ xs: 'none', lg: 'flex' }}>
      <header>
        <ImageLogo src={rootImage.logo.logo1} />
      </header>

      <DrawerDashboardItems />
    </Sidebar>
  )
}

const Sidebar = styled(Box)<BoxProps>(({ theme }) => ({
  minHeight: "100vh",
  background: "#fff",
  display: "flex",
  flexDirection: "column",
  padding: "25px 45px 25px 25px",
  [theme.breakpoints.up("xl")]: {
    padding: "25px 45px 10px 25px",
  },
  [theme.breakpoints.down("xl")]: {
    padding: "25px 25px 0px 25px",
  },
}))