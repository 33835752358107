import { createSlice} from '@reduxjs/toolkit'
import { NotificationInterface } from '../../app/interfaces'

const initialState: NotificationInterface = {
   notifications:[{
    "id": "01GTMBGHBYMWDFW4GEZVDW5SRP",
    "title": "Saints and Soldiers: The Void",
    "user": "https://robohash.org/quammolestiasomnis.png?size=50x50&set=set1",
    "isRead": false,
    "isArchivate": true,
    "body": "Customer-focused mission-critical capacity",
    "date": "4/29/2022"
  }, {
    "id": "01GTMBGHC2KHAY7JT0YZM3JZ2T",
    "title": "Breaking Out (Vägen ut)",
    "user": "https://robohash.org/pariaturquimagnam.png?size=50x50&set=set1",
    "isRead": false,
    "isArchivate": true,
    "body": "Persistent exuding success",
    "date": "8/7/2022"
  }, {
    "id": "01GTMBGHC60K4JFXEGTT1RY37E",
    "title": "Osaka Elegy (Naniwa erejî)",
    "user": "https://robohash.org/aliquamdolorumitaque.png?size=50x50&set=set1",
    "isRead": true,
    "isArchivate": true,
    "body": "Public-key foreground project",
    "date": "11/27/2022"
  }, {
    "id": "01GTMBGHCATXEH19XKYTEWY1FG",
    "title": "Bear's Kiss",
    "user": "https://robohash.org/temporarepellatillo.png?size=50x50&set=set1",
    "isRead": true,
    "isArchivate": true,
    "body": "Self-enabling incremental budgetary management",
    "date": "11/30/2022"
  }, {
    "id": "01GTMBGHCEADHTBR84BMJTQT85",
    "title": "Goodbye World",
    "user": "https://robohash.org/estnobisut.png?size=50x50&set=set1",
    "isRead": true,
    "isArchivate": true,
    "body": "Progressive cohesive capacity",
    "date": "4/6/2022"
  }, {
    "id": "01GTMBGHCJR4R5GP30603QA153",
    "title": "Tora! Tora! Tora!",
    "user": "https://robohash.org/consequaturrerumarchitecto.png?size=50x50&set=set1",
    "isRead": true,
    "isArchivate": false,
    "body": "Monitored disintermediate benchmark",
    "date": "11/12/2022"
  }, {
    "id": "01GTMBGHCP71DYC1SMGQ3PBWQZ",
    "title": "Citizen Gangster ",
    "user": "https://robohash.org/facilisnesciuntsed.png?size=50x50&set=set1",
    "isRead": false,
    "isArchivate": true,
    "body": "Realigned directional throughput",
    "date": "6/20/2022"
  }, {
    "id": "01GTMBGHCTM2MBCE7R391NVXSW",
    "title": "Eagle Has Landed, The",
    "user": "https://robohash.org/suntreruma.png?size=50x50&set=set1",
    "isRead": true,
    "isArchivate": false,
    "body": "Business-focused 4th generation instruction set",
    "date": "4/21/2022"
  }, {
    "id": "01GTMBGHCYBFZJGY0W77GZNT1B",
    "title": "House of Bamboo",
    "user": "https://robohash.org/consecteturautducimus.png?size=50x50&set=set1",
    "isRead": true,
    "isArchivate": true,
    "body": "Networked bandwidth-monitored internet solution",
    "date": "12/19/2022"
  }, {
    "id": "01GTMBGHD2YSKN3SEPXRMJ2HX9",
    "title": "Sand Sharks",
    "user": "https://robohash.org/quiainminus.png?size=50x50&set=set1",
    "isRead": true,
    "isArchivate": false,
    "body": "Realigned coherent task-force",
    "date": "5/17/2022"
  }, {
    "id": "01GTMBGHD6JD711X97X7JHWFGB",
    "title": "Vampire Hunter D",
    "user": "https://robohash.org/oditplaceatin.png?size=50x50&set=set1",
    "isRead": true,
    "isArchivate": true,
    "body": "Customizable modular capability",
    "date": "6/5/2022"
  }, {
    "id": "01GTMBGHD9CDDCSHN0QRJK51CV",
    "title": "Sons of Perdition",
    "user": "https://robohash.org/deseruntquaepariatur.png?size=50x50&set=set1",
    "isRead": false,
    "isArchivate": false,
    "body": "Operative content-based policy",
    "date": "9/4/2022"
  }, {
    "id": "01GTMBGHDDDCB2NXXRTB0JR6TV",
    "title": "Big Sky, The",
    "user": "https://robohash.org/nullaquisaepe.png?size=50x50&set=set1",
    "isRead": false,
    "isArchivate": false,
    "body": "Team-oriented value-added leverage",
    "date": "12/29/2022"
  }, {
    "id": "01GTMBGHDG5NBRDEXFX88K097C",
    "title": "Mark, The",
    "user": "https://robohash.org/veritatisrerumexercitationem.png?size=50x50&set=set1",
    "isRead": false,
    "isArchivate": false,
    "body": "Organic non-volatile flexibility",
    "date": "5/9/2022"
  }, {
    "id": "01GTMBGHDMQ8K02T6KXABY00MB",
    "title": "Knightriders",
    "user": "https://robohash.org/estinventoreet.png?size=50x50&set=set1",
    "isRead": false,
    "isArchivate": false,
    "body": "Distributed web-enabled array",
    "date": "9/5/2022"
  }, {
    "id": "01GTMBGHDQFMB8VCJJCC236WDB",
    "title": "Ice Quake ",
    "user": "https://robohash.org/etetsimilique.png?size=50x50&set=set1",
    "isRead": false,
    "isArchivate": false,
    "body": "Programmable upward-trending contingency",
    "date": "7/5/2022"
  }, {
    "id": "01GTMBGHDT9VDAWZV8WZC8JA3D",
    "title": "Emperor Waltz, The",
    "user": "https://robohash.org/consequunturnamillum.png?size=50x50&set=set1",
    "isRead": false,
    "isArchivate": true,
    "body": "Proactive composite attitude",
    "date": "10/1/2022"
  }, {
    "id": "01GTMBGHDX1KK5T28JPT2R4EQZ",
    "title": "Reconstruction",
    "user": "https://robohash.org/deseruntreiciendisdelectus.png?size=50x50&set=set1",
    "isRead": false,
    "isArchivate": false,
    "body": "Synergistic fresh-thinking artificial intelligence",
    "date": "1/28/2023"
  }, {
    "id": "01GTMBGHE0GMQVC101ZPZ52TZV",
    "title": "Land of the Pharaohs",
    "user": "https://robohash.org/sintidofficiis.png?size=50x50&set=set1",
    "isRead": true,
    "isArchivate": false,
    "body": "Profound impactful orchestration",
    "date": "4/27/2022"
  }, {
    "id": "01GTMBGHE4W2TGV3YYYKMT2VVS",
    "title": "Heavens Fall",
    "user": "https://robohash.org/reprehenderitaliquidveniam.png?size=50x50&set=set1",
    "isRead": false,
    "isArchivate": true,
    "body": "Adaptive composite orchestration",
    "date": "10/29/2022"
  }, {
    "id": "01GTMBGHE7X4FK0T4E1GV7KS2J",
    "title": "Magic Voyage of Sindbad, The (Sadko)",
    "user": "https://robohash.org/nonaculpa.png?size=50x50&set=set1",
    "isRead": true,
    "isArchivate": false,
    "body": "Visionary solution-oriented strategy",
    "date": "8/18/2022"
  }, {
    "id": "01GTMBGHEA042JH8TP2RKNFY4V",
    "title": "Bus Stop",
    "user": "https://robohash.org/exercitationembeataeodit.png?size=50x50&set=set1",
    "isRead": false,
    "isArchivate": false,
    "body": "Synergistic demand-driven interface",
    "date": "3/10/2022"
  }, {
    "id": "01GTMBGHED6VCVQYK27T9ETXMK",
    "title": "The Feathered Serpent",
    "user": "https://robohash.org/laudantiumitaqueet.png?size=50x50&set=set1",
    "isRead": true,
    "isArchivate": false,
    "body": "Multi-channelled zero tolerance firmware",
    "date": "9/2/2022"
  }, {
    "id": "01GTMBGHEG5309275PEKJ1XGEX",
    "title": "Journey, The (El viaje)",
    "user": "https://robohash.org/similiquesedquia.png?size=50x50&set=set1",
    "isRead": true,
    "isArchivate": true,
    "body": "Operative upward-trending application",
    "date": "5/27/2022"
  }, {
    "id": "01GTMBGHEKS8G7NY5RGJQY6XF6",
    "title": "Du côté de la côte",
    "user": "https://robohash.org/suntiurenostrum.png?size=50x50&set=set1",
    "isRead": true,
    "isArchivate": true,
    "body": "Front-line scalable focus group",
    "date": "2/23/2023"
  }, {
    "id": "01GTMBGHEPQ1V3FK0WHTRF0W4W",
    "title": "Jo Jo Dancer, Your Life is Calling",
    "user": "https://robohash.org/iuremagnamillo.png?size=50x50&set=set1",
    "isRead": true,
    "isArchivate": false,
    "body": "Secured eco-centric open architecture",
    "date": "8/13/2022"
  }, {
    "id": "01GTMBGHERY96QNRF0Q454ZB7E",
    "title": "Better Life, A",
    "user": "https://robohash.org/quamquilibero.png?size=50x50&set=set1",
    "isRead": false,
    "isArchivate": true,
    "body": "Progressive dedicated parallelism",
    "date": "7/2/2022"
  }, {
    "id": "01GTMBGHEV1FJEVBPWMETWBFG9",
    "title": "Soylent Green",
    "user": "https://robohash.org/saepeexpeditadistinctio.png?size=50x50&set=set1",
    "isRead": false,
    "isArchivate": true,
    "body": "Profit-focused transitional product",
    "date": "10/12/2022"
  }, {
    "id": "01GTMBGHEXPX1N7V303CCDNEVM",
    "title": "Liberty Heights",
    "user": "https://robohash.org/corruptiexplicabodoloremque.png?size=50x50&set=set1",
    "isRead": true,
    "isArchivate": true,
    "body": "Progressive empowering application",
    "date": "6/22/2022"
  }, {
    "id": "01GTMBGHF1S3PPBRVW987MRXGC",
    "title": "Ken Park",
    "user": "https://robohash.org/doloribusnequecum.png?size=50x50&set=set1",
    "isRead": false,
    "isArchivate": true,
    "body": "Profound radical monitoring",
    "date": "4/10/2022"
  }, {
    "id": "01GTMBGHF45XGZ06M4TXXSZJJ3",
    "title": "Aragami",
    "user": "https://robohash.org/praesentiumomniseveniet.png?size=50x50&set=set1",
    "isRead": true,
    "isArchivate": true,
    "body": "Business-focused next generation monitoring",
    "date": "6/25/2022"
  }, {
    "id": "01GTMBGHF768MQ95QRF6TMFZD4",
    "title": "Glass Shield, The",
    "user": "https://robohash.org/omnisetquod.png?size=50x50&set=set1",
    "isRead": false,
    "isArchivate": true,
    "body": "Multi-channelled contextually-based parallelism",
    "date": "5/14/2022"
  }, {
    "id": "01GTMBGHF9NK20PQV0EYK1T1HG",
    "title": "Submarino",
    "user": "https://robohash.org/quocupiditatesint.png?size=50x50&set=set1",
    "isRead": true,
    "isArchivate": false,
    "body": "Reactive asymmetric parallelism",
    "date": "7/30/2022"
  }, {
    "id": "01GTMBGHFCEE3Y6A0GRDCVTC1T",
    "title": "Teenage Mutant Ninja Turtles III",
    "user": "https://robohash.org/quorerumnon.png?size=50x50&set=set1",
    "isRead": false,
    "isArchivate": true,
    "body": "Pre-emptive system-worthy frame",
    "date": "7/24/2022"
  }, {
    "id": "01GTMBGHFFC4Z77RS695KB81X4",
    "title": "Q: The Winged Serpent",
    "user": "https://robohash.org/autcorruptiipsum.png?size=50x50&set=set1",
    "isRead": false,
    "isArchivate": false,
    "body": "Sharable analyzing database",
    "date": "5/13/2022"
  }, {
    "id": "01GTMBGHFJSQ29076EMGV8YYJY",
    "title": "Inbetween Worlds",
    "user": "https://robohash.org/quisquamestnumquam.png?size=50x50&set=set1",
    "isRead": false,
    "isArchivate": true,
    "body": "Universal client-driven throughput",
    "date": "11/16/2022"
  }, {
    "id": "01GTMBGHFNX0T93BDXFQP22HDX",
    "title": "Hip Hop Witch, Da",
    "user": "https://robohash.org/quiasuntbeatae.png?size=50x50&set=set1",
    "isRead": false,
    "isArchivate": false,
    "body": "Cross-group uniform website",
    "date": "11/20/2022"
  }, {
    "id": "01GTMBGHFRHWDBNEHDSX2JR8TJ",
    "title": "Picnic at Hanging Rock",
    "user": "https://robohash.org/occaecativoluptasconsequatur.png?size=50x50&set=set1",
    "isRead": true,
    "isArchivate": true,
    "body": "Self-enabling well-modulated toolset",
    "date": "5/16/2022"
  }, {
    "id": "01GTMBGHFWD0V3Y4G775D1Z6C9",
    "title": "Seduced and Abandoned (Sedotta e abbandonata)",
    "user": "https://robohash.org/omniscorporiseligendi.png?size=50x50&set=set1",
    "isRead": false,
    "isArchivate": false,
    "body": "Pre-emptive logistical forecast",
    "date": "9/8/2022"
  }, {
    "id": "01GTMBGHFZH3SSVKP3Q08DN3HP",
    "title": "Necessities of Life, The (Ce qu'il faut pour vivre)",
    "user": "https://robohash.org/molestiasrerumqui.png?size=50x50&set=set1",
    "isRead": true,
    "isArchivate": true,
    "body": "Open-source 3rd generation utilisation",
    "date": "9/24/2022"
  }, {
    "id": "01GTMBGHG3FG3VKEX12N0F01B3",
    "title": "Sometimes Happiness, Sometimes Sorrow (Kabhi Khushi Kabhie Gham)",
    "user": "https://robohash.org/teneturnesciuntomnis.png?size=50x50&set=set1",
    "isRead": true,
    "isArchivate": true,
    "body": "Function-based client-server migration",
    "date": "9/22/2022"
  }, {
    "id": "01GTMBGHG6Q2XB6QF4KD8XQT2G",
    "title": "Young Adam",
    "user": "https://robohash.org/ipsumnequeveniam.png?size=50x50&set=set1",
    "isRead": true,
    "isArchivate": false,
    "body": "Grass-roots secondary capability",
    "date": "5/4/2022"
  }, {
    "id": "01GTMBGHG98H50TJQKBVED0MHV",
    "title": "Bad Boy Bubby",
    "user": "https://robohash.org/cumautvitae.png?size=50x50&set=set1",
    "isRead": true,
    "isArchivate": false,
    "body": "Profit-focused stable adapter",
    "date": "1/5/2023"
  }, {
    "id": "01GTMBGHGC5KF8QTVEVTTYY26T",
    "title": "Planet Ocean",
    "user": "https://robohash.org/estveniamlaudantium.png?size=50x50&set=set1",
    "isRead": true,
    "isArchivate": true,
    "body": "Future-proofed real-time time-frame",
    "date": "3/24/2022"
  }, {
    "id": "01GTMBGHGFBN8KDMGRRDAZ3NWS",
    "title": "Bhowani Junction",
    "user": "https://robohash.org/architectoaperiamprovident.png?size=50x50&set=set1",
    "isRead": true,
    "isArchivate": true,
    "body": "Configurable client-driven monitoring",
    "date": "5/30/2022"
  }, {
    "id": "01GTMBGHGJMH4DY1TE1V8FXN6Q",
    "title": "White Massai, The (Weisse Massai, Die)",
    "user": "https://robohash.org/suscipitrepellatveniam.png?size=50x50&set=set1",
    "isRead": false,
    "isArchivate": false,
    "body": "Proactive disintermediate standardization",
    "date": "6/7/2022"
  }, {
    "id": "01GTMBGHGP10YHJ8R622Z0M1W8",
    "title": "Hush!",
    "user": "https://robohash.org/consequaturvoluptatemipsum.png?size=50x50&set=set1",
    "isRead": true,
    "isArchivate": true,
    "body": "Cross-group full-range alliance",
    "date": "9/23/2022"
  }, {
    "id": "01GTMBGHGSV4GH6RN88YKMY46K",
    "title": "Werner - Gekotzt wird später",
    "user": "https://robohash.org/molestiaemagnamillum.png?size=50x50&set=set1",
    "isRead": true,
    "isArchivate": true,
    "body": "Configurable maximized Graphical User Interface",
    "date": "11/12/2022"
  }, {
    "id": "01GTMBGHGXMHYQKY692D3P0F7T",
    "title": "Funeral Parade of Roses (Bara no sôretsu)",
    "user": "https://robohash.org/utveritatislaudantium.png?size=50x50&set=set1",
    "isRead": false,
    "isArchivate": false,
    "body": "Synchronised client-server parallelism",
    "date": "6/1/2022"
  }, {
    "id": "01GTMBGHH0KRK8TVQFQ4E9NDS1",
    "title": "I Am Sam",
    "user": "https://robohash.org/enimaut.png?size=50x50&set=set1",
    "isRead": false,
    "isArchivate": false,
    "body": "Public-key bi-directional matrices",
    "date": "8/13/2022"
  }],
    view: 'all',
    isOpenMessage: false,
    notification: null
}

const notificationSlice = createSlice({
   name: 'notification',
   initialState,
   reducers: {
    toggleView (state, { payload }) {
       state.view = payload
    },
    markAllAsRead (state) {
      state.notifications = state.notifications.map( notification => {
        notification.isRead = true
        return notification
      })
    },
    openMessage (state, { payload }) {
      state.isOpenMessage = payload
    },
    message (state, { payload }) {
      state.notification = payload
    },
    readMessage (state, {payload}) {
      state.notifications = state.notifications.map( notification => {
        if( payload.id === notification.id ) {
          return {
            ...payload,
            isRead: true,
          }
        } else {
          return notification
        }
      })
    }
   } 
 })

export const notificationActions = notificationSlice.actions
export const notificationReducer = notificationSlice.reducer