import { Box, Grid, TablePagination } from "@mui/material"
import { rootImage } from "../../../app/core/rootImages"
import { NewsCard } from "./NewsCard"

export const NewsPage = () => {
  return (
    <Box>
      <Grid container rowSpacing={2} spacing={2}>
        {[1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => (
          <Grid item md={4} lg={3} key={index}>
            <NewsCard newsData={DUMMY_DATA[index]} id={item}/>
          </Grid>
        ))}
      </Grid>

      <TablePagination
        sx={{ mt: '15px' }}
        component="div"
        count={100}
        page={1}
        onPageChange={() => console.log('holi')}
        rowsPerPage={1}
        onRowsPerPageChange={() => console.log('holi')}
      />
    </Box>
  )
}


const DUMMY_DATA = [
  {
    date: {
      day: '28',
      month: 'Oct'
    },
    title: 'La conjura de Mbappe',
    description: 'Club, vestuario y afición creen que es posible ganar y pasar en Múnich agarrándose a la figura de su estrella francesa',
    views: '2,907',
    likes: 623,
    comments: 23,
    images: [
      rootImage.news.news1
    ]
  },
  {
    date: {
      day: '28',
      month: 'Oct'
    },
    title: '¿Quién es Enríquez Negreira?',
    description: 'Fue internacional desde la temporada 87-88 y era un habitual espectador de los partidos del Barcelona desde las últimas butacas del palco presidencial del Camp Nou.',
    views: '2,907',
    likes: 623,
    comments: 23,
    images: [
      rootImage.news.news2
    ]
  },
  {
    date: {
      day: '28',
      month: 'Oct'
    },
    title: 'Dos grandes en apuros',
    description: 'Hasta diez veces se han cruzado en el máximo torneo continental, incluso, han protagonizados dos finales. Las dos cayeron del lado azulgrana, en 2009 y 2011.',
    views: '2,907',
    likes: 623,
    comments: 23,
    images: [
      rootImage.news.news3
    ]
  },
  {
    date: {
      day: '28',
      month: 'Oct'
    },
    title: 'Pedraza alivia la plaga de lesiones',
    description: 'el regreso a los entrenamientos de Alfonso Pedraza arrojó algo de luz a la situación por a que pasa la plantilla que entrena Quique Setién.',
    views: '2,907',
    likes: 623,
    comments: 23,
    images: [
      rootImage.news.news4
    ]
  },
  {
    date: {
      day: '28',
      month: 'Oct'
    },
    title: 'De Tomás empieza a carburar',
    description: 'Tocaba volver a esperar. Casi un mes después, el "25" rayista saltaba de nuevo al césped.',
    views: '2,907',
    likes: 623,
    comments: 23,
    images: [
      rootImage.news.news5
    ]
  },
  {
    date: {
      day: '28',
      month: 'Oct'
    },
    title: 'El calvario de Vitolo',
    description: 'Hasta diez veces se han cruzado en el máximo torneo continental, incluso, han protagonizados dos finales. Las dos cayeron del lado azulgrana, en 2009 y 2011.',
    views: '2,907',
    likes: 623,
    comments: 23,
    images: [
      rootImage.news.news6
    ]
  },
  {
    date: {
      day: '28',
      month: 'Oct'
    },
    title: 'La conjura de Mbappe',
    description: 'Club, vestuario y afición creen que es posible ganar y pasar en Múnich agarrándose a la figura de su estrella francesa',
    views: '2,907',
    likes: 623,
    comments: 23,
    images: [
      rootImage.news.news1
    ]
  },
  {
    date: {
      day: '28',
      month: 'Oct'
    },
    title: '¿Quién es Enríquez Negreira?',
    description: 'Fue internacional desde la temporada 87-88 y era un habitual espectador de los partidos del Barcelona desde las últimas butacas del palco presidencial del Camp Nou.',
    views: '2,907',
    likes: 623,
    comments: 23,
    images: [
      rootImage.news.news2
    ]
  },
  {
    date: {
      day: '28',
      month: 'Oct'
    },
    title: 'Dos grandes en apuros',
    description: 'Hasta diez veces se han cruzado en el máximo torneo continental, incluso, han protagonizados dos finales. Las dos cayeron del lado azulgrana, en 2009 y 2011.',
    views: '2,907',
    likes: 623,
    comments: 23,
    images: [
      rootImage.news.news3
    ]
  },
  {
    date: {
      day: '28',
      month: 'Oct'
    },
    title: 'Pedraza alivia la plaga de lesiones',
    description: 'el regreso a los entrenamientos de Alfonso Pedraza arrojó algo de luz a la situación por a que pasa la plantilla que entrena Quique Setién.',
    views: '2,907',
    likes: 623,
    comments: 23,
    images: [
      rootImage.news.news4
    ]
  },
  {
    date: {
      day: '28',
      month: 'Oct'
    },
    title: 'De Tomás empieza a carburar',
    description: 'Tocaba volver a esperar. Casi un mes después, el "25" rayista saltaba de nuevo al césped.',
    views: '2,907',
    likes: 623,
    comments: 23,
    images: [
      rootImage.news.news5
    ]
  },
  {
    date: {
      day: '28',
      month: 'Oct'
    },
    title: 'El calvario de Vitolo',
    description: 'Hasta diez veces se han cruzado en el máximo torneo continental, incluso, han protagonizados dos finales. Las dos cayeron del lado azulgrana, en 2009 y 2011.',
    views: '2,907',
    likes: 623,
    comments: 23,
    images: [
      rootImage.news.news6
    ]
  },
  {
    date: {
      day: '28',
      month: 'Oct'
    },
    title: 'El calvario de Vitolo',
    description: 'Hasta diez veces se han cruzado en el máximo torneo continental, incluso, han protagonizados dos finales. Las dos cayeron del lado azulgrana, en 2009 y 2011.',
    views: '2,907',
    likes: 623,
    comments: 23,
    images: [
      rootImage.news.news6
    ]
  },
  {
    date: {
      day: '28',
      month: 'Oct'
    },
    title: 'El calvario de Vitolo',
    description: 'Hasta diez veces se han cruzado en el máximo torneo continental, incluso, han protagonizados dos finales. Las dos cayeron del lado azulgrana, en 2009 y 2011.',
    views: '2,907',
    likes: 623,
    comments: 23,
    images: [
      rootImage.news.news6
    ]
  },
]
