import { Grid } from '@mui/material'
import { CardImageItem } from '../../../app/components/UI'

export const ImagesGrid = ({ imgs, itemId }: Props ) => {
    return (
        <>
            <CardImageItem url={imgs[Number(itemId) - 1].value} sx={{ width: '100%'}} />

            <Grid container spacing={2} mt="10px">
                <Grid item xs={4} sm={4}>
                    <CardImageItem url={imgs[Number(itemId) - 1].value} />
                </Grid>
                <Grid item xs={4} sm={4}>
                    <CardImageItem url={imgs[Number(itemId) - 1].value} />
                </Grid>
                <Grid item xs={4} sm={4}>
                    <CardImageItem url={imgs[Number(itemId) - 1].value} />
                </Grid>
            </Grid>
        </>
    )
}

interface Props {
    imgs: any
    itemId: number
}
