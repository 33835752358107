import { useState } from "react"
import { useFormik } from "formik"
import { Link, useNavigate } from "react-router-dom"
import { Box, Stack, Typography } from "@mui/material"
import { PermIdentityOutlined, EmailOutlined } from "@mui/icons-material"
import PhoneInput from "react-phone-number-input"
import { toast } from "react-toastify"

// My Imports
import { FormContainer } from "../../../app/components/layout/"
import { FormButton } from "../../../app/Custom"
import {
  DobleInput,
  NormalInput,
  PasswordInput,
  validationSchemaRegister,
} from "../../../app/components/Forms"
import { TerminosCondiciones } from "./TerminosCondiciones"
import { findErros } from "../../../app/utils"
import { AuthRegister } from "../../../app/interfaces"
import { useRequest } from "../../../app/hooks/"
import { apiConfig } from "../../../api/Api.config"

import "react-phone-number-input/style.css"


export const RegisterPage = () => {
  const history = useNavigate()

  const [acceptTerminos, setAccepterminos] = useState(false)
  const [captchaToken, setCaptchaToken] = useState("")
  const [phoneNumber, setPhoneNumber] = useState<any>("")
  const { isLoading,  sendRequest } = useRequest()

  const formik = useFormik({
    initialValues: {
      name: "",
      lastname: "", 
      email: "",
      "confirm email": "",
      password: "",
      "confirm password": "",
      phone: "",
      captchaToken: "",
    },
    validationSchema: validationSchemaRegister,
    onSubmit: async (values: AuthRegister) => {
      const sendData = {
        names: values.name,
        lastnames: values.lastname,
        email: values.email,
        emailC: values["confirm email"],
        Cel: getPhoneNumber(),
        password: values.password,
        passwordC: values["confirm password"],
        aceptTerm: true,
        captcha: getCaptchaToken(),
      }

      await sendRequest(apiConfig.post('/signup', sendData)).then(() => {
        toast.success('Se a creado el usuario con exito')
      })
      history('/auth/login')
    },
  })

  const isInvalidForm: boolean = findErros(formik.errors)
  const isTochedForm: boolean = findErros(formik.touched)
  const getCaptchaToken = (): string => {
    return captchaToken
  }
  const getPhoneNumber = (): string => {
    return phoneNumber
  }

  return (
    <FormContainer
      onSumit={formik.handleSubmit}
    >
      <Stack spacing={2} padding="25px 0">
        <DobleInput
          Icon={PermIdentityOutlined}
          label1={"Name"}
          label2={"Lastname"}
          value={formik.values.name}
          value2={formik.values.lastname}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
          error2={formik.touched.lastname && Boolean(formik.errors.lastname)}
        />

        <NormalInput
          Icon={EmailOutlined}
          label={"Email"}
          type={"Email"}
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched["email"] && Boolean(formik.errors["email"])}
          helperText={formik.errors.email}
        />
        <NormalInput
          Icon={EmailOutlined}
          label={"Confirm Email"}
          type={"Email"}
          value={formik.values["confirm email"]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched["confirm email"] &&
            Boolean(formik.errors["confirm email"])
          }
          helperText={formik.errors["confirm email"]}
        />

        <PasswordInput
          label={"Password"}
          id="1"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.errors["password"]}
        />
        <PasswordInput
          label={"Confirm Password"}
          id="2"
          value={formik.values["confirm password"]}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched["confirm password"] &&
            Boolean(formik.errors["confirm password"])
          }
          helperText={formik.errors["confirm password"]}
        />

        <PhoneInput
          placeholder="Example: +55 123456789"
          value={phoneNumber}
          onChange={(event) => setPhoneNumber(event)}
        />
      </Stack>

      <Box
        display={"flex"}
        alignContent="center"
        justifyContent={"center"}
        flexDirection="column"
        textAlign={"center"}
      >
        {/* <Captcha value={captchaToken} setValue={setCaptchaToken} /> */}
        <TerminosCondiciones
          acceptTerminos={acceptTerminos}
          setAccepTerminos={setAccepterminos}
        />

         <FormButton
            disabled={
              isTochedForm && acceptTerminos 
                ? isInvalidForm
                : true
            }
            size="large"
            type="submit"
            fullWidth
            loading={isLoading}
            loadingPosition="end"
            variant="contained"
            color="secondary"
          >
            create an account
          </FormButton>
        

        <Typography
          variant="body2"
          fontSize={"16px"}
          mt="15px"
          fontWeight="500"
          sx={{ display: "flex", margin: "8px auto", gap: "5px" }}
        >
          You already have an account?
          <Link to={"/auth/login"} style={{ textDecoration: "none" }}>
            <Typography
              component={"strong"}
              variant="body2"
              fontSize={"16px"}
              fontWeight="500"
              color="secondary"
            >
              Log in
            </Typography>
          </Link>
        </Typography>
      </Box>
    </FormContainer>
  )
}
