import { Tab, Paper, Typography, Stack, Button, styled, SxProps, Grid, IconButton, Box } from "@mui/material"
import { TabContext, TabList, TabPanel } from '@mui/lab'
import React from "react"
import { ListNotifications } from "./ListNotifications"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { notificationActions } from "../../../store/slice/notification.slice"
import { BreakLine, Container } from "../../../app/Custom"
import { CancelOutlined } from "@mui/icons-material"

export const NotificationPage = () => {
    const { notifications, isOpenMessage, notification } = useAppSelector(state => state.notification)
    const dispatch = useAppDispatch()

    const [value, setValue] = React.useState('1')

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue)
    }

    const archivateNotification = notifications.filter(({ isArchivate }) => isArchivate)
    const unReadMessageLength = notifications.filter(({ isRead }) => !isRead).length

    const TabDinamyc = TabWithNumber(unReadMessageLength)

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={5.5}>
                <BoxContainerNotification elevation={3}>
                    <TabContext value={value} >
                        <Paper sx={paperStyle} elevation={2}>
                            <Stack direction='row' justifyContent='space-between'>
                                <Typography variant='h5' fontWeight={'bold'} color="#323232">Notifications</Typography>

                                <Button sx={{ color: 'secondary.main' }} onClick={() => dispatch(notificationActions.markAllAsRead())}>Mark as all read</Button>
                            </Stack>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <TabDinamyc label="All" value="1" />
                                <Tab label="Archive" value="2" />
                            </TabList>
                        </Paper>
                        <TabPanelCustom value="1"><ListNotifications listNotification={notifications} /></TabPanelCustom>
                        <TabPanelCustom value="2"><ListNotifications listNotification={archivateNotification} /></TabPanelCustom>
                    </TabContext>
                </BoxContainerNotification>
            </Grid>

            <Grid item xs={12} md={5.5}>
                {isOpenMessage && notification !== null && <Container>

                    <Stack direction='row' justifyContent={'space-between'} alignItems={'center'}>
                        <Typography variant='subtitle1'>Notification</Typography>
                        <IconButton onClick={() => dispatch(notificationActions.openMessage(false))}>
                            <CancelOutlined  color="error"/>
                        </IconButton>
                    </Stack>
                        
                    <Typography variant='h4' fontWeight={'800'}>{notification.title}</Typography>
                    <BreakLine sx={{ background: 'rgb(0, 128, 178)', height: '15px', mt: '5px', mb: '5px' }} />
                    <Typography variant='subtitle1'>
                        {notification.date}    
                    </Typography>

                    <Box mt="25px">
                       <Typography variant='body1'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias officia, adipisci repudiandae excepturi quos consequatur modi porro illo obcaecati minus tempore quam et eos voluptates!</Typography> 
                       <Typography mt='15px' variant='body1'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias officia, adipisci repudiandae excepturi quos consequatur modi porro illo obcaecati minus tempore quam et eos voluptates!</Typography> 
                       <Typography mt='15px' variant='body1'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias officia, adipisci repudiandae excepturi quos consequatur modi porro illo obcaecati minus tempore quam et eos voluptates!</Typography> 
                    </Box>
                </Container>}
            </Grid>
        </Grid>
    )
}

const paperStyle: SxProps = {
    borderBottom: 1,
    borderColor: 'divider',
    padding: '25px 25px 0'
}

const TabPanelCustom = styled(TabPanel)(() => ({
    background: '',
    padding: '0',
}))

const TabWithNumber = (num: number) => {
    return styled(Tab)(({ theme }) => ({
        position: 'relative',
        paddingRight: '30px',
        '&:after': {
            content: `"${num}"`,
            position: 'absolute',
            width: '28px',
            height: '25px',
            color: '#fff',
            backgroundColor: theme.palette.secondary.light,
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            right: '2px'
        }
    }))
}

const BoxContainerNotification = styled(Paper)(() => ({
    background: '#fff',
    borderRadius: '8px',
    overflow: 'hidden',
    width: '100%',
}))

