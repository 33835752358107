import React from 'react'
import { Box } from '@mui/material'

export const PartnersSquare = ({ index }: any) => {
   const arr = ["https://www.pmits.co.uk/portals/0/images/partners/solar-communications-200.png", "https://www.pmits.co.uk/portals/0/images/partners/jpr-200.png", "https://www.pmits.co.uk/Portals/0/pegasus-logo.png", "https://www.pmits.co.uk/Portals/0/sage business partner.jpg"  ] 
  return (
    <Box className="square-holder" height={"100%"} borderRadius="25px">
      <img alt="" src={arr[index]} />
    </Box>
  )
}
