import { Box, Grid, Typography } from '@mui/material'
import { PartnersSquare } from './PartnersSquare'

export const Partners = () => {
  return (
    <Box mt="45px">
        <Typography mb={'15px'} variant='h4' color="#000000de" textTransform={'uppercase'} fontWeight={'800'}>Our <strong>Partners</strong>  </Typography>
        <Grid container spacing={2}>
                {
                    [1,2,3,4].map((num, index) => (
                        <Grid item md={3} key={index}>
                            <PartnersSquare index={index}/>
                        </Grid>
                    ))
                }
        </Grid>
    </Box>
  )
}
