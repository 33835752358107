import { useState } from "react"
import { toast } from "react-toastify"
import { Box, Button, Grid, styled, SxProps, Typography, darken } from "@mui/material"
import { useParams } from "react-router-dom"
import { rootImage } from "../../../app/core/rootImages"
import { BasicCardContainer, BreakLine } from "../../../app/Custom"
import { Counter } from "./Counter"
import { SizeSelector } from "./sizeSelector/SizeSelector"
import { ImagesGrid } from "./ImagesGrid"
import { CarritoButtons } from "./Carrito/CarritoButtons"
import { OtherProduct } from "./OtherProduct/OtherProduct"
import { useAppDispatch } from "../../../app/hooks"
import { shopActions } from "../../../store/slice/shop.slice"

export const ItemDetails = () => {
  const dispatch = useAppDispatch()

  const [itemSelect, setItemSelect] = useState(false)

  const addToCart = () => {
    dispatch(shopActions.addOne())
    setItemSelect(true)
    toast.success('Item Added to your cart')
  }

  let { itemId = 1 } = useParams()

  itemId = +itemId === 6 ? 2 : +itemId

  const imgs = []

  for (const [key, value] of Object.entries(rootImage.camisas)) {
    if (!key.includes("barca")) imgs.push({ key, value })
  }

  return (
    <Box>
      <CarritoButtons addToCart={addToCart} itemSelect={itemSelect}/>

      <Grid container spacing={2} mb="25px">
        <Grid
          item
          md={6}
          display="flex"
          alignItems={"center"}
          justifyContent="center"
          flexDirection={"column"}
        >
          <ImagesGrid itemId={itemId} imgs={imgs} />
        </Grid>

        <Grid item md={6}>
          <BasicCardContainer sx={BasiCardStyled}>
            <Typography variant="h3" sx={TitleStyles}>
              {" "}
              New season T-shirt{" "}
            </Typography>

            <BreakLine sx={BreackLineStyles} />

            <Box
              flex="1 1"
              my={"19px"}
              justifyContent="space-between"
              display="flex"
              flexDirection={"column"}
            >
              <Box mb="15px">
                <Typography color="#fff" variant="body1" sx={descriptionStyles}>
                  Clean, classic and created to mark a milestone. The timeless
                  look of this NFansT home authentic jersey is accented with
                  light blue details and a repeated club badge engineered into
                  its white fabric. This version of the shirt is made to perform
                  on the pitch with cooling HEAT.RDY.
                </Typography>
                <Typography
                  color="#fff"
                  variant="body1"
                  sx={descriptionStyles}
                  mt="10px"
                >
                  Made with 100% recycled materials, this product represents
                  just one of our solutions to help end plastic waste.
                </Typography>

                <Box mt="25px">
                  <SizeSelector />
                </Box>
              </Box>

              <Counter />
            </Box>

            <Button
              variant="contained"
              fullWidth
              color="secondary"
              sx={{ borderRadius: "25px", "&:disabled": { background: darken("#00b8ff", .5), color: darken("#fff", .5) } }}
              size="medium"
              disabled={itemSelect}
              onClick={() => addToCart()}
            >
              Add to Card
            </Button>
          </BasicCardContainer>
        </Grid>
      </Grid>

      <Box display="flex" mt="25px" mb="25px">
        <Typography fontWeight={800} fontSize="35px" display={'flex'} alignItems="center" justifyContent={'center'} gap={{ md: "25px", sx: '0'}} my="25px" flexWrap={'wrap'}>
          YOU MIGHT ALSO LIKE
          <span style={spanStyle}>
            <DashBox /> 
            <DashBox /> 
            <DashBox /> 
            <DashBox /> 
            <DashBox /> 
            <DashBox /> 
            <DashBox /> 
            <DashBox /> 
            <DashBox />
          </span>
        </Typography>
      </Box>


      <Box display="flex" gap="15px" flexWrap={"wrap"}>
        {[1, 2, 3].map((num, index) => (
          <OtherProduct key={index} num={num} />
        ))}
      </Box>
    </Box>
  )
}

const BasiCardStyled: SxProps = {
  bgcolor: "primary.main",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: {
    sx: "33px 55px",
    lg: "33px 20px",
    xl: "33px 55px",
  },
}

const BreackLineStyles: SxProps = {
  mt: "15px",
  height: "10px",
  width: "80px",
}

const TitleStyles: SxProps = {
  fontWeight: "800",
  color: "secondary.main",
  fontSize: {
    xs: "35px",
    sm: "45px",
    md: "30px",
    lg: "40px",
    xl: "45px",
  },
}
const descriptionStyles: SxProps = {
  fontWeight: "500",
  color: "#fff",
  fontSize: { xs: "12px", sm: "18px", md: "15px", lg: "14", xl: "15px" },
}

const DashBox = styled(Box)(() => ({
  backgroundColor: 'rgba(0, 0, 0, 0.87)',
  transform: 'skew(-30deg)',
  height: 'auto',
  width: '10px'
})) 


const spanStyle: React.CSSProperties = {
  display: 'flex',
  gap: '10px',
  maxHeight: '30px',
  height: '100%',
  marginLeft: '10px',
}
