import { createSlice} from '@reduxjs/toolkit'

const initialState = {
   slate: [{
       type: "paragraph",
       children: [{ text: "" }]
   }],
   messages: [{
    "user": {
      "icon": "https://robohash.org/quisinciduntsapiente.png?size=50x50&set=set1",
      "name": "Kally MacGaffey"
    },
    "title": "Exotica",
    "body": "eu est congue elementum in hac habitasse platea dictumst morbi vestibulum velit id pretium iaculis",
    "isFavorite": true,
    "isNew": true,
    "posted": "12/1/2022"
  }, {
    "user": {
      "icon": "https://robohash.org/voluptasautrecusandae.png?size=50x50&set=set1",
      "name": "Lenee Cromarty"
    },
    "title": "Crude",
    "body": "lacus morbi quis tortor id nulla ultrices aliquet maecenas leo odio condimentum id luctus nec molestie",
    "isFavorite": false,
    "isNew": false,
    "posted": "1/18/2023"
  }, {
    "user": {
      "icon": "https://robohash.org/molestiaeaccusamuset.png?size=50x50&set=set1",
      "name": "Martha Lafrentz"
    },
    "title": "Ugly",
    "body": "congue risus semper porta volutpat quam pede lobortis ligula sit amet eleifend pede",
    "isFavorite": false,
    "isNew": true,
    "posted": "4/10/2022"
  }, {
    "user": {
      "icon": "https://robohash.org/consequaturidvoluptatem.png?size=50x50&set=set1",
      "name": "Genny Kepling"
    },
    "title": "French Lieutenant's Woman, The",
    "body": "neque aenean auctor gravida sem praesent id massa id nisl venenatis lacinia aenean sit amet justo",
    "isFavorite": true,
    "isNew": false,
    "posted": "5/16/2022"
  }, {
    "user": {
      "icon": "https://robohash.org/placeatreiciendisaut.png?size=50x50&set=set1",
      "name": "Tommy O'Fearguise"
    },
    "title": "K-19: The Widowmaker",
    "body": "sapien sapien non mi integer ac neque duis bibendum morbi non quam nec dui luctus rutrum",
    "isFavorite": true,
    "isNew": false,
    "posted": "9/18/2022"
  }, {
    "user": {
      "icon": "https://robohash.org/veldictaipsam.png?size=50x50&set=set1",
      "name": "Gordan Jerdein"
    },
    "title": "Ransom (a.k.a. The Terrorists)",
    "body": "tellus in sagittis dui vel nisl duis ac nibh fusce lacus purus aliquet at feugiat non pretium quis lectus",
    "isFavorite": true,
    "isNew": false,
    "posted": "11/9/2022"
  }, {
    "user": {
      "icon": "https://robohash.org/illumnatusipsum.png?size=50x50&set=set1",
      "name": "Darice Muckart"
    },
    "title": "Dinner with Friends",
    "body": "nulla eget eros elementum pellentesque quisque porta volutpat erat quisque erat",
    "isFavorite": true,
    "isNew": false,
    "posted": "1/15/2023"
  }, {
    "user": {
      "icon": "https://robohash.org/temporesitquo.png?size=50x50&set=set1",
      "name": "Rubie Barfield"
    },
    "title": "Legend, The (Legend of Fong Sai-Yuk, The) (Fong Sai Yuk)",
    "body": "nec euismod scelerisque quam turpis adipiscing lorem vitae mattis nibh ligula nec sem duis aliquam convallis",
    "isFavorite": true,
    "isNew": false,
    "posted": "5/1/2022"
  }, {
    "user": {
      "icon": "https://robohash.org/autsedquisquam.png?size=50x50&set=set1",
      "name": "Collie Rolfi"
    },
    "title": "Story of Me, The (O contador de histórias)",
    "body": "velit donec diam neque vestibulum eget vulputate ut ultrices vel augue vestibulum ante",
    "isFavorite": true,
    "isNew": false,
    "posted": "11/4/2022"
  }, {
    "user": {
      "icon": "https://robohash.org/idearumtempora.png?size=50x50&set=set1",
      "name": "Johann Cleveland"
    },
    "title": "Trans-Atlantic Tunnel (Tunnel, The)",
    "body": "morbi a ipsum integer a nibh in quis justo maecenas rhoncus aliquam lacus morbi quis tortor",
    "isFavorite": true,
    "isNew": false,
    "posted": "1/4/2023"
  }, {
    "user": {
      "icon": "https://robohash.org/numquamsaepequasi.png?size=50x50&set=set1",
      "name": "Gertrudis Sowten"
    },
    "title": "Boy in Blue, The",
    "body": "duis at velit eu est congue elementum in hac habitasse platea dictumst morbi vestibulum velit id pretium iaculis diam erat",
    "isFavorite": false,
    "isNew": true,
    "posted": "11/19/2022"
  }, {
    "user": {
      "icon": "https://robohash.org/veroetest.png?size=50x50&set=set1",
      "name": "Boigie Gladding"
    },
    "title": "Pekka ja Pätkä salapoliiseina",
    "body": "cras pellentesque volutpat dui maecenas tristique est et tempus semper est quam pharetra magna ac consequat metus",
    "isFavorite": true,
    "isNew": true,
    "posted": "11/13/2022"
  }, {
    "user": {
      "icon": "https://robohash.org/excepturivoluptatemfuga.png?size=50x50&set=set1",
      "name": "Alena Briscoe"
    },
    "title": "Star of Midnight",
    "body": "ante vivamus tortor duis mattis egestas metus aenean fermentum donec ut mauris",
    "isFavorite": false,
    "isNew": true,
    "posted": "7/2/2022"
  }, {
    "user": {
      "icon": "https://robohash.org/modiquioccaecati.png?size=50x50&set=set1",
      "name": "Gracia Buckmaster"
    },
    "title": "Jeffrey",
    "body": "justo etiam pretium iaculis justo in hac habitasse platea dictumst etiam faucibus",
    "isFavorite": true,
    "isNew": true,
    "posted": "8/22/2022"
  }, {
    "user": {
      "icon": "https://robohash.org/eumlaboriosamest.png?size=50x50&set=set1",
      "name": "Frank Dye"
    },
    "title": "Invisible Man, The",
    "body": "nam congue risus semper porta volutpat quam pede lobortis ligula sit amet eleifend",
    "isFavorite": true,
    "isNew": true,
    "posted": "2/15/2023"
  }, {
    "user": {
      "icon": "https://robohash.org/dictasedaliquid.png?size=50x50&set=set1",
      "name": "Warner Whye"
    },
    "title": "Man from London, The (A Londoni férfi)",
    "body": "curae duis faucibus accumsan odio curabitur convallis duis consequat dui nec nisi volutpat eleifend donec ut",
    "isFavorite": false,
    "isNew": true,
    "posted": "8/2/2022"
  }, {
    "user": {
      "icon": "https://robohash.org/auteumaut.png?size=50x50&set=set1",
      "name": "Felice Ruppert"
    },
    "title": "Superstar",
    "body": "mattis nibh ligula nec sem duis aliquam convallis nunc proin at turpis",
    "isFavorite": false,
    "isNew": false,
    "posted": "8/27/2022"
  }, {
    "user": {
      "icon": "https://robohash.org/recusandaesequiat.png?size=50x50&set=set1",
      "name": "Guillermo Whether"
    },
    "title": "Johnny Angel",
    "body": "urna ut tellus nulla ut erat id mauris vulputate elementum nullam varius",
    "isFavorite": false,
    "isNew": true,
    "posted": "1/13/2023"
  }, {
    "user": {
      "icon": "https://robohash.org/autnihileligendi.png?size=50x50&set=set1",
      "name": "Saraann Birkby"
    },
    "title": "Powaqqatsi",
    "body": "mauris morbi non lectus aliquam sit amet diam in magna bibendum imperdiet nullam orci pede",
    "isFavorite": true,
    "isNew": true,
    "posted": "5/25/2022"
  }, {
    "user": {
      "icon": "https://robohash.org/facilisiurevoluptatem.png?size=50x50&set=set1",
      "name": "Goldia Hazle"
    },
    "title": "Temptations, The",
    "body": "fusce lacus purus aliquet at feugiat non pretium quis lectus suspendisse potenti in eleifend quam",
    "isFavorite": true,
    "isNew": true,
    "posted": "3/20/2022"
  }, {
    "user": {
      "icon": "https://robohash.org/velitconsequunturin.png?size=50x50&set=set1",
      "name": "Chiquita Ewens"
    },
    "title": "Lost Youth",
    "body": "felis sed interdum venenatis turpis enim blandit mi in porttitor",
    "isFavorite": false,
    "isNew": false,
    "posted": "6/19/2022"
  }, {
    "user": {
      "icon": "https://robohash.org/voluptaseiusalias.png?size=50x50&set=set1",
      "name": "Charlie Thornton-Dewhirst"
    },
    "title": "Twenty Days Without War (Dvadtsat dney bez voyny)",
    "body": "non mattis pulvinar nulla pede ullamcorper augue a suscipit nulla elit ac nulla sed vel enim sit amet nunc",
    "isFavorite": true,
    "isNew": true,
    "posted": "5/28/2022"
  }, {
    "user": {
      "icon": "https://robohash.org/etistemodi.png?size=50x50&set=set1",
      "name": "Jo-anne Stolli"
    },
    "title": "Velocity of Gary, The",
    "body": "vitae ipsum aliquam non mauris morbi non lectus aliquam sit amet diam in magna bibendum imperdiet nullam orci pede",
    "isFavorite": false,
    "isNew": false,
    "posted": "9/29/2022"
  }, {
    "user": {
      "icon": "https://robohash.org/doloribusducimusquia.png?size=50x50&set=set1",
      "name": "Ashly Sutor"
    },
    "title": "Twin Dragons (Shuang long hui)",
    "body": "sed vel enim sit amet nunc viverra dapibus nulla suscipit ligula in",
    "isFavorite": false,
    "isNew": false,
    "posted": "1/5/2023"
  }, {
    "user": {
      "icon": "https://robohash.org/sapienteinciduntiste.png?size=50x50&set=set1",
      "name": "Stanislaus Archard"
    },
    "title": "Husbands",
    "body": "aliquam non mauris morbi non lectus aliquam sit amet diam",
    "isFavorite": true,
    "isNew": true,
    "posted": "7/23/2022"
  }],
  view: 'all',  
  preview: 'full',
  showBody: false
}

const messageSlice = createSlice({
   name: 'message',
   initialState,
   reducers: {
    textEditor (state, { payload }) {
       state.slate = payload
    },
    cleanEditor (state) {
        state.slate = [{
            type: "paragraph",
            children: [{ text: "" }]
        }]
    },
    newMessage (state, { payload }) {
        state.messages.push(payload)
    },
    sortFor (state, { payload }) {
      state.view = payload
    },
    setShowBody (state, { payload }) {
      state.showBody = payload
    }
   }
 })

export const messageActions = messageSlice.actions
export const messageReducer = messageSlice.reducer