import { TextField, Grid, Box, useTheme } from "@mui/material"

export const DobleInput = (props: Props) => {
  const { Icon, label1, label2 } = props

  const theme = useTheme()

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Icon sx={{ 
        color: props.error ? theme.palette.error.light : "#0000008a",
        mr: 1, my: 0.5,
        mb: props.error ? '29px' : '0px'
      }} fontSize="large" />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            variant="outlined"
            size="medium"
            onChange={props.onChange}
            onBlur={props.onBlur}
            name={label1.toLowerCase()}
            type={"text"}
            label={label1}
            value={props.value}
            error={props.error}
            helperText={props.error && "must be greater than 3 characters"}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            onChange={props.onChange}
            onBlur={props.onBlur}
            label={label2}
            type={"text"}
            name={label2.toLowerCase()}
            variant="outlined"
            size="medium"
            fullWidth
            value={props.value2}
            error={props.error2}
            helperText={props.error2 && "must be greater than 3 characters"}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

interface Props {
  Icon: any
  label1: string
  label2: string
  value: string
  value2: string
  onChange: any
  onBlur: any
  error: boolean | undefined
  error2: boolean | undefined
}
