import { useState } from "react"
import { Box, Button,  darken,  Dialog, Grid, Paper, styled, SxProps, Typography } from "@mui/material"
import Stack from "@mui/material/Stack"
import { useClock, useRequest } from "../../../../app/hooks"
import { Clock } from "./Clock"
import { LoadingModal } from "./Modal/LoadingModal"
import { SuccessModal } from "./Modal/SuccessModal"
import { NoAccessModal } from "./Modal/NoAccessModal"

export const SubastaCard = (props: Props) => {
  const { text = 'NFT Exclusive', subText = 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime, ipsa?', disabled } = props
  const { buttonText = 'Place a bid', exclusive } = props

  const { seconds, days, hours, leading0, minutes } = useClock(props.timeLeft)
  const { sendRequest, isLoading } = useRequest()

  const [isBuy, setBuy] = useState(false)
  const [viewWeb, setViewWeb] = useState(false)
  const [component, setComponent] = useState<null | string>(null)
  const [complete, setComplete ] = useState(false)

  const handleClick = () => {
    setComponent(null)
    setBuy(true)

    if(viewWeb || component === 'loading') {
      setComponent('loading')
      setTimeout(() => setComponent('success') ,3000)
      return
    }

    const promise = new Promise((resolve) => {
      setTimeout(() => resolve(disabled) , 3000)
    })

    sendRequest(promise).then((res) => {
      if( !res ) {
        setComponent('success')
      } else {
        setComponent('no-access')
      }
    })
  }

  const buyNfant = () => {
    if(!viewWeb) {
      window.open('https://marketplacenfanst.devtop.online/')
      setViewWeb(true)
      setComponent('loading')
      setTimeout(() => {
        setComponent('success')
        setComplete(true)
      } ,10000)
    }
  }

  return (
    <>
      <BasicCardContainer className={ exclusive ? 'exclusive-subasta' : ''} elevation={2} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <Box>
          <Grid container>
            <Grid item display={props.ticket ? 'flex' : 'none'} xs={6}>
              <ResponsiveImg src={props.ticket} width="60%" />
            </Grid>
            <Grid item display={'flex'} xs={props.ticket ? 6 : 12}>
              <ResponsiveImg src={props.imgCamisa} alt="camisa" />
            </Grid>
          </Grid>

          <Stack mt="15px">
            <Typography fontSize={{ xs: '18px', md: '25px', xl: '28px' }} variant="h4" fontWeight={'800'} textTransform="uppercase">{text}</Typography>
            <Typography variant='subtitle1' mt="5px">{subText}</Typography>
          </Stack>
        </Box>

        <Box mt="15px">
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <CustomPaper elevation={0}>
                <Typography variant="body2">Time Left</Typography>
                <Clock seconds={leading0(seconds)} day={leading0(days)} hrs={leading0(hours)} min={leading0(minutes)} />
              </CustomPaper>
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomPaper elevation={0}>
                <Typography variant="body2">Minimum Bid</Typography>

                <Typography variant="h4" fontWeight={'800'} fontSize={{ xs: '20px', lg: '20px', xl: '30px' }} >3 wETH</Typography>
                <Typography variant="body2">${props.price}</Typography>
              </CustomPaper>
            </Grid>
          </Grid>

          <Button  
            onClick={handleClick}
            size="large" 
            fullWidth 
            variant="contained" 
            color="secondary" 
            sx={disabled && !complete ? {...disabledStyle, ...{ borderRadius: '12px', mt: '15px' }} : { ...{ borderRadius: '12px', mt: '15px' }}}>
            {buttonText}
          </Button>
        </Box>
      </BasicCardContainer>
      
      <Dialog
        open={isBuy}
        onClose={() => setBuy(false)}
        >
          {isLoading && <LoadingModal />}
          {component === 'loading' && <LoadingModal />}
          {component === 'success' && <SuccessModal seconds={leading0(seconds)} day={leading0(days)} hrs={leading0(hours)} min={leading0(minutes)} onClose={() => setBuy(false)} />}
          {component === 'no-access' && <NoAccessModal onClose={() => setBuy(false)} buyNfants={buyNfant} />}
      </Dialog>
    </>
  )
}

interface Props {
  imgCamisa: string
  ticket?: string
  timeLeft: string
  id: string | number
  price: string
  text?: string
  disabled?: boolean
  subText?: string
  buttonText?:string
  exclusive?: boolean
}

const disabledStyle: SxProps = {
  color: "#c2c2c2",
  background: darken("#00b8ff", .5),
}

const BasicCardContainer = styled(Paper)(({ theme }) => ({
  background: '#FFF',
  borderRadius: '25px',
  backgroundColor: '#fff',
  height: '100%',
  [theme.breakpoints.up('xl')]: {
    padding: theme.spacing(4),
  },
  [theme.breakpoints.down('xl')]: {
    padding: theme.spacing(4, 2),
  },
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(2),
  }
}))

const ResponsiveImg = styled('img')(() => ({
  maxWidth: '100%',
  maxHeight: '290px',
  margin: '0 auto'
}))

const CustomPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.business.background,
  borderRadius: '15px',
  height: '100%',
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(2, 1),
  },
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(1),
  }
}))