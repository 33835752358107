
// My imports ------------------------------------
import { AppRouter } from "./app/router/AppRouter"
import './assets/sass/main.scss'

function App() {
  return <AppRouter />
}

export default App
