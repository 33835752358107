import { Box, Stack, Typography } from '@mui/material'
import { MessagesCard } from '../../../app/components/UI'
import { rootImage } from '../../../app/core/rootImages'

function AdminMessages() {
  return (
    <Box bgcolor={'rgba(245,245,245,1)'} p={3} maxWidth={'1200px'} mx={'auto'}>
      <Stack>
        <Typography my={4} fontWeight={'bold'} fontSize={'3em'}>
          Messages
        </Typography>
        <Box my={2}>
          <Typography color={'gray'}>Today</Typography>
        </Box>
        <Stack spacing={1}>
          <MessagesCard
            img={rootImage.avatar.default}
            name="Jhon Doe"
            time="1h ago"
            message="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
          />
          <MessagesCard
            img={rootImage.avatar.default}
            name="Louis G"
            time="1h ago"
            message="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
          />
          <MessagesCard
            img={rootImage.avatar.default}
            name="Amanda T"
            time="2h ago"
            message="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
          />
        </Stack>
        <Box my={2}>
          <Typography color={'gray'}>Yestarday</Typography>
        </Box>
        <Stack spacing={1}>
          <MessagesCard
            img={rootImage.avatar.default}
            name="Jhonny E"
            time="22h ago"
            message="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
          />
          <MessagesCard
            img={rootImage.avatar.default}
            name="Isa T"
            time="1d ago"
            message="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
          />
        </Stack>
      </Stack>
    </Box>
  )
}
export default AdminMessages
