import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { authActions } from '../../../../store/slice/auth.slice'
import { isValidAddress } from '../../../../wallet/ConnectWallet'

import { useAppDispatch } from '../../../hooks'
import { useWallet } from '../../../hooks/useWallet'
import AlertDialogSlide from '../Modal/ModalAlert'

export const VerifyAdmin: any = ({ children }: any) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  
  const { active, connect, loading, varifyAdmin } = useWallet()

  const [isAdmin, setIsAdmin] = useState<any>(1003)

  const connectarWalletAlCargarLaPagina = useCallback(async () => {
    const res = await connect()

    if ( isValidAddress( String(res) ) ) {
      dispatch(authActions.setWallet(res))

      await varifyAdmin(String(res)).then((res: any) => {
        if (res.isAdmin) {
          toast.success("Loggin Success")
          setIsAdmin(1001)
          dispatch(authActions.setId(res.id))
        } else {
          toast.error("Route only valid for admin")
          setIsAdmin(1002)
        }
      })
    } else {
      setIsAdmin(false)
    }
  } ,[])


  useEffect(() => {
    connectarWalletAlCargarLaPagina()
  }, [])

  if (!active)
    return <AlertDialogSlide title='In order to access the dashboard you must connect your wallet' loading={loading} onClick={connectarWalletAlCargarLaPagina} />

  if(isAdmin === 1003) 
    return <AlertDialogSlide title='We are validating the credentials, please wait' subTitle='go to home page' loading={loading} onClick={() => navigate('/')} />

  if (isAdmin === 1002) 
    return navigate('/')

  return <div>{children}</div>
}