import { Typography, SxProps, Box } from '@mui/material'
import { AccordionComponent } from '../../../../app/components/UI'

export const AcordionsSection = ({ start = 0, end = 3, onChange, setExpanded }: Props ) => {

  return (
    <Box display="flex"  flexDirection='column' gap='25px'>
        {DUMMY_DATA.slice(start, end).map(({ title, description, description2, description3, Extrabenefits = [] }, index) => {
            const TitleComponent = <Typography sx={titleStyled}>{title}</Typography>
            const Description : JSX.Element = description ? <Typography sx={descriptionStyles} variant={'body1'}>{description}</Typography> : <></>
            const Description2: JSX.Element = description2 ? <Typography sx={descriptionStyles} mt='15px' variant={'body1'}>{description2}</Typography> : <></>
            const Description3: JSX.Element = description3 ? <Typography sx={descriptionStyles} mt='15px' variant={'body1'}>{description3}</Typography> : <></>
            const Extras = Extrabenefits.map(item => {return <li><Typography sx={descriptionStyles} variant='body2'>{item}</Typography></li>})

            return (
            <AccordionComponent
                id={((index + start)).toString() }
                TitleComponent={TitleComponent}
                onChange={setExpanded}
                expanded={onChange}
                key={(index + 1)}
            >
                {Description}
                {Description2}
                {Description3}
                <ul>
                    {Extras}
                </ul>
            </AccordionComponent>
            )
        })}
    </Box>
  )
}
interface Props {
    start?: number
    end?: number
    setExpanded: any
    onChange: any
}

const titleStyled: SxProps = {
    fontWeight: '600',
    fontSize: '20px'
  }
  
const descriptionStyles: SxProps = {
fontWeight: '500',
fontSize: '18px',
}
  
const DUMMY_DATA = [
{
    title: "What is it NFansT NFT?",
    description:
    "The NFansT NFT doubles as a VIP card to our exclusive and limited web 3.0 community, and grants access to members-only benefits and the opportunity to discover a host of new VIP experiences and content that you would not be able to enjoy otherwise.",
},
{
    title: "Where I can buy or sell the NFansT NFT? Are they minted?",
    description: "NFansT can be bought and sold on our platform or any external marketplace that works on the polygon network such as Opensea.",
},
{
    title: "Which are the available Tiers and how they work",
    description:
    "You can choose between 4 tiers of membership: COMMON, RARE, EXCLUSIVE and UNIQUES. Each tier will grant you the acces to certains benefits and services. But do not worry, if your tier denied the access to something you want you will always have the chances of paying a one-time fee to access to it.",
},
{
    title: "What is it the Digital ID and its difference with the NFansT NFT",
    description:
    "The NFansT NFT is your membership access to the platform. Holders of these NFTs automatically become members of the exclusive club, and these are a potential admission ticket to an ecosystem with different content, benefits and exclusive experiences.",
    description2:
    "The Digital ID, is a NFT that you receive as a gift and that works as a PFP (NFT profile pictures used on Social Medias). You can use it as your avatar on Twitter or Instagram to indicate your affiliation with our NFT community. Also you can display this PFP NFTs on your profiles as a gallery to showcase an expensive collectible",
},
{
    title: "When are the Pre-sale and when the official launches?",
    description:
    "The pre-sale will be set for the 20 of June of 2023. You will be able to buy 1000 Common NFT, 100 Rare, 10 Unique and 1 Exclusive at one special price. On the 1 of August of 2023 will be the official launch of the collection.",
},
{
    title: "What are the fees for the resale of my NFansT NFT?",
    description: "Once you own the NFansT NFT you will be able to re sell it. For do this, the NFansT NFT must be minted. NFansT NFTs have a 5% royalty",
},
{
    title: "What I can do in the platform?",
    description: "As a member of our community you will gets access to",
    Extrabenefits: [
    "As a member of our community you will gets access to",
    "Receive a NFT PFP as a Gift",
    "Access exclusive discounts on official products",
    "Participate i exclusive events",
    "Acquire unique products at auction",
    "Participate streaming with your idols",
    "Possibility to meet other members of the community",
    "Access product pre-sales",
    "And a lot, lot more",
    ],
},
{
    title: "How Can I launch my own NFT on the platform?",
    description:
    "Community members will have the option to launch NFTs on the exclusive marketplace for the community. NFTs will be subject to approval. They may be launched at a fixed price or under auction. NFansT will keep a % of the sale.",
},
{
    title: "How long have I secured my access to the Platform?",
    description:
    "Once you get the NFT you will secured the access to the community for 1 year form its launch. After that time you will need to pay a renew Fee of 20 usd for the year, this means you need both to login to the community, the NFansT NFT and the subscription paid. If you do not want to, you can sell the NFT so other fan can enjoy it paying the second year access fee.",
},
{
    title: "How it works the renew of the subscription?",
    description:
    "From the moment the NFT is sold by us and for one year, the subscription is included in the price. After one year it is necessary to pay the subscription FEE again to be able to access the community. The payment of this subscription will be made by credit card and only once.",
    description2:
    "If you sell your NFT in the middle of a subscription, the buyer will enjoy the time remaining until its expiration.",
    description3:
    "If you decide not to renew, you can keep the NFT and renew later or sell it and have the new owner pay for the renewal.",
},
{
    title: "What happens if I don't want to renew the subscription?",
    description:
    "If you do not want to pay the fee of renew, you can sell the NFT on our platform or a third party platform like OpenSea so other fan can enjoy it paying the second year access fee.",
},
]
