import { Box, Button, Grid, Paper, Stack } from "@mui/material"
import Typography from "@mui/material/Typography"
import { styled, SxProps } from "@mui/system"
import { useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { rootImage } from "../../../app/core/rootImages"
import { BannerLive, ButtonWhite } from "../../../app/Custom"
import { useClock } from "../../../app/hooks"
import { SubastaCard } from "../../Dashboard/home/subastas/SubastaCard"
import { DuetCard } from "./DuetCard"
import { HeaderImages } from "./HeaderImages"

export const Duete = () => {
    const DateMemo = useMemo(
        () =>
            new Date(new Date().setSeconds(new Date().getSeconds() + 30)).toString(),
        []
    )
    const { minutes, seconds, leading0 } = useClock(DateMemo)
    const navigate = useNavigate()

    return (
        <BasicCardContainer>
            <Grid container spacing={3}>
                <Grid item md={3} xs={0} display={{ md: "block", xs: "none" }}>
                    <HeaderImages />
                </Grid>
                <Grid item md={9} >
                    <Typography variant="h3" color="primary" fontWeight={"800"} fontSize={{  md: '35px' ,xl: '48px'}}>
                        Aikira-NFansT, the perfect Duet
                    </Typography>
                    <BreakLine />

                    <Typography variant="body1" fontSize={'14px'}>
                        The members of the community will find many exclusive benefits that
                        they can be access either by auction or direct purchase. Let's
                        imagine here from signed CDs, clothes used in recitals, discounts on
                        recital tickets, access to virtual talks, auction to spend an hour
                        with Aikira or NFanst or even both, VIP experiences, etc.
                    </Typography>

                    <Typography variant="body1" mt="8px" fontSize={'14px'}>
                        Fans who own these NFTs will be able to access to the community of NFnasT , the Collaborative community between "NfansT" and "Aikira" and finally the  exclusive community that will be created for "Aikira"
                    </Typography>
                </Grid>
            </Grid>

            <Grid container mt="25px" spacing={3}>
                <Grid item xs={12} md={6}>
                    <BoxVisit elevation={3} sx={{ background: "#13017b" }}>
                        <Grid container padding={"10px 35px"}>
                            <Grid item xs={12} md={6}>
                                <ResponsiveImg
                                    src={rootImage.avatar.shakiraBlanco}
                                    width="70%"
                                    alt="Aikira  "
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Stack justifyContent={"center"} height="100%">
                                    <Typography variant="h3" color="white" fontWeight={"800"}>
                                        Aikira
                                    </Typography>
                                    <Typography variant="h4" color="white" fontWeight={"200"}>
                                        WEB 3.0
                                    </Typography>

                                    <Box>
                                        <Button
                                            size="large"
                                            sx={{ borderRadius: "25px" }}
                                            variant="contained"
                                            color="secondary"
                                        >
                                            VISIT
                                        </Button>
                                    </Box>
                                </Stack>
                            </Grid>
                        </Grid>
                    </BoxVisit>
                </Grid>
                <Grid item xs={12} md={6}>
                    <BoxVisit elevation={3} sx={{ background: "#00b8ff" }}>
                        <Grid container padding={"10px 35px"}>
                            <Grid item xs={12} md={6}>
                                <Stack justifyContent={"center"} height="100%">
                                    <Typography variant="h3" color="white" fontWeight={"800"}>
                                        NFant
                                    </Typography>
                                    <Typography variant="h4" color="white" fontWeight={"200"}>
                                        WEB 3.0
                                    </Typography>

                                    <Box>
                                        <Button
                                            size="large"
                                            sx={{ borderRadius: "25px" }}
                                            variant="contained"
                                            color="primary"
                                        >
                                            VISIT
                                        </Button>
                                    </Box>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Box>
                                    <ResponsiveImg
                                        src={rootImage.avatar.shakiraBlanco}
                                        alt="Aikira  "
                                        style={{ visibility: "hidden", width: "70%" }}
                                    />
                                    <img
                                        src={rootImage.camisas.women}
                                        alt="Aikira"
                                        style={{
                                            position: "absolute",
                                            top: "85%",
                                            transform: "translate(-40%, -50% )",
                                            width: "60%",
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </BoxVisit>
                </Grid>
            </Grid>

            <Grid container my="15px" spacing={2}>
                {DUMMY_DATA.map(({ text, icon, url = "#" }, idx) => (
                    <Grid
                        display={"flex"}
                        item
                        lg={3}
                        md={4}
                        xs={12}
                        key={idx}
                        justifyContent="center"
                    >
                        <DuetCard text={text} icon={icon} url={url} />
                    </Grid>
                ))}
            </Grid>

            <BannerLive mt="40px" sx={{ background: `url(${rootImage.banner.shakiraSpecial})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <Grid container height={'100%'}>
                    <Grid item xs={12} md={7} display='flex'  minHeight={'600px'} flexDirection='column' justifyContent={'center'} alignItems='center'>
                        <Stack spacing={3}>
                            <Typography  
                                variant="h4"
                                lineHeight={1}
                                fontSize={{ md: "60px" }}
                                fontWeight={"800"}>
                                Aikira-NFansT <br/> Special Tour
                            </Typography>

                            <Box>
                                <ButtonWhite
                                    variant="contained"
                                    sx={{ my: "25px" }}
                                    disabled={minutes === 0 && seconds === 0 ? false : true}
                                >
                                    {/* Access to the Event */}
                                    <Typography
                                        className="button-active"
                                        display={minutes === 0 && seconds === 0 ? "block" : "none"}
                                    >
                                        <span id={"letter-1"}>A</span>
                                        <span id={"letter-2"}>c</span>
                                        <span id={"letter-3"}>c</span>
                                        <span id={"letter-4"}>e</span>
                                        <span id={"letter-5"}>s</span>
                                        <span id={"letter-6"}>s </span>
                                        <span id={"letter-7"}>t</span>
                                        <span id={"letter-8"}>o </span>
                                        <span id={"letter-9"}>t</span>
                                        <span id={"letter-10"}>h</span>
                                        <span id={"letter-11"}>e </span>
                                        <span id={"letter-12"}>E</span>
                                        <span id={"letter-13"}>v</span>
                                        <span id={"letter-14"}>e</span>
                                        <span id={"letter-15"}>n</span>
                                        <span id={"letter-16"}>t</span>
                                    </Typography>

                                    {minutes === 0 && seconds === 0
                                        ? ""
                                        : `Stream Start in ${leading0(minutes)}:${leading0(
                                            seconds
                                        )} min`}
                                </ButtonWhite>
                            </Box>

                            <Box>
                                <Typography variant="body1" lineHeight={1.3}>
                                    Exclusively for members of the NfansT community, those of Aikira and those who are members of the Dueto community, the collaboration will be broadcast live on July 30, 2023.
                                </Typography>
                                <Typography variant="body1" lineHeight={1.3} mt='8px'>
                                    Then they will have access to all the duet's merch and exclusive videos.
                                </Typography>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </BannerLive>
           

            <Grid container mt={"15px"} spacing={2}>
                <Grid item xs={12} md={6}>
                    <SubastaCard
                        imgCamisa={rootImage.camisas.shakira}
                        timeLeft={"March, 29, 2023"}
                        text={"AUTOGRAPHED T-SHIRT"}
                        subText={"T-shirt used by Aikira in the Autographed Duet and the exclusive CD of the event"}
                        id={"001"}
                        price={"4000"}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <SubastaCard
                        imgCamisa={rootImage.tikects.shakiraVip}
                        text={"Live the NFansT-Aikira 2023 experience"}
                        subText={"Go behind the scenes of the collaboration, share a day with your idols, share stories with them and see them in action in the front row."}
                        timeLeft={"March, 30, 2023"}
                        id={"002"}
                        price={"5000"}
                        disabled={true}
                    />
                </Grid>
            </Grid> 

            <Box mt="15px" display="flex" sx={{ borderRadius: '25px', overflow: 'hidden', backgroundColor: '#000' }}>
               <img src={ rootImage.banner.bannerAkira } style={{ maxHeight: '180px', margin: 'auto'}} />
            </Box>

            <Box sx={banner} maxHeight='200px' display='flex' alignItems={'flex-end'}>
                <img src={rootImage.banner.duete} alt="banner" style={{ height: '100%',cursor: 'pointer', position: 'relative', zIndex: '99', margin: '0 auto' }} onClick={() => navigate('/dashboard/shop/1') } />
            </Box>
        </BasicCardContainer>
    )
}

const banner:SxProps = { 
    background: '#1ab5f2', 
    position: 'relative',
    borderRadius: '25px',
    mt: '8px',
    overflow: 'hidden'
}

const BasicCardContainer = styled(Box)(({ theme }) => ({
    backgroundColor: "#fff",
    borderRadius: "25px",
    [theme.breakpoints.up("xs")]: {
        padding: theme.spacing(2),
    },
    [theme.breakpoints.up("lg")]: {
        padding: theme.spacing(4, 5),
    },
}))

const ResponsiveImg = styled("img")(() => ({
    maxWidth: "100%",
    maxHeight: "326px",
    margin: "0 auto",
}))

const BreakLine = styled(Box)(({ theme }) => ({
    width: "80px",
    height: "15px",
    borderRadius: "15px",
    backgroundColor: theme.palette.secondary.main,
    margin: theme.spacing(1, 0),
}))

const BoxVisit = styled(Paper)(() => ({
    borderRadius: "25px",
    position: "relative",
    overflow: "hidden",
    maxHeight: "400px",
}))

const DUMMY_DATA = [
    {
        text: "Store",
        icon: rootImage.icons["life-shoping-icon"],
    },
    {
        text: "Special Product",
        icon: rootImage.icons.special,
    },
    {
        text: "News",
        icon: rootImage.icons.dasboard.news,
    },
    {
        text: "Marketpalce",
        icon: rootImage.icons.dasboard.onlineStore,
        url: "",
    },
]
