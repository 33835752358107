/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react"
import { BrowserRouter as Router } from "react-router-dom"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { ThemeProvider } from "@emotion/react"
import { CssBaseline } from "@mui/material"
import { ToastContainer } from "react-toastify"
import { Web3ReactProvider } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'




// My Imports --------------------------------
import "./index.css"
import "react-toastify/dist/ReactToastify.css"

import App from "./App"
import { store } from "./store"
import { lightTheme } from "./theme"

const container = document.getElementById("root")!
const root = createRoot(container)

function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(provider)
  library.pollingInterval = 12000
  return library
}

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Router>
          <ThemeProvider theme={lightTheme}>
            <CssBaseline />
            <App />
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
          </ThemeProvider>
        </Router>
      </Web3ReactProvider>
    </Provider>
  </React.StrictMode>
)
