/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, styled, Typography } from "@mui/material"
import { useEffect, useState } from "react"

export const Clock = ({ deadline }: Props) => {
  const [days, setDays] = useState(0)
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)

  const leading0 = (num: number) => {
    return num < 10 ? "0" + num : num
  }

  const getTimeUntil = (deadline: string) => {
    const time = Date.parse(deadline) - Date.parse(new Date().toString())
    if (time < 0) {
      setDays(0)
      setHours(0)
      setMinutes(0)
      setSeconds(0)
    } else {
      setDays(Math.floor(time / (1000 * 60 * 60 * 24)))
      setHours(Math.floor((time / (1000 * 60 * 60)) % 24))
      setMinutes(Math.floor((time / 1000 / 60) % 60))
      setSeconds(Math.floor((time / 1000) % 60))
    }
  }

  useEffect(() => {
    setInterval(() => getTimeUntil(deadline), 1000)

    return () => getTimeUntil(deadline)
  }, [deadline])

  return (
    <Box>
      <Typography
        textAlign="center"
        variant="h4"
        fontWeight={"800"}
        textTransform="uppercase"
        my='10px'
        fontSize={{ xs: '20px',  md: "20px", xl: "25px" }}
      >
        Starts in
      </Typography>
      <BoxClock>
        <Box className="Clock-days">
          <Text variant="h3">
            {leading0(days)}
          </Text>
          <DayHourText variant="h5">
            Days
          </DayHourText>
        </Box>
        <Box className="Clock-hours">
          <Text variant="h3">
            {leading0(hours)}
          </Text>
          <DayHourText variant="h5">
            Hrs
          </DayHourText>
        </Box>
        <Box className="Clock-minutes">
          <Text variant="h3">
            {leading0(minutes)}
          </Text>
          <DayHourText variant="h5">
            Min
          </DayHourText>
        </Box>
      </BoxClock>
    </Box>
  )
}

interface Props {
  deadline: string
}

const BoxClock = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "15px",
  justifyContent: "space-around",
}))

const Text = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  textTransform: 'uppercase',
  fontWeight: '800',
  [theme.breakpoints.up("sm")]: {
    fontSize: "35px",
  },   
  [theme.breakpoints.down("sm")]: {
    fontSize: "55px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "85px",
  }
}))

const DayHourText = styled(Typography)(({theme}) => ({
  textAlign: 'center',
  justifyContent: 'center',
  fontWeight: '800',
  [theme.breakpoints.up("sm")]: {
    fontSize: "12px",
  },   
  [theme.breakpoints.down("sm")]: {

  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "30px",
  }
}))