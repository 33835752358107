import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, colors, SxProps } from '@mui/material'
import { BtnPrimary } from '../../../Custom'

export const TermsCondition = ({ open, closeModal, onScroll, ref, isFieldRead, setOpen, acept }: Prop) => {
    return (
        <Dialog open={open} onClose={closeModal} scroll={'paper'}>
            <DialogTitle>TERMS & CONDITIONS</DialogTitle>
            <DialogContent
                sx={{ padding: "0 25px" }}
                dividers={true}
                onScroll={onScroll}
            >
                <DialogContentText ref={ref} id="scroll-div" py='20px'>
                    <span style={{ marginLeft: '15px' }}></span>NFansT   is a collection of digital artworks (NFTs) running on the Ethereum network. This website is only an interface allowing participants to exchange digital collectibles. Users are entirely responsible for the safety and management of their own private Ethereum wallets and validating all transactions and contracts generated by this website before approval. Furthermore, as the NFansT  smart contract runs on the Ethereum network, there is no ability to undo, reverse, or restore any transactions.<br /><br />

                    This website and its connected services are provided “as is” and “as available” without warranty of any kind. By using this website you are accepting sole responsibility for any and all transactions involving NFansT digital collectibles.<br /><br />

                    OWNERSHIP <br /><br />
                    i. You Own the NFT. Each NFansT is an NFT on the Ethereum blockchain. When you purchase an NFT, you own the underlying NFansT, the Art, completely. Ownership of the NFT is mediated entirely by the Smart Contract and the Ethereum Network: at no point may we seize, freeze, or otherwise modify the ownership of any NFansT. <br /><br />

                    ii. Personal Use. Subject to your continued compliance with these Terms, Fanaticoins LLC grants you a worldwide, royalty-free license to use, copy, and display the purchased Art, along with any extensions that you choose to create or use, solely forthe following purposes: (i) for your own personal, non-commercial use; (ii) as part of a marketplace that permits the purchase and sale of your NFansT / NFT, provided that the marketplace cryptographically verifies each NFansT owner’s rights to display the Art for their NFansT to ensure that only the actual owner can display the Art; or (iii) as part of a third party website or application that permits the inclusion, involvement, or participation of your NFansT, provided that the website/application cryptographically verifies each NFansT owner’s rights to display the Art for their NFansT to ensure that only the actual owner can display the Art, and provided that the Art is no longer visible once the owner ofthe NFansT leaves the website/application.<br /><br />

                    iii. Commercial Use. Subject to your continued compliance with these Terms, Fanaticoins LLC grants you an unlimited, worldwide license to use, copy, and display the purchased Art for the purpose of creating derivative works based upon the Art (“Commercial Use”). Examples of such Commercial Use would e.g. be the use of the Art to produce and sell merchandise products (T-Shirts etc.) displaying copies of the Art. For the sake of clarity, nothing in this Section will be deemed to restrict you from (i) owning or operating a marketplace that permits the use and sale of NFansTs generally, provided that the marketplace cryptographically verifies each NFansT owner’s rights to display the Art for their NFansT to ensure that only the actual owner can display the Art; (ii) owning or operating a third party website or application that permits the inclusion, involvement, or participation of NFansTs generally, provided that the third party website or application cryptographically verifies each NFansT owner’s rights to display the Art for their NFansT to ensure that only the actual owner can display the Art, and provided that the Art is no longer visible once the owner of the Purchased NFansT leaves the website/application; or (iii) earning revenue from any of the foregoing.

                </DialogContentText>
            </DialogContent>

            <DialogActions sx={{ padding: "15px 20px" }}>
                <BtnPrimary sx={styles} size="small" onClick={() => setOpen(false)}>
                    Cancel
                </BtnPrimary>
                <BtnPrimary
                    sx={{ borderRadius: "5px" }}
                    size="small"
                    onClick={acept ? acept : closeModal}
                    disabled={!isFieldRead}
                >
                    Acept
                </BtnPrimary>
            </DialogActions>
        </Dialog>
    )
}

interface Prop {
    closeModal(): void
    open: boolean
    setOpen(terms: boolean): void
    onScroll(e: any): void
    isFieldRead: boolean
    ref: any
    acept?( term?: any ):void
}

const styles: SxProps = {
    borderRadius: "5px",
    backgroundColor: colors.red[600],
    "&:hover": {
      backgroundColor: colors.red[900],
    },
  }
  