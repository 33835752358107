import { Box, Card, CardActionArea, Stack, Typography } from '@mui/material'
import '../../../../assets/sass/common/_dashboard-stats.scss'

function DashboardCard({
  title,
  number,
  icon,
  percentage,
  time,
  date,
  children,
  iconColor,
}: Props) {
  return (
    <Card>
      <CardActionArea sx={{ cursor: 'default' }}>
        <Stack
          direction={{ sm: 'row' }}
          justifyContent={{ sm: 'space-between' }}
          alignItems={'center'}
          gap={2}
          maxHeight={'25rem'}
          p={{ xs: 1, sm: 3 }}
        >
          <Stack spacing={2}>
            <Typography fontWeight={'bold'} fontSize={'2.5em'}>
              {title}
            </Typography>
            <Typography fontSize={'3em'}>{number}</Typography>
            <Stack
              direction={'row'}
              spacing={1}
              alignItems={'center'}
              minWidth={'10vw'}
              minHeight={'30px'}
            >
              <Box>{icon}</Box>
              <Typography color={iconColor} fontSize={{ xs: '1.5em', md: '1.1em', xl: '1.25em' }}>
                {percentage}
              </Typography>
              <Typography color={'gray'} fontSize={{ xs: '1.5em', md: '1.1em', xl: '1.25em' }}>
                {time}
              </Typography>
            </Stack>
          </Stack>
          <Stack>
            <Typography color={'lightgray'}>{date}</Typography>
            <Box>{children}</Box>
          </Stack>
        </Stack>
      </CardActionArea>
    </Card>
  )
}
export default DashboardCard

interface Props {
  title: string
  number: number
  icon: JSX.Element | JSX.Element[]
  percentage: string
  time: string
  date: string
  iconColor: string
  children: JSX.Element | JSX.Element[]
}
