import { FormEvent, useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"
import {
  Box,
  styled,
  BoxProps,
  Grid,
  Stack,
  Typography,
  IconButton,
  ButtonGroup,
  Divider,
} from "@mui/material"
import {
  FacebookTwoTone,
  Instagram,
  WhatsApp,
  Twitter,
} from "@mui/icons-material"

// My imports ------------------------------------
import { rootImage } from "../../core/rootImages"
import { ImageLogo } from "../../Custom"
import { ContactInput } from "../Forms"
import { TermsCondition } from "../UI"


const BoxFooter = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
}))

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  width: "29px",
  height: "29px",
  color: "#fff",
  backgroundColor: "#00b8ff",
  margin: "0 15px",
  "&:hover": {
    backgroundColor: theme.palette.secondary.dark,
  },
}))

export function AppFooter() {
  const [open, setOpen] = useState(false)
  const [isFieldRead, setIsFieldRead] = useState(false)

  const descriptionElementRef: any = useRef<HTMLElement>(null)

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  const onScroll = (e: any) => {
    const parrafo = document.querySelector("#scroll-div")

    const positionBottomContainer = Number(e.target.getBoundingClientRect().bottom)
    const positionBottomText = Number(parrafo?.getBoundingClientRect().bottom)


    if (Math.abs(positionBottomText - positionBottomContainer) < 2) {
      setIsFieldRead(true)
    } else {
      setIsFieldRead(false)
    }

  }

  const closeModal = () => {
    setIsFieldRead(false)
    setOpen(false)
  }

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    toast.success('Thank you for subscribing to our whitelist, your position is 64. You will be duly notified before launching the pre-sale of the NFTs', {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: false,
      className: 'app-nfriend__tostify-success-footer'
    })
  }

  return (
    <>
      <BoxFooter
        width={"100%"}
        pt={"45px"}
        pb={"45px"}
        sx={{
          backgroundColor: "primary.dark",
        }}
      >
        <Box
          maxWidth={"1400px"}
          sx={{
            width: {
              xs: "90%",
              md: "85%",
              xl: "85%",
            },
            margin: "10px auto 0",
          }}
        >
          <Grid container mb={"15px"} spacing={3}>
            <Grid item sm={12} md={6}>
              <Stack spacing={3} justifyContent="space-between" height="100%">
                <ImageLogo src={rootImage.logo.logo2} />
                <Typography
                  variant="body2"
                  color={"white"}
                  width={{ xs: "100%", md: "80%" }}
                >
                  The highlights of NFansT are the access to a web3.0 with
                  "services, content and benefits" that support the NFT that your
                  are buying, they are not just "images, videos or audios" that
                  you can download or find for free on the net with nothing
                  behind.
                </Typography>

                <ButtonGroup>
                  <IconButtonStyled sx={{ bgcolor: "transparent" }}>
                    <FacebookTwoTone fontSize="large" sx={{ color: "#00b8ff" }} />
                  </IconButtonStyled>
                  <IconButtonStyled>
                    <Instagram fontSize="small" color="primary" />
                  </IconButtonStyled>
                  <IconButtonStyled>
                    <WhatsApp fontSize="small" color="primary" />
                  </IconButtonStyled>
                  <IconButtonStyled>
                    <Twitter fontSize="small" color="primary" />
                  </IconButtonStyled>
                </ButtonGroup>
              </Stack>
            </Grid>
            <Grid item sm={12} md={6}>
              <header>
                <Typography
                  variant="subtitle2"
                  color={"secondary.main"}
                  fontWeight="600"
                >
                  Start your journey here
                </Typography>
              </header>
              <Typography variant="body2" color="white" mb={"15px"}>
                Register below to join our whitelisting campaing and get firsthand
                access to NFansT exclusive NFTs
              </Typography>

              <form onSubmit={onSubmit}>
                <Stack spacing={2}>
                  <Box>
                    <ContactInput
                      placeholder={"E-mail"}
                      type={"email"}
                      value={undefined}
                      label={"E-mail Address"}
                      required={true}
                    />
                  </Box>
                  <Box>
                    <ContactInput
                      placeholder={"Wallet Address (Ethreum Network)"}
                      type={"text"}
                      value={undefined}
                      label={"Wallet Address"}
                      required={true}
                    />
                  </Box>
                </Stack>

                <input type={'submit'} value="Submit" className="button-footer" />
              </form>
            </Grid>
          </Grid>

          <Divider color="#fff" />

          <Typography variant="body1" color="white" mt={2} onClick={() => setOpen(true)} sx={{ cursor: 'pointer' }}>
            Terms and Conditions
          </Typography>
        </Box>
      </BoxFooter>
      <TermsCondition
        closeModal={closeModal}
        open={open}
        setOpen={setOpen}
        onScroll={onScroll}
        isFieldRead={isFieldRead}
        ref={descriptionElementRef} />
    </>
  )
}
