/* eslint-disable no-constant-condition */
import { List, ListItem, ListItemAvatar, Avatar, ListItemText, Typography } from "@mui/material"
import React from "react"
import { useDispatch } from "react-redux"
import { rootImage } from "../../../app/core/rootImages"
import { NotificationArray } from "../../../app/interfaces"
import { notificationActions } from "../../../store/slice/notification.slice"

export const ListNotifications = ({ listNotification }: Props ) => {
  const dispatch = useDispatch()

  const handleClick = (notification: any) => {
    dispatch(notificationActions.readMessage(notification))
    dispatch(notificationActions.openMessage(true))
    dispatch(notificationActions.message(notification))
  }

  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>

      {
        listNotification.map(({title, date, isRead, user, body, isArchivate, id}, idx) => (
          <ListItem onClick={() => handleClick({id, title, date, isRead: true, user, body, isArchivate})} alignItems="flex-start" key={idx} sx={{ background: isRead ? '#f4f7fd' : '#fff', cursor: 'pointer' }}>
            <ListItemAvatar>
              <Avatar alt={user} src={rootImage.avatar.default} />
            </ListItemAvatar>
            <ListItemText
              primary={title}
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: 'inline', fontWeight: isRead ? '800' : '500' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    System {"- "}
                  </Typography>
                  {body.substring(0, 35) + '...' }
                </React.Fragment>
              }
            />
          </ListItem>
        ))
      }

    </List>
  )
}

interface Props {
  listNotification: NotificationArray[]
}