import { Box, Button, CircularProgress, Grid, styled, Typography } from '@mui/material'

import { lighten, SxProps } from '@mui/system'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { BasicCardContainer } from '../../../app/Custom'
import { useRequest } from '../../../app/hooks'

export const SectionNFT = ({ imgRote, nftWallet, url, buttonText: text}: Props) => {
  const { isLoading, sendRequest } = useRequest()
  const [buttonText, setButtonText] = useState(text ? text : 'MINT')

  const { background,  title, badge, rarity } = nftWallet

   const mintNFT = () => {
    if( buttonText !== 'MINT' ) return

    const promise = new Promise<any>((resolve) => {
      setTimeout(() => {
        return resolve(true)
      }, 3000)
    })  

    sendRequest(promise).then(() => {
      setButtonText('0x2354f...26')
      toast.success('success')
    })
  }

  return (
    <BasicCardContainer sx={basiCardStyle} >
        <Box flex={'1'} display="flex" flexDirection={"column"}>
            <Box display="flex" alignItems={'center'} justifyContent="center" flex="1 0">
                <img src={imgRote} style={{ maxWidth: '100%', maxHeight: '350px'}}/>
            </Box>

            <Box display="flex" justifyContent={'space-between'} mt="10px">
                <Typography variant='body1' sx={fontFooter} >{title}</Typography>
            </Box>
        </Box>

        <Grid container spacing={2} my="20px">
            <Grid item xs={4}>
                <BoxDescription>
                    <Typography sx={Typography_1}> Rarity</Typography>
                    <Typography sx={Typography_2}>{rarity}</Typography>
                </BoxDescription>
            </Grid>
            <Grid item xs={4}>
                <BoxDescription>
                    <Typography sx={Typography_1} display={{ md: 'block', xs: 'none'}}>Background</Typography>
                    <Typography sx={Typography_1} display={{ md: 'none', xs: 'block'}}>Bg</Typography>
                    <Typography sx={Typography_2}>{background}</Typography>
                </BoxDescription>
            </Grid>
            <Grid item xs={4}>
                <BoxDescription>
                    <Typography sx={Typography_1}>Badge</Typography>
                    <Typography sx={Typography_2}>{badge}</Typography>
                </BoxDescription>
            </Grid>
        </Grid>

        <Button 
            disabled={isLoading} 
            fullWidth 
            variant='contained' 
            onClick={mintNFT} 
            color="secondary" 
            sx={{ borderRadius: '25px'}} 
            size="large" 
            href={url || '#'}
            >
            {isLoading ?  <CircularProgress color="secondary" /> : buttonText}
        </Button>
    </BasicCardContainer>
  )
}

interface Props {
    imgRote: string 
    nftWallet: {
        id: string,
        rarity: string,
        background: string,
        badge: string,
        title: string
    }
    buttonText?: string
    url?: string
    buttonOnclick?(): void
    isLoading?: boolean
}

const basiCardStyle: SxProps = {
    background: '#fff',
    height: '100%',
    justifyContent: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
    padding: '40px 25px'
} 

const fontFooter: SxProps= {
    fontWeight: '800',
    fontSize: '25px'
}
const Typography_1: SxProps = {
    fontWeight: '800',
    color: 'secondary.main',
    textAlign: 'center'
}
const Typography_2: SxProps = {
    fontWeight: '400',
    textAlign: 'center',
    fontSize: {
        md: '16px',
        xs: '12px'
    }
}

const BoxDescription = styled(Box)(({ theme }) => ({
    backgroundColor: lighten( theme.palette.secondary.light, 0.8 ),
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '8px',
    textAlign: 'center',
    height: '100%',
    padding: '12px 8px'
}))