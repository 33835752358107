import { Box } from '@mui/material'
import Typography from '@mui/material/Typography'
import React from 'react'

export const Clock = ({seconds, min, hrs, day}: Props) => {
  return (
    <Box>
          <Typography variant='h4' letterSpacing={4} fontSize={{ xs: '20px', lg: '20px', xl: '30px' }} mb="12px"> 
               <span className='clock-subasta day'>{day}:</span>
               <span className='clock-subasta hrs'>{hrs}:</span>
               <span className='clock-subasta min'>{min}:</span>
               <span className='clock-subasta sec'>{seconds}</span>
          </Typography>
    </Box>
  )
}

interface Props {
    seconds: string | number
    min: string | number
    hrs: string | number
    day: string | number
}