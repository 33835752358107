/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AccordionSummary,
  Box,
  AccordionDetails,
  styled,
  Accordion,
  AccordionProps,
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

// My Imports===============================
import { CircularBox } from "../../Custom"

export const AccordionComponent = (props: Props) => {
  const {
    Icon = ExpandMoreIcon,
    gap = "10px",
    TitleComponent,
    onChange,
  } = props

  return (
    <AccordionCustom
      expanded={props.expanded === `${props.id}-header`}
      onChange={() => onChange(`${props.id}-header`)}
    >
      <AccordionSummary
        expandIcon={<Icon />}
        aria-controls={`${props.id}-content`}
        id={`${props.id}-header`}
      >
        <Box display={"flex"} alignItems={"center"} gap={gap}>
          <CircularBox />
          {TitleComponent}
        </Box>
      </AccordionSummary>

      <AccordionDetails sx={{ ml: '50px' }}>{props.children}</AccordionDetails>
    </AccordionCustom>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AccordionCustom = styled(Accordion)<AccordionProps>(({ theme }) => ({
  border: "5px solid #0d0056",
  borderRadius: "50px !important",
  padding: "5px",
  ":before": {
    display: "none",
  },
}))

interface Props {
  id: string
  TitleComponent: JSX.Element | JSX.Element[]
  children: JSX.Element | JSX.Element[]
  onChange(id: string): void
  expanded: string
  gap?: string
  Icon?: any
}
