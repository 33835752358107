import { useState } from "react"
import {  Route, Routes } from "react-router-dom"
import { Drawer } from "@mui/material"

// My imports --------------------------------------------------------------------------
import { DashboardContainer, BoxContainerDashboard, BoxScroll } from "../../app/Custom"
import { DrawerItems } from "./DrawerItems"
import { Duete } from "./home/Duete"
import { NavDashboard, SideBarComponent } from "../../app/components/UI"
import { rootImage } from "../../app/core/rootImages"
import { rootRouters } from "../../app/core/rootRouter"


export const DuetePage = () => {
  const [open, setOpen] = useState(false)

  return (
    <DashboardContainer>
      <SideBarComponent image={rootImage.logo.logo1}>
        <DrawerItems />
      </SideBarComponent>
      <Drawer anchor={'left'} open={open} onClose={() => setOpen(false)}>
        <DrawerItems />
      </Drawer>

      <BoxContainerDashboard>
        <NavDashboard setOpen={setOpen} />

        <BoxScroll>
          <Routes>
            <Route element={<Duete />}  path={rootRouters.duet.home}/>
          </Routes>
        </BoxScroll>
      </BoxContainerDashboard>
    </DashboardContainer>
  )
}
