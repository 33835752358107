import {  Divider, List, SxProps } from '@mui/material'

import { AvatarOlineUser, CustomListItem } from '../../app/components/UI'
import { rootImage } from '../../app/core/rootImages'
import { rootRouters } from '../../app/core/rootRouter'

export const DrawerItems = () => {
  return (
    <>
      <AvatarOlineUser size={100} />

      <Divider sx={{ my: '10px' }} />

      <List sx={stlyed}>
        <CustomListItem
          text="Dashboard"
          url={`/admin${rootRouters.admin.home}`}
          icon={<img src={rootImage.icons.dasboard.home} />}
        />
        <CustomListItem
          text="Sections"
          url={`/admin${rootRouters.admin.permissions}`}
          icon={<img src={rootImage.icons.dasboard.wallet} width="19px" />}
        />
        <CustomListItem
          text="Wallet"
          url={`/admin${rootRouters.admin.wallet}`}
          icon={<img src={rootImage.icons.dasboard.wallet} />}
        />
        <CustomListItem text="Whitelist" icon={<img src={rootImage.icons.dasboard.nftIcon} />} />
        <CustomListItem
          text="Shop"
          url={`/admin${rootRouters.admin.shop}`}
          icon={<img src={rootImage.icons.dasboard.shop} />}
        />
        <CustomListItem
          text="Live Shopping"
          url={`/admin${rootRouters.admin.liveshopping}`}
          icon={<img src={rootImage.icons.dasboard.history} />}
        />
        <CustomListItem
          text="News"
          url={`/admin${rootRouters.admin.news}`}
          icon={<img src={rootImage.icons.dasboard.newsIcon} />}
        />
        <CustomListItem
          text="Surveys"
          url={`/admin${rootRouters.admin.surveys}`}
          icon={<img src={rootImage.icons.dasboard.surveys} />}
        />
        <CustomListItem
          text="Duets"
          url={`/admin${rootRouters.admin.duets}`}
          icon={<img src={rootImage.icons.dasboard.history} />}
        />
        <CustomListItem
          text="Users"
          url={`/admin${rootRouters.admin.users}`}
          icon={<img src={rootImage.icons.dasboard.settings} />}
        />
        <CustomListItem
          text="Messages"
          url={`/admin${rootRouters.admin.messages}`}
          icon={<img src={rootImage.icons.dasboard.message} />}
        />
        <CustomListItem
          text="Marketplace"
          url={`/admin${rootRouters.admin.marketplace}`}
          icon={<img src={rootImage.icons.dasboard.nftIcon} />}
        />
        <CustomListItem
          text="Special Events"
          url={`/admin${rootRouters.admin.specials}`}
          icon={<img src={rootImage.icons.dasboard.lifeShoping} />}
        />
        <CustomListItem
          text="Raffles"
          url={`/admin${rootRouters.admin.raffles}`}
          icon={<img src={rootImage.icons.dasboard.tickets} />}
        />
      </List>
      <Divider />

      <List>
        <CustomListItem text="log out" icon={<img src={rootImage.icons.dasboard.logout} />} />
      </List>
    </>
  )
}

const stlyed: SxProps = {
  flex: '1 1',
  overflowY: 'scroll',
  '::-webkit-scrollbar': {
    width: '5px',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: 'gray',
    borderRadius: '4px',
  },
}
