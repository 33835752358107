import { createSlice} from '@reduxjs/toolkit'

const initialState = {
   counter: 0
}

const shopSlice = createSlice({
   name: 'shop',
   initialState,
   reducers: {
    addOne (state) {
       state.counter += 1
    },
    reducerOne (state) {
        if( state.counter === 1 ) return
       state.counter -= 1
    }
   }
 })

export const shopActions = shopSlice.actions
export const shopReducer = shopSlice.reducer