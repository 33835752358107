import { Event_01 } from "./event-01/Event-01"
import { Event_02 } from "./event-02/Event-02"

export const SpecialEvent = () => {
  
  return (
    <>
      <Event_01 />
      <Event_02 />
    </>
  )
}
