import { ShoppingCart } from "@mui/icons-material"
import { Stack, Button } from "@mui/material"
import { useAppSelector } from "../../../../app/hooks"

export const CarritoButtons = ({ addToCart, itemSelect }: Props) => {
  const { counter } = useAppSelector( state => state.shop )

  return (
    <Stack direction={"row"} mb="15px" justifyContent={"flex-end"} spacing={2}>
      <Button
        sx={{ borderRadius: "15px" }}
        color="secondary"
        variant="contained"
        onClick={addToCart}
        disabled={itemSelect}
      >
        Add to Cart
      </Button>
      <Button
        sx={{ borderRadius: "15px" }}
        color="secondary"
        variant="contained"
        startIcon={<ShoppingCart />}
      >
        My Cart <span style={{ marginLeft: "5px" }}>({counter})</span>
      </Button>
    </Stack>
  )
}

interface Props {
  addToCart(): void
  itemSelect: boolean
}
