import { Box, Button, Paper, SxProps, Typography } from "@mui/material"
import { Link } from "react-router-dom"

export const ShopItem = (props: Props) => {
  const { isNew, descuento } = props

  return (
    <Paper sx={PaperCustom} className={`custom-paper-shop ${isNew && 'is-new'}`}>
      <Link to={`/dashboard/shop/${props.id}`} style={{ color: '#000', textDecoration: 'none' }}>
        <Box p={'15px 10px'}>
          <img src={props.url} width="100%" />
        </Box>

        <Box p="10px" className="hover-effect">
          <div className="button-dinamyc-shop-page">
            <div>
              <Typography textAlign={"center"} variant="subtitle1">
                {props.text}
              </Typography>
              <Typography
                textAlign={"center"}
                variant="subtitle1"
                fontWeight={"800"}
              >
                {
                  descuento ?<span> <del style={{ fontWeight: '500' }}>${Number(props.price).toFixed(2)}</del> <span>${(Number(props.price) / 2).toFixed(2)}</span> </span>:`$${Number(props.price).toFixed(2)}`
                }
              </Typography>
            </div>
            <div>
              <Button variant="contained" color="secondary" size="small">
                Add to card
              </Button>
            </div>
          </div>

          <Box sx={breakLine} />
        </Box>
      </Link>
    </Paper>
  )
}

interface Props {
  text: string
  price: string | number
  url: string
  id: string | number
  isNew: boolean
  descuento: boolean
}

const breakLine: SxProps = {
  height: "1px",
  backgroundColor: "primary.main",
  width: "100%",
  marginTop: "10px",
}

const PaperCustom: SxProps = {
  cursor: "pointer",
}
