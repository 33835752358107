import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { CircularProgress } from '@mui/material'

export default function DialogAlert({ open }: Props ) {
    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                verifying that you have access
            </DialogTitle>
            <DialogContent sx={{ display: 'flex' }}>
                <CircularProgress color='secondary' sx={{ margin: '0 auto'}}/>
            </DialogContent>
            <DialogActions>

            </DialogActions>
        </Dialog>
    )
}

interface Props {
    open: boolean
}