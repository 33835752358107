import { Box, Grid, styled, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import { rootImage } from "../../../../app/core/rootImages"
import { rootRouters } from "../../../../app/core/rootRouter"
import { BoxBgImage, BtnPrimary } from "../../../../app/Custom"

export const ShopCamisa = () => {
  const CartImg = BoxBgImage(rootImage.banner.tShirt)

  return (
    <CartImg>
      <Grid container spacing={{ xs: 2, md: 6 }}>
        <Grid item xs={12} sm={6} md={6} alignItems="center" display="flex">
          <ImageResponsive
            src={rootImage.camisas.women}
          />
        </Grid>
        <Grid
          item
          xs={12} sm={6}
          md={6}
          display="flex"
          flexDirection="column"
          justifyContent={"center"}
          alignItems="center"
        >
          <Box>
            <Typography
              fontSize={{ xs: "35px", sm: "55px" }}
              variant="h3"
              color={"secondary.main"}
              fontWeight="600"
            >
              Store
            </Typography>

            <Typography
              fontSize={{ xs: "16px", sm: "18px" }}
              variant="body1"
              color={"#fff"}
              py="10px"
              pb="20px"
            >
              Enjoy official products, products from our sponsors, discounts and exclusive pre-sales only for members of the community.
            </Typography>

            <Link to={`/dashboard${rootRouters.dashboard.shop.index}`} style={{ color: "#fff", textDecoration: 'none' }}>
              <BtnPrimary size={'small'} sx={{ width: "100px", textAlign: 'left' }}>
                Buy
              </BtnPrimary>
            </Link>

          </Box>
        </Grid>
      </Grid>
    </CartImg>
  )
}

const ImageResponsive = styled("img")(({ theme }) => ({
  margin: 'auto',
  [theme.breakpoints.up("lg")]: {
    maxWidth: "90%",
    maxHeight: '350px'
  },
  [theme.breakpoints.down("lg")]: {
    maxWidth: "100%",
  },
}))
