import { Box, Grid, styled, Typography } from "@mui/material"
import { rootImage } from "../../../app/core/rootImages"
import { BreakLine } from "../../../app/Custom"

export const DuetoDetail = () => {
  return (
    <Grid container spacing={4}>
      <Grid item sx={{ position: 'relative' }} xs={0} md={4}>
        <BoxLogos   sx={{
            backgroundColor: 'custom.blueBarca',
            top: '65%',
            left: '75%',
            transform: 'translate(-85%, -50%)',
            zIndex: '10',
        }}>
            <img width={'100%'} src={rootImage.logo.football.barca} />
        </BoxLogos>
        <BoxLogos sx={{
            backgroundColor: 'business.background',
            top: '50%',
            left: '40%',
            padding: '20px',
            transform: 'translate(-50%, -50%)',
            zIndex: '5',
        }}>
            <img width={'100%'} src={rootImage.logo.football.Liga} />
        </BoxLogos>
      </Grid>

      <Grid item md={8}>
        <Box>
            <Typography variant="h3" fontWeight={'800'} color="primary.main">Dueto Detail</Typography>
            <BreakLine sx={{ backgroundColor: 'secondary.main'}} my={'20px'} width="100px"/>

            <Typography variant="body1" fontWeight={'500'}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam eveniet deleniti veritatis, corporis consectetur amet quia dolores praesentium quisquam modi provident quos asperiores nam assumenda eum! 
            </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const BoxLogos = styled(Box)(({ theme }) => ({
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: '150px',
    padding: '20px',
    height: '150px',
    [theme.breakpoints.down("md")]: {
      display: 'none'
    },
  }))