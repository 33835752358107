import { Link } from 'react-router-dom'
import { Box,styled, Typography } from '@mui/material'

export const DuetCard = ({ icon, text, url }: Props) => {
  return (
    <Link to={url} style={{ flex: '1', textDecoration: 'none', color: '#000' }} >
        <BoxItem>
            <Box display='flex'>
                <img src={icon}  style={{margin: '0 auto', maxHeight: '80px'}}/>
            </Box>
            <Typography variant='subtitle1' textAlign={'center'} fontWeight="800">{text}</Typography>
        </BoxItem>
    </Link>
  )
}
interface Props {
    icon: string
    text: string
    url: string
}

const BoxItem = styled(Box)(({ theme }) => ({
    background: theme.palette.secondary.main,
    padding: theme.spacing(4),
    borderRadius: '25px',
    maxHeight: '150px',
    maxWidth: '100%',
    flex: '1',
    margin: 'auto',
    cursor: 'pointer',
    transition: theme.transitions.create(['background-color', 'transform'], {
        duration: theme.transitions.duration.standard,
      }),
    '&:hover': {
        transform: "scale(1.09)"
    }
}))