import { Box, Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { apiConfig } from '../../../api/Api.config'
import { FormButton } from '../../../app/Custom'
import { PermissionsCard } from '../../../app/components/UI'
import { useAppSelector, useRequest } from '../../../app/hooks'

function AdminPermissions() {
  const { useId } = useAppSelector(state => state.auth)
  const { isLoading, sendRequest } = useRequest()
  const [sectionMenu, setSectionMenu] = useState<any>({
    wallet: {
      section: 'wallet',
      status: true,
    },
    shop: {
      section: 'shop',
      status: true,
    },
    liveshop: {
      section: 'liveshop',
      status: true,
    },
    news: {
      section: 'news',
      status: true,
    },
    survey: {
      section: 'survey',
      status: true,
    },
    duets: {
      section: 'duets',
      status: true,
    },
    users: {
      section: 'users',
      status: true,
    },
    messages: {
      section: 'messages',
      status: true,
    },
    market: {
      section: 'market',
      status: true,
    },
    specials: {
      section: 'specials',
      status: true,
    },
    raffles: {
      section: 'raffles',
      status: true,
    },
  })

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const sectionsMenu = []

    for (const value of Object.values(sectionMenu)) {
      sectionsMenu.push(value)
    }

    const sendBody = { sectionsMenu: sectionsMenu }

    await sendRequest(apiConfig.post(`/updatesection/${useId}`, sendBody))
      .then((res: any) => {
        console.log(res.data)
        toast.success('The sections has been saved successfully')
      })
      .catch((err) => console.log(err.message))
  }

  const getSections = async () => {
    await sendRequest(apiConfig.get(`/getsection/${useId}`))
      .then((res: any) => {
        const sectionData = res.data?.data.sections

        sectionData.map((item: any) => {
          const { section, status } = item

          // setSectionMenu((last: any) => {
          //   const newValues = last[section]

          //   return {
          //     ...last,
          //     [section]: {
          //       ...newValues,
          //       status: status,
          //     },
          //   }
          // })
        })
      })
      .catch((err) => console.log(err.message))
  }

  useEffect(() => {
    getSections()
  }, [])

  return (
    <>
      <Grid container spacing={2}>
        {permissionsTexts.map((permissions, idx) => {
          const { text, name } = permissions

          return (
            <Grid key={idx} item xs={12} sm={6} xl={4}>
              <Box my={2}>
                <PermissionsCard
                  txt={text}
                  name={name}
                  checked={sectionMenu[name]?.status}
                  id={text}
                  setSectionMenu={setSectionMenu}
                />
              </Box>
            </Grid>
          )
        })}
      </Grid>
      <form onSubmit={(e) => onSubmit(e)}>
        <FormButton
          disabled={isLoading}
          size="large"
          type="submit"
          sx={{
            marginTop: '15px',
          }}
          loading={isLoading}
          variant="contained"
          color="secondary"
        >
          Send
        </FormButton>
      </form>
    </>
  )
}

const permissionsTexts: PermissionsType[] = [
  {
    text: 'Wallet',
    name: 'wallet',
  },
  {
    text: 'Shop',
    name: 'shop',
  },
  {
    text: 'Live Shopping',
    name: 'liveshop',
  },
  {
    text: 'News',
    name: 'news',
  },
  {
    text: 'Surveys',
    name: 'survey',
  },
  {
    text: 'Duets',
    name: 'duets',
  },
  {
    text: 'Users',
    name: 'users',
  },
  {
    text: 'Messages',
    name: 'messages',
  },
  {
    text: 'Marketplace',
    name: 'market',
  },
  {
    text: 'Special Events',
    name: 'specials',
  },
  {
    text: 'Raffles',
    name: 'raffles',
  },
]

export default AdminPermissions

interface PermissionsType {
  text: string
  name: string
}
