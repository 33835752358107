import { useState } from "react"
import { Chip, Typography, Box, Stack } from "@mui/material"

export const SizeSelector = () => {
  const [select, setSelect] = useState("")

  const onChange = (size: string) => {
    setSelect(size)
  }
  return (
    <Box>
        <Typography variant="body1" fontWeight={'800'} fontSize="18px" mb={'10px'} color='#fff'>Sizes</Typography>
        <Stack direction="row" spacing={1}>
            <Chip 
                label="S" 
                color="secondary" 
                variant={select === 'S' ? 'filled' : "outlined"} 
                size="medium"
                onClick={() => onChange('S')}
                />

            <Chip 
                label="M" 
                color="secondary" 
                variant={select === 'M' ? 'filled' : "outlined"} 
                size="medium"
                onClick={() => onChange('M')}
                />

            <Chip 
                label="L" 
                color="secondary" 
                variant={select === 'L' ? 'filled' : "outlined"}
                size="medium"
                onClick={() => onChange('L')}
                />

            <Chip 
                label="XL"
                color="secondary"
                variant={select === 'XL' ? 'filled' : "outlined"}
                size="medium"
                onClick={() => onChange('XL')}
                />
        </Stack>
    </Box>
  )
}
