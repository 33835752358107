import { Card, Stack, SxProps } from '@mui/material'
import { SwitchStyled } from '../../../Custom'

interface PermissionsCardProps {
  txt: string
  name: string
  value?: string
  checked: boolean
  sx?: SxProps
  id: string
  setSectionMenu(props: any): void
}

function PermissionsCard({ txt, name, checked, sx = {}, setSectionMenu }: PermissionsCardProps) {
  const onChange = () => {
    setSectionMenu((last: any) => {
      const newValues = last[name]

      return {
        ...last,
        [name]: {
          ...newValues,
          status: !newValues?.status,
        },
      }
    })
  }

  return (
    <Card sx={{ borderRadius: '10px', padding: '15px', maxWidth: '20rem', height: '6rem', ...sx }}>
      <Stack sx={stackStyle} width={{ sm: '15rem', md: '20rem' }}>
        <h2 style={{ margin: '0', width: '7.5rem', textAlign: 'center' }}>{txt}</h2>
        <SwitchStyled id={name} name={name} value={checked} checked={checked} onChange={onChange} />
      </Stack>
    </Card>
  )
}
export default PermissionsCard

const stackStyle = {
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10%',
  height: '4rem',
}
