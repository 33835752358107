import React, { useState, useMemo } from "react"
import { Modal, Typography, Box, styled} from "@mui/material"

import { rootImage } from "../../../app/core/rootImages"
import { useClock } from "../../../app/hooks"

const VideoModal = ({ open, setOpen }: Props) => {
  
  const DateMemo = useMemo(() => new Date(new Date().setSeconds(new Date().getSeconds() + 30)).toString(), [])
  const { seconds } = useClock(DateMemo)

  const [view, setView] = useState(false)

  if (seconds == 15 && !view) {
    setView(true)
  }

  return (<>
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        border: "none !important",
      }}
    >
      <VideoContainer>
        <Box display={"flex"} justifyContent="flex-end" mx={'10px'}>
          <Typography
            color="#fff"
            fontWeight={"800"}
            sx={{ cursor: "pointer" }}
            onClick={() => setOpen(false)}
          >
            X
          </Typography>
        </Box>
        <EmbedContainer>
          <Iframe
            src="https://www.youtube.com/embed/UQhwAUoWsdQ?autoplay=1&mute=1"
            title="YouTube video player" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          ></Iframe>
          {view && <BannerPublicitario>
            <Box display={"flex"} justifyContent="flex-end" mx={'10px'}>
                <Typography
                  color="#fff"
                  fontWeight={"800"}
                  sx={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault()
                    setView(false)
                  }}
                >
                  X
                </Typography>
            </Box>
            <img src={rootImage.banner.ads} alt="banner" style={{ width: '100%',cursor: 'pointer' }} onClick={() => window.open('/dashboard/shop/1') } />
          </BannerPublicitario>}
        </EmbedContainer>
      </VideoContainer>
    </Modal>
  </>

  )
}

export default React.memo(VideoModal)

interface Props {
  url?: string;
  open: boolean;
  setOpen(param: boolean): void;
}

const VideoContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  border: "none",
  [theme.breakpoints.down('sm')]: {
    width: '95%',
  }
}))

const EmbedContainer = styled(Box)(() => ({
  position: "relative",
  paddingBottom: "56.25%",
  height: "0",
  overFlow: "hidden",
}))

const BannerPublicitario = styled(Box)(() => ({
  position: "absolute",
  display: 'block',
  bottom: '25px',
  left: '50%',
  transform: 'translateX(-50%)',
  padding: '10px'
}))

const Iframe = styled("iframe")(() => ({
  position: "absolute",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
}))
