import { Box, BoxProps, Grid, Link, styled, SxProps, Typography } from "@mui/material"
import { rootImage } from "../../../../app/core/rootImages"

export const PerfectProducts = () => {

  return (
    <BoxCombination>
      <Link href="/dashboard-duete" target='_blank'>
        <Grid
          container
          sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          spacing={2}
        >
          <Grid item xs={6} md={6}>
            <Box sx={boxAvatar}>
              <ImageResponsive src={rootImage.avatar.default} />
            </Box>
          </Grid>
          <Grid item xs={6} md={6}>
            <Box sx={boxAvatar}>
              <ImageResponsive src={rootImage.avatar.shakira} />
            </Box>
          </Grid>
        </Grid>
      </Link>
      <BoxFloat>
        <InternalBox>
          <Typography variant="body1" fontWeight={800}>
            The Perfect
          </Typography>
        </InternalBox>
        <Typography variant="body1">Duets</Typography>
      </BoxFloat>
    </BoxCombination>
  )
}
const BoxCombination = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  position: "relative",
  height: "100%",
  borderRadius: "25px",
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  [theme.breakpoints.up("sm")]: {
    padding: "25px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "10px",
  },
}))

const boxAvatar: SxProps = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "auto",
}

const BoxFloat = styled(Box)<BoxProps>(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  color: "#fff",
  backgroundColor: theme.palette.secondary.dark,
  borderRadius: "10px",
  padding: "0 0 2px 0",
  textAlign: "center",
  minWidth: "138px",
  maxWidth: "253px",
  [theme.breakpoints.up("sm")]: {
    width: "30%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "60%",
    top: "91%",
  },
}))

const InternalBox = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  borderRadius: "10px",
  padding: "2px 15px",
}))

const ImageResponsive = styled("img")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    maxHeight: "200px",
    maxWidth: "200px",
  },
  [theme.breakpoints.up("md")]: {
    maxHeight: "150px",
    maxWidth: "150px",
  },
  [theme.breakpoints.up("sm")]: {
    maxHeight: "180px",
    maxWidth: "180px",
  },
  [theme.breakpoints.down("sm")]: {
    maxHeight: "120px",
    maxWidth: "120px",
  },
}))
