import { Drawer } from '@mui/material'
import { useState } from 'react'
import { Route, Routes } from 'react-router'

// My Imports ----------------------------------------------------------------------
import AdminUsers from './Users/AdminUsers'
import AdminSurveys from './Surveys/AdminSurveys'
import AdminShop from './Shops/AdminShop'
import AdminPermissions from './Permissions/AdminPermissions'
import AdminNews from './News/AdminNews'
import AdminMessages from './Messages/AdminMessages'
import AdminDashboard from './Dashboard/AdminDashboard'
import { SideBarComponent } from '../../app/components/UI'
import { rootRouters } from '../../app/core/rootRouter'
import { rootImage } from '../../app/core/rootImages'
import { NavDashboard } from '../../app/components/UI/dashboard/NavDashboard'
import { DrawerItems } from './DrawerItems'
import { BoxContainerDashboard, BoxScroll, DashboardContainer } from '../../app/Custom'

export const AdminPage = () => {
  const [open, setOpen] = useState(false)

  return (
    <DashboardContainer>
      <SideBarComponent image={rootImage.logo.logo1}>
        <DrawerItems />
      </SideBarComponent>
      <Drawer anchor={'left'} open={open} onClose={() => setOpen(false)}>
        <DrawerItems />
      </Drawer>

      <BoxContainerDashboard>
        <NavDashboard setOpen={setOpen} />

        <BoxScroll>
          <Routes>
            <Route path={rootRouters.admin.home} element={<AdminDashboard />} />
            <Route path={rootRouters.admin.permissions} element={<AdminPermissions />} />
            <Route path={rootRouters.admin.news} element={<AdminNews />} />
            <Route path={rootRouters.admin.shop} element={<AdminShop />} />
            <Route path={rootRouters.admin.users} element={<AdminUsers />} />
            <Route path={rootRouters.admin.messages} element={<AdminMessages />} />
            <Route path={rootRouters.admin.wallet} element={<h1>Admin Wallet</h1>} />
            <Route path={rootRouters.admin.marketplace} element={<h1>Admin Marketplace</h1>} />
            <Route path={rootRouters.admin.specials} element={<h1>Admin Specials Events</h1>} />
            <Route path={rootRouters.admin.liveshopping} element={<h1>Admin Live Shopping</h1>} />
            <Route path={rootRouters.admin.raffles} element={<h1>Admin Raffles</h1>} />
            <Route path={rootRouters.admin.surveys} element={<AdminSurveys />} />
            <Route path={rootRouters.admin.duets} element={<h1>Admin Duets</h1>} />
          </Routes>
        </BoxScroll>
      </BoxContainerDashboard>
    </DashboardContainer>
  )
}
