import { Card, CardActionArea, SxProps } from "@mui/material"

export const CardImageItem = ({ url, sx = {} }: { url: string, sx?: SxProps }) => {
  return (
    <Card sx={{ borderRadius: "25px", padding: "15px", ...sx }}>
      <CardActionArea sx={{ borderRadius: "25px", display: 'flex', justifyContent: 'center' }}>
        <img src={url} style={{ maxHeight: '400px', maxWidth: '100%' }}/>
      </CardActionArea>
    </Card>
  )
}
