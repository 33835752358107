import {
  Stack,
  Typography,
  Grid,
  GridProps,
  styled,
} from "@mui/material"

// My Imports ---------------------------------------
import { rootImage } from "../../../app/core/rootImages"
import { ResponsiveImage, SubTitle } from "../../../app/Custom"

const GridCard = styled(Grid)<GridProps>(({ theme }) => ({
  backgroundColor: "#f4f7fd",
  borderRadius: "25px",
  margin: "0",
  padding: "35px 45px",
  display: "flex",
  alignItem: "center",
  justifyContent: "center",
  [theme.breakpoints.down("md")]: {
    padding: "55px 22px",
    width: "100%",
  },
}))

function NFriends() {
  const cssClasses = ["nfriend", "open"]

  return (
    <GridCard container spacing={2} className="about-nfriend" mb={'75px !important'}>
      <Grid
        item
        xs={12}
        lg={6}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        paddingTop={"0 !important"}
      >
        <ResponsiveImage src={rootImage.nFriends} />
      </Grid>

      <Grid item xs={12} lg={6} padding={0} paddingTop="0 !important" display={'flex'} alignItems="center" justifyContent={"center"}>
        <Stack
          spacing={3}
          display="flex"
          alignItems={"center"}
          justifyContent="center"
        >
          <Typography variant="h2" fontSize={"48px"}>
            {" "}
            About <strong>NFansT</strong>{" "}
          </Typography>
          <SubTitle variant="subtitle2" fontSize={"18"}>
            NFansT is an exclusive and limited community of 11,110 NFTs for fans
            of Fanaticoins. The only way to access said club is by owning an NFT
            that will function as an access key to our web 3.0 experience.
          </SubTitle>
          <SubTitle variant="subtitle2" fontSize={"18"} mt={'10px'}>
          Holders of these NFTs automatically become members of the exclusive
            club, and these are an admission ticket to an ecosystem with
            different content, benefits and VIP experiences that you would not
            be able to access otherwise.
          </SubTitle>
          <SubTitle
            variant="subtitle2"
            fontSize={"18"}
            mt='10px'
            className={cssClasses.join(" ")}
          >
            There are different levels of membership based on the rarity of the
            NFT purchased: COMMON, RARE, EXCLUSIVE, and UNIQUE.
            </SubTitle>
            <SubTitle
            variant="subtitle2"
            fontSize={"18"}
            mt='10px'
            className={cssClasses.join(" ")}
          >
            Depending on its rarity, the holder of it will be able to access
            certain content or experiences specially released for their
            membership level, and NFT holders of lower tiers may pay an extra
            fee to enjoy those benefits of higher membership levels.
          </SubTitle>
        </Stack>
      </Grid>
    </GridCard>
  )
}

export default NFriends
