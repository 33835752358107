import { useMemo, useState } from "react"
import { Box, Grid, styled, SxProps, Typography } from "@mui/material"

import { rootImage } from "../../../app/core/rootImages"
import { BasicCardContainer, ButtonWhite } from "../../../app/Custom"
import { useClock } from "../../../app/hooks/useClock"

import VideoModal from "./VideoModal"

export const LifePage = () => {
  const DateMemo = useMemo(() => new Date(new Date().setSeconds(new Date().getSeconds() + 30)).toString(), [])
  const { minutes, seconds, leading0 } = useClock(DateMemo)

  const [show, setShow] = useState(false)
  const [open, setOpen] = useState(false)


  const handleClick = () => {
    setOpen(true)
  }

  if( seconds === 50 && !show) {
    setShow(true)
  }
  
  return (
    <>
      <BasicCardContainer sx={styles}>
        <BannerLive>
          <Grid container>
            <Grid item md={6}>
              <img src={rootImage.logo.logo2} width="150px" />

              <Typography
                variant="h4"
                lineHeight={1}
                fontSize={{ md: "60px" }}
                fontWeight={"800"}
                my="45px"
              >
                Presentation of the New T-shirt for the season 2023
              </Typography>

              <ButtonWhite
                variant="contained"
                sx={{ mb: "25px" }}
                onClick={handleClick}
                disabled={minutes === 0 && seconds === 0 ? false : true}
              >
                {/* Access to the Event */}
                <Typography className="button-active" display={minutes === 0 && seconds === 0 ? 'block' : 'none'}>
                  <span id={"letter-1"}>A</span>
                  <span id={"letter-2"}>c</span>
                  <span id={"letter-3"}>c</span>
                  <span id={"letter-4"}>e</span>
                  <span id={"letter-5"}>s</span>
                  <span id={"letter-6"}>s </span>
                  <span id={"letter-7"}>t</span>
                  <span id={"letter-8"}>o </span>
                  <span id={"letter-9"}>t</span>
                  <span id={"letter-10"}>h</span>
                  <span id={"letter-11"}>e </span>
                  <span id={"letter-12"}>E</span>
                  <span id={"letter-13"}>v</span>
                  <span id={"letter-14"}>e</span>
                  <span id={"letter-15"}>n</span>
                  <span id={"letter-16"}>t</span>
                </Typography>

                {minutes === 0 && seconds === 0 ? '' : `Stream Start in ${leading0(minutes)}:${leading0(seconds)} min`}
              </ButtonWhite>

              <Typography variant="body1" lineHeight={1.3} fontSize="20px">
              NFansT is proud to present their first joint product collection of the new partnership dedicated to the Fanaticois, most notably the new Home and Away jerseys, inspired by marble, a natural, geographical and cultural element that represents us, available from today, 17th of Januar
              </Typography>
            </Grid>
          </Grid>
        </BannerLive>

        <Box mt="25px">
          <Typography variant='h4' fontWeight={'800'}>What does Live Shopping consist of?</Typography>
          <Typography
            variant="body1"
            fontSize={{ xs: "14", md: "18px" }}
            mt="15px"
          >
            Live Shopping, as the name says, is live shopping. The format is very
            similar to the well-remembered classic “Call now” but live. This
            format manages to link socialization, entertainment and shopping.
          </Typography>
          <Typography
            variant="body1"
            fontSize={{ xs: "14", md: "18px" }}
            mt="15px"
          >
            While an exclusive live is being carried out for members of the
            community presenting an event or product before its launch, they will
            be able to purchase it. Even buy the clothing that at that time a
            member of NFansT who participates in the live is using.
          </Typography>
        </Box>
      </BasicCardContainer>
      {open && <VideoModal setOpen={setOpen} open={true}/>}
    </>
  )
}

const styles: SxProps = {
  background: "#fff",
  height: "auto",
  padding: {
    md: "25px 45px",
    xs: "25px 10px",
  },
}

const BannerLive = styled(Box)(({ theme }) => ({
  backgroundImage: `url( ${rootImage.banner.bannerLiveCamisa} )`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center center",
  padding: "55px 45px",
  color: "#fff",
  minHeight: "650px",
  [theme.breakpoints.up("md")]: {
    padding: "55px 35px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "25px 10px",
  },
}))
