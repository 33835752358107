const dirIcon = "/img/icons"
const dirLogo = "/img/logo"
const dirTikects = "/img/tikects"
const dirBanner = "/img/banners"
const dirCamisa = "/img/camisa"
const dirAvatar = "/img/avatars"
const dirSocial = "/img/redes-sociales"
const dirImg = "/img"

export const rootImage = {
  icons: {
    equipo: `${dirIcon}/equipo-icon.png`,
    nft: `${dirIcon}/nft-icon.png`,
    nft1: `${dirIcon}/nft-1-icon.png`,
    parachute: `${dirIcon}/parachute-icon.png`,
    sales: `${dirIcon}/sales-icon.png`,
    common: `${dirIcon}/common.png`,
    exclusive: `${dirIcon}/exclusive.png`,
    rare: `${dirIcon}/rare.png`,
    unique: `${dirIcon}/unique.png`,
    'nft-dashboard-icon': `${dirIcon}/nft-dashboard-icon.png`,
    'shop-icon': `${dirIcon}/shop-icon.png`,
    'surveys-icon': `${dirIcon}/surveys-icon.png`,
    'life-shoping-icon': `${dirIcon}/live-shoping-icon.png`,
    exclusiveIcon: `${dirIcon}/t-shirt.png`,
    trading: `${dirIcon}/trading.png`,
    acquisition: `${dirIcon}/monedas.png`,
    consult: `${dirIcon}/consult.png`,
    collaboration: `${dirIcon}/collaboration.png`,
    tokens: `${dirIcon}/tokens.png`,
    token: `${dirIcon}/token.png`,
    star: `${dirIcon}/star.png`,
    stream: `${dirIcon}/stream.png`,
    special: `${dirIcon}/special.png`,
    singer: `${dirIcon}/singer.png`,
    auction: `${dirIcon}/auction.png`,
    alianza: `${dirIcon}/alianza.png`,
    user: `${dirIcon}/user-interface.png`,
    dasboard: {
      nftIcon: `${dirIcon}/dashboard/nft-list.png`,
      home: `${dirIcon}/dashboard/home.png`,
      logout: `${dirIcon}/dashboard/logout.png`,
      settings: `${dirIcon}/dashboard/settings.png`,
      shop: `${dirIcon}/dashboard/shop.png`,
      surveys: `${dirIcon}/dashboard/surveys.png`,
      wallet: `${dirIcon}/dashboard/wallet.png`,
      history: `${dirIcon}/dashboard/history.png`,
      lifeShoping: `${dirIcon}/dashboard/life-shoping.png`,
      news: `${dirIcon}/dashboard/news.png`,
      myProfile: `${dirIcon}/dashboard/myProfile.png`,
      message: `${dirIcon}/dashboard/message.png`,
      tickets: `${dirIcon}/dashboard/tickets.png`,
      newsIcon: `${dirIcon}/dashboard/newsIcon.png`,
      onlineStore: `${dirIcon}/dashboard/online-store.png`,
    }
  },
  logo: {
    logo1: `${dirLogo}/logo-1.png`,
    logo2: `${dirLogo}/logo-2.png`,
    logoCircular: `${dirLogo}/logo-con-fondo.png`,
    discord: `${dirLogo}/discord.png`,
    football:  {
      Liga: `${dirImg}/logo/football/liga-bbva.png`,
      barca: `${dirImg}/logo/football/barcelona.png`,
      seleccion: `${dirImg}/logo/football/seleccion.png`,
      barcelonaWithe: `${dirLogo}/football/barcelona-withe.png`,
      realmadritWithe: `${dirLogo}/football/realmadrit-withe.png`,
    }
  },
  tikects: {
    common: `${dirTikects}/common.png`,
    exclusive: `${dirTikects}/exclusive.png`,
    rare: `${dirTikects}/rare.png`,
    unique: `${dirTikects}/unique.png`,
    subasta1: `${dirTikects}/subasta1.png`,
    subasta2: `${dirTikects}/subasta2.png`,
    shakiraVip: `${dirTikects}/shakira-vip.png`,
    especialEvent: `${dirTikects}/especial-event.png`,
    home: {
      common: `${dirTikects}/home/common-home.png`,
      exclusive: `${dirTikects}/home/exclusive-home.png`,
      rare: `${dirTikects}/home/rare-home.png`,
      unique: `${dirTikects}/home/unique-home.png`,
    }
  },
  banner: {
    futBanner: `${dirBanner}/fut-banner.png`,
    tShirt: `${dirBanner}/t-shirt-banner.png`,
    estadioLiso: `${dirBanner}/estadio-liso.png`,
    bannerLive: `${dirBanner}/bg-live-shopping.png`,
    banner2Life: `${dirBanner}/stream-2.png`,
    bannerLiveCamisa: `${dirBanner}/bg-live-shopping-nuevo.jpg`,
    presale: `${dirBanner}/presale.png`,
    duete: `${dirBanner}/duete.png`,
    ads: `${dirImg}/ads.png`,
    addidas: `${dirBanner}/addidas.jpg`,
    raffles: `${dirBanner}/banner-raffles.png`,
    shakira: `${dirBanner}/banner-shakira.png`,
    shakiraSpecial: `${dirBanner}/especial-duete.png`,
    bannerAkira: `${dirBanner}/banner-new.png`,
  },
  camisas: {
    women: `${dirCamisa}/15-Soccer-Shirt-Mockup.png`,
    barca1: `${dirCamisa}/shirt-barca-1.png`,
    barca2: `${dirCamisa}/shirt-barca-2.png`,
    barca3: `${dirCamisa}/shirt-barca-3.png`,
    soccer2: `${dirCamisa}/soccer-2.png`,
    soccer3: `${dirCamisa}/soccer-3.png`,
    soccer4: `${dirCamisa}/soccer-4.png`,
    soccer5: `${dirCamisa}/soccer-5.png`,
    shakira: `${dirCamisa}/shakira.png`,
    camisaConBalon: `${dirCamisa}/camisa-con-balon.png`,
  },
  avatar: {
    shakira: `${dirAvatar}/shakira.png`,
    perfil: `${dirAvatar}/perfil.png`,
    rick: `${dirAvatar}/rick.png`,
    default: `${dirAvatar}/avatar-default.png`,
    shakiraBlanco: `${dirAvatar}/shakira-blanco.png`,
  },
  news: {
    news1: `${dirImg}/News/news1.png`,
    news2: `${dirImg}/News/news2.png`,
    news3: `${dirImg}/News/news3.png`,
    news4: `${dirImg}/News/news4.png`,
    news5: `${dirImg}/News/news5.png`,
    news6: `${dirImg}/News/news6.png`,
    news7: `${dirImg}/News/news6.png`,
  },
  redes: {
    ws: `${dirSocial}/whatsapp.png`,
    facebook: `${dirSocial}/facebook.png`,
    instagram: `${dirSocial}/instagram.png`,
    twitter: `${dirSocial}/twitter.png`,
  },
  tiket: `${dirImg}/tikets.png`,
  blurAzul: `${dirImg}/blur-azul.png`,
  nFriends: `${dirImg}/NFriends.png`,
  tiketMessi: `${dirImg}/ticket.png`,
  lentes: `${dirImg}/lentes-realidad.png`,
  fields: `${dirImg}/fields.png`,
  servers: `${dirImg}/server.png`,
  contacto: `${dirImg}/contacto.png`,
  camiseta: `${dirImg}/camiseta.png`,
  unique: `${dirImg}/unique.avif`,
  portada: `${dirImg}/portada.png`,
  moneda: `${dirImg}/moneda.png`,
  news1: `${dirImg}/news-01.png`,
  carnetDigital: `${dirImg}/carnet.png`,
  xpVip: `${dirImg}/experience-vip.png`,
  canertDisabled: `${dirImg}/carnet-disabled.png`,
}
