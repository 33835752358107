import { useDispatch, useSelector } from "react-redux"
import { Drawer, Divider } from "@mui/material"
import { CardMembershipOutlined, MapOutlined, ContactMail, Person2 } from "@mui/icons-material"
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined"

// My imports ---------------------------
import { RootState } from "../../../../store"
import { UIActions } from "../../../../store/slice/UI.slice"
import { CustomItem } from "./CustomItem"
import { ListItem } from "@mui/material"
import { Button } from "@mui/material"
import { ListSubheader } from "@mui/material"

export function SideBar() {
  const open = useSelector((state: RootState) => state.ui.openSideBar)
  const dispatch = useDispatch()

  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={() => dispatch(UIActions.toggleSideBar())}
    >
      <ListItem>
        <Button fullWidth variant="contained" color="secondary" href="/auth/login">
          Connect Wallet
        </Button>
      </ListItem>
      <Divider />

      <ListSubheader>Sections</ListSubheader>
      <CustomItem Icon={ShoppingCartOutlinedIcon} text={"Buy NFT"} url='/buy-nft'/>
      <CustomItem Icon={Person2} text={"About"} id='about' url='/#about' />
      <CustomItem Icon={CardMembershipOutlined} text={"Whitepaper"} url={'https://nfanst.gitbook.io/nfanst-exclusive-community/'} />
      <CustomItem Icon={MapOutlined} text={"Benefits"} id='benefits' url='/#membership-benefits' />
      <CustomItem Icon={MapOutlined} text={"Roadmap"}  id='roadmap' url='/#roadmap' />
      <CustomItem Icon={ShoppingCartOutlinedIcon} text={"FAQs"}  id='faqs' url='/#faqs' />
      <CustomItem Icon={ContactMail} text={"Contact"}   id='contact' url='/#contact' />
    </Drawer>
  )
}
