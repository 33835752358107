import { Paper, Box, Typography } from "@mui/material"
import { SxProps } from "@mui/material/styles"
import { rootImage } from "../../../../app/core/rootImages"

const images = [
    rootImage.camisas.soccer2,
    rootImage.camisas.soccer3,
    rootImage.camisas.women,
]

export const OtherProduct = ({ num }: { num: number }) => {
    return (
        <Box display="flex" alignItems={'center'} justifyContent="center">
            <Paper sx={cardStyle}>
                <img src={images[num - 1]} height='220px' />
                <Box>
                    <Typography variant="body1" fontWeight={'800'} textAlign='center'>Mens away shirt 22/23 purple</Typography>
                    <Typography variant="body1" textAlign='center'>Starting at $22</Typography>
                </Box>
                <Box sx={breackLine} />
            </Paper>
        </Box>
    )
}

const cardStyle: SxProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '10px 15px',
    height: '100%',
}

const breackLine: SxProps = {
    height: '1px',
    backgroundColor: 'primary.main',
    width: '100%',
    marginTop: '10px'
}