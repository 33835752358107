import { Box, Grid, Stack, Typography } from "@mui/material"

// My Imports
import CartContainer from "../../../app/components/layout/CartContainer"
import { ContactInput, TextAreaCustom } from "../../../app/components/Forms"
import { rootImage } from "../../../app/core/rootImages"
import { BtnPrimary } from "../../../app/Custom"

export const Contanto = () => {
  return (
    <CartContainer
      styles={{
        padding: "18px 25px 18px 50px",
        width: "100%",
        display: "block"
      }}
    >
      <Stack>
        <Typography fontWeight={"600"} color="white" fontSize={"28px"}>
          Contact
        </Typography>
      </Stack>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2} mb="10px">
            <Grid item xs={12} md={6}>
              <ContactInput
                placeholder={"Your name"}
                type={"text"}
                value={undefined}
                label={"Name"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ContactInput
                placeholder={"Your lastname"}
                type={"text"}
                value={undefined}
                label={"LastName"}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} mb="10px">
            <Grid item xs={12} md={6}>
              <ContactInput
                placeholder={"Email"}
                type={"email"}
                value={undefined}
                label={"Email"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ContactInput
                placeholder={"Phone"}
                type={"text"}
                value={undefined}
                label={"Phone"}
              />
            </Grid>
          </Grid>

          <TextAreaCustom />

          <footer>
            <BtnPrimary sx={{ mt: '10px' }}>Send</BtnPrimary>
          </footer>
        </Grid>

        <Grid item xs={0} md={6} display={{ xs: "none", md: "flex" }}>
          <Box
            display={"flex"}
            alignItems="center"
            justifyContent="center"
            width={"100%"}
          >
            <img
              src={rootImage.contacto}
              width={"98%"}
              style={{ margin: "0 auto", maxWidth: "400px" }}
            />
          </Box>
        </Grid>
      </Grid>
    </CartContainer>
  )
}
