import { Typography, Box, Fade } from "@mui/material"
import { useState } from "react"

// My Imports -----------------------------------------------
import { BtnSecondary } from "../../../app/Custom"
import { useHidden } from "../../../app/hooks"
import { AcordionsSection } from "./FAQScomponents/AcordionsSection"

export const Faqs = () => {
  const { checked, handleChange } = useHidden()
  const [expanded, setExpanded] = useState("")


  return (
    <Box mt={"100px"} margin={"25px  auto"} width={"100%"}>
      <Typography textAlign={"center"} variant={"h3"} fontWeight={"bolder"}>
        FAQs
      </Typography>

      <Box
        mt={"25px"}
        display="flex"
        flexDirection="column"
        gap="25px"
        justifyContent={"center"}
      > 
        <AcordionsSection setExpanded={setExpanded} onChange={expanded} />
        
        <Fade in={checked} style={{ height: checked ? "100%" : "0" }}>
          <Box>
            <AcordionsSection  setExpanded={setExpanded} onChange={expanded} start={3} end={12} />
          </Box>
        </Fade>

        <BtnSecondary sx={{ mx: "auto", mt: 5 }} onClick={handleChange}>
          <strong>{checked ? 'See less' : 'See more'}</strong>
        </BtnSecondary>
      </Box>
    </Box>
  )
}
