import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined'
import { Box, DialogTitle, Stack, DialogContent, Typography, DialogActions, Button } from '@mui/material'

export const NoAccessModal = ({ onClose, buyNfants }: Props) => {

    return (
        <Box>
            <DialogTitle>
                <Stack direction='row' alignItems={'center'} spacing={4} gap='10px'>
                    <AnnouncementOutlinedIcon color='warning' fontSize="large" />
                    You still do not have access to bid
                </Stack>
            </DialogTitle>

            <DialogContent>
                <Typography variant='subtitle1'>To participate in this auction you must purchase a one time Ticket use as is an auction for UNIQUE members tiers</Typography>
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose} variant='contained' color='error'>Close</Button>
                <Button onClick={buyNfants} variant='contained' color='secondary'>Buy Access ticket</Button>
            </DialogActions>
        </Box>
    )
}

interface Props {
    onClose(): void
    buyNfants(): void
}