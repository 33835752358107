import { Checkbox, Stack, TableBody, TableCell, TableRow } from '@mui/material'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ButtonCreateNew, StyledTableCell, StyledTableRow } from '../../../app/Custom'
import { CustomTable, EnhancedTableHead } from '../../../app/components/UI'
import { rootImage } from '../../../app/core/rootImages'
import { Data, HeadCell, Order, RowsI } from '../../../app/interfaces'
import { createData, getComparator, stableSort } from '../../../app/utils/tableActions'
import { RootState } from '../../../store'
import { tableActions } from '../../../store/slice/table.slice'

function AdminShop() {
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<keyof Data>('catgs')
  const [dense, setDense] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const { page, selected } = useSelector((state: RootState) => state.table)
  const dispatch = useDispatch()

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick: any = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(tableActions.handleSelectAllClick({ checked: event.target.checked, rows }))
  }

  const isSelected = (name: string) => selected.indexOf(name) !== -1

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  return (
    <Stack spacing={4}>
      <ButtonCreateNew>Create New</ButtonCreateNew>
      <CustomTable
        rows={rows}
        title={'Shop'}
        dense={dense}
        setDense={setDense}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      >
        <EnhancedTableHead
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={rows.length}
          headCells={headCells}
        />
        <TableBody>
          {stableSort<any>(rows, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              const isItemSelected = isSelected(row.name)
              const labelId = `enhanced-table-checkbox-${index}`

              return (
                <StyledTableRow
                  hover
                  onClick={() => dispatch(tableActions.selectNewRow({ name: row.name }))}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.name}
                  selected={isItemSelected}
                >
                  <StyledTableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        'aria-labelledby': labelId,
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center" id={labelId} scope="row" padding="none">
                    {row.name}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.desc}</StyledTableCell>
                  <StyledTableCell align="center">{row.price}</StyledTableCell>
                  <StyledTableCell align="center">{row.qtty}</StyledTableCell>
                  <StyledTableCell align="center">{row.sales}</StyledTableCell>
                  <StyledTableCell align="center">{row.date}</StyledTableCell>
                  <StyledTableCell align="center">
                    <div>
                      <img src={rootImage.avatar.default} height="50" />
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              )
            })}
          {emptyRows > 0 && (
            <TableRow
              style={{
                height: (dense ? 33 : 53) * emptyRows,
              }}
            >
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </CustomTable>
    </Stack>
  )
}

const rows: RowsI[] = [
  createData({
    name: 'Cupcake',
    img: 'k',
    desc: 'Nuevo pedido',
    price: 12,
    qtty: 12,
    sales: 'dd',
    date: String(new Date(Date.now()).toLocaleDateString()),
  }),
  createData({
    name: 'Donut',
    img: 'lkdlklkdfs',
    desc: 'Nuevo pedido',
    price: 323,
    qtty: 124,
    sales: 'dd',
    date: String(new Date(Date.now()).toLocaleDateString()),
  }),
  createData({
    name: 'Eclair',
    img: 'lkdlklkdfs',
    desc: 'Nuevo pedido',
    price: 323,
    qtty: 124,
    sales: 'dd',
    date: String(new Date(Date.now()).toLocaleDateString()),
  }),
  createData({
    name: 'Frozen yoghurt',
    img: 'lkdl',
    desc: 'Nuevo pedido',
    price: 323,
    qtty: 124,
    sales: 'dd',
    date: String(new Date(Date.now()).toLocaleDateString()),
  }),
  createData({
    name: 'Gingerbread',
    img: 'lkd',
    desc: 'Nuevo pedido',
    price: 323,
    qtty: 124,
    sales: 'dd',
    date: String(new Date(Date.now()).toLocaleDateString()),
  }),
  createData({
    name: 'Honeycomb',
    img: 'lkd',
    desc: 'Nuevo pedido',
    price: 323,
    qtty: 12,
    sales: 'dd',
    date: String(new Date(Date.now()).toLocaleDateString()),
  }),
  createData({
    name: 'Ice cream sandwich',
    img: 'lkds',
    desc: 'Nuevo pedido',
    price: 323,
    qtty: 12,
    sales: 'dd',
    date: String(new Date(Date.now()).toLocaleDateString()),
  }),
  createData({
    name: 'Jelly Bean',
    img: 'lkd',
    desc: 'Nuevo pedido',
    price: 32,
    qtty: 12,
    sales: 'dd',
    date: String(new Date(Date.now()).toLocaleDateString()),
  }),
  createData({
    name: 'KitKat',
    img: 'ff',
    desc: 'Nuevo pedido',
    price: 19,
    qtty: 12,
    sales: 'dd',
    date: String(new Date(Date.now()).toLocaleDateString()),
  }),
  createData({
    name: 'Lollipop',
    img: 'lks',
    desc: 'Nuevo pedido',
    price: 32,
    qtty: 12,
    sales: 'dd',
    date: String(new Date(Date.now()).toLocaleDateString()),
  }),
]

const headCells: HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'desc',
    numeric: false,
    disablePadding: false,
    label: 'Description',
  },
  {
    id: 'price',

    numeric: false,
    disablePadding: false,
    label: 'Price',
  },
  {
    id: 'qtty',
    numeric: false,
    disablePadding: false,
    label: 'Quantity',
  },
  {
    id: 'sales',
    numeric: false,
    disablePadding: false,
    label: 'Sales',
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: 'Date',
  },
  {
    id: 'img',
    numeric: false,
    disablePadding: false,
    label: 'Image',
  },
]

export default AdminShop
