import { styled } from "@mui/material"

export const ImageLogo = styled("img")({
  maxWidth: " 181px",
  height: "auto",
})

export const ResponsiveImage = styled("img")(({ theme }) => ({
  maxWidth: "90%",
  [theme.breakpoints.down("md")]: {
    maxWidth: "100%",
  },
}))
