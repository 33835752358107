import {
  Paper,
  Box,
  SxProps,
  styled,
  Typography,
  createTheme,
  TextField,
  Switch,
  SwitchProps,
} from "@mui/material"
import { darken, Stack } from "@mui/system"
import { LoadingButton } from '@mui/lab'
import { HeaderCard } from "./HeaderCard"
import { FormEvent } from "react"

const theme = createTheme()

export const ProfileCard = ({ handleClick, loading, saved }: Props) => {

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleClick()
  }

  return (
    <Box display={"block"} m='0 auto'>
      <Paper sx={paperStyle} elevation={3}>
        <Box sx={backgroundStyle}>
          <HeaderCard />
          {!saved && <Typography variant="body2" mt="10px">
            Complete your registration to obtain your digital ID, you will be able to share it on your social networks and presume your membership to the community!
          </Typography>}

          <Box mt="15px">
            <form onSubmit={onSubmit}>
              <Typography component={"label"} variant="body2" color="#000000a8">
                Name
              </Typography>
              <Box>
                <TextField
                  hiddenLabel
                  required
                  id="filled-hidden-label-small"
                  defaultValue=""
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Box>

              <Typography component={"label"} variant="body2" color="#000000a8">
                Last Name
              </Typography>
              <Box>
                <TextField
                  hiddenLabel
                  required
                  id="filled-hidden-label-small"
                  defaultValue=""
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Box>

              <Typography component={"label"} variant="body2" color="#000000a8">
                Email
              </Typography>
              <Box>
                <TextField
                  hiddenLabel
                  required
                  id="filled-hidden-label-small"
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Box>

              <Typography component={"label"} variant="body2" color="#000000a8">
                Number
              </Typography>
              <Box>
                <TextField
                  hiddenLabel
                  required
                  id="filled-hidden-label-small"
                  defaultValue="07 82 26 62 62"
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Box>

              <Stack direction='row' justifyContent={'flex-end'} mt='15px' alignItems={'flex-end'}>

                <ChangeButton variant="contained" size='small' loading={loading} type="submit">save</ChangeButton>
              </Stack>
            </form>
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}
interface Props {
  loading: boolean
  handleClick(): void
  saved: boolean
}

const paperStyle: SxProps = {
  padding: theme.spacing(1, "5px"),
  backgroundColor: "#ebe9e9",
  borderRadius: "8px",
  height: '100%'
}

const backgroundStyle: SxProps = {
  bgcolor: "#fff",
  borderRadius: "8px",
  maxWidth: '400px',
  padding: {
    xs: "10px",
    lg: "25px 35px",
    md: "12px 25px",
  },
  height: '100%'
}

const ChangeButton = styled(LoadingButton)(({ theme }) => ({
  background: theme.palette.secondary.main,
  color: "#FFF",
  "&:hover": {
    backgroundColor: darken(theme.palette.secondary.main, 0.125),
  },
}))

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  marginTop: '15px',
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#A31ACB",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}))

