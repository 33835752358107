import { TrendingDown, TrendingUp } from '@mui/icons-material'
import { Checkbox, Grid, Stack, TableBody, TableCell, TableRow } from '@mui/material'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CustomTable, DashboardCard, EnhancedTableHead } from '../../../app/components/UI'
import { Data, HeadCell, Order, RowsI } from '../../../app/interfaces'
import { createData, getComparator, stableSort } from '../../../app/utils/tableActions'
import { RootState } from '../../../store'
import { tableActions } from '../../../store/slice/table.slice'
import DashboardStats from './DashboardStats'

function AdminDashboard() {
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<keyof Data>('catgs')
  const [dense, setDense] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const { page, selected } = useSelector((state: RootState) => state.table)
  const dispatch = useDispatch()

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick: any = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(tableActions.handleSelectAllClick({ checked: event.target.checked, rows }))
  }

  const isSelected = (name: string) => selected.indexOf(name) !== -1

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  return (
    <Stack spacing={4} bgcolor={'rgba(245,245,245,1)'} p={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <DashboardCard
            title={'Views'}
            number={12380}
            icon={<TrendingUp color="success" />}
            percentage={'+23%'}
            time={'Today'}
            date={String(new Date(Date.now()).toDateString())}
            iconColor="green"
          >
            <DashboardStats barColor="green" />
          </DashboardCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <DashboardCard
            title={'Sales'}
            number={9241}
            icon={<TrendingDown color="error" />}
            percentage={'+21%'}
            time={'For last 7 days'}
            date={String(new Date(Date.now()).toDateString())}
            iconColor="red"
          >
            <DashboardStats barColor="red" />
          </DashboardCard>
        </Grid>
      </Grid>
      <CustomTable
        rows={rows}
        title={'Users'}
        dense={dense}
        setDense={setDense}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      >
        <EnhancedTableHead
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={rows.length}
          headCells={headCells}
        />
        <TableBody>
          {stableSort<any>(rows, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              const isItemSelected = isSelected(row.name)
              const labelId = `enhanced-table-checkbox-${index}`

              return (
                <TableRow
                  hover
                  onClick={() => dispatch(tableActions.selectNewRow({ name: row.name }))}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.name}
                  selected={isItemSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        'aria-labelledby': labelId,
                      }}
                    />
                  </TableCell>
                  <TableCell align="center" id={labelId} scope="row" padding="none">
                    {row.name}
                  </TableCell>
                  <TableCell align="center">{row.wallet}</TableCell>
                  <TableCell align="center">{row.email}</TableCell>
                  <TableCell align="center">{row.phone}</TableCell>
                  <TableCell align="center">{row.date}</TableCell>
                  <TableCell align="center">{row.purchs}</TableCell>
                </TableRow>
              )
            })}
          {emptyRows > 0 && (
            <TableRow
              style={{
                height: (dense ? 33 : 53) * emptyRows,
              }}
            >
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </CustomTable>
    </Stack>
  )
}

const rows: RowsI[] = [
  createData({
    name: 'Mayerlin G',
    wallet: '0x...782d',
    email: 'maye@gmail.com',
    phone: '+58 412-6691911',
    date: String(new Date(Date.now()).toLocaleDateString()),
    purchs: '$155',
  }),
  createData({
    name: 'Daniel G',
    wallet: '0x...782d',
    email: 'dani12@gmail.com',
    phone: '+58 412-6691911',
    date: String(new Date(Date.now()).toLocaleDateString()),
    purchs: '$155',
  }),
  createData({
    name: 'Pamela R',
    wallet: '0x...782d',
    email: 'pam@gmail.com',
    phone: '+58 412-6691911',
    date: String(new Date(Date.now()).toLocaleDateString()),
    purchs: '$155',
  }),
  createData({
    name: 'Rudy L',
    wallet: '0x...782d',
    email: 'r13@gmail.com',
    phone: '+58 412-6691911',
    date: String(new Date(Date.now()).toLocaleDateString()),
    purchs: '$155',
  }),
  createData({
    name: 'Aura T',
    wallet: '0x...782d',
    email: 'aurat@gmail.com',
    phone: '+58 412-6691911',
    date: String(new Date(Date.now()).toLocaleDateString()),
    purchs: '$155',
  }),
  createData({
    name: 'Uriel J',
    wallet: '0x...782d',
    email: 'urij@gmail.com',
    phone: '+58 412-6691911',
    date: String(new Date(Date.now()).toLocaleDateString()),
    purchs: '$155',
  }),
  createData({
    name: 'Elvis G',
    wallet: '0x...782d',
    email: 'eg@gmail.com',
    phone: '+58 412-6691911',
    date: String(new Date(Date.now()).toLocaleDateString()),
    purchs: '$155',
  }),
  createData({
    name: 'Román R',
    wallet: '0x...782d',
    email: 'rr154@gmail.com',
    phone: '+58 412-6691911',
    date: String(new Date(Date.now()).toLocaleDateString()),
    purchs: '$155',
  }),
  createData({
    name: 'Belkys G',
    wallet: '0x...782d',
    email: 'bg@gmail.com',
    phone: '+58 412-6691911',
    date: String(new Date(Date.now()).toLocaleDateString()),
    purchs: '$155',
  }),
  createData({
    name: 'Laura R',
    wallet: '0x...782d',
    email: 'lr1@gmail.com',
    phone: '+58 412-6691911',
    date: String(new Date(Date.now()).toLocaleDateString()),
    purchs: '$155',
  }),
]

const headCells: HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'wallet',
    numeric: false,
    disablePadding: false,
    label: 'Wallet',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'phone',
    numeric: false,
    disablePadding: false,
    label: 'Phone',
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: 'Date',
  },
  {
    id: 'purchs',
    numeric: false,
    disablePadding: false,
    label: 'Purchases',
  },
]

export default AdminDashboard
