import * as yup from "yup"

export const validationSchemaRegister = yup.object({
  name: yup
    .string()
    .min(3, "Must be greater than 3 characters")
    .required('The name is required'),
  lastname: yup
    .string()
    .min(3, "Must be greater than 3 characters")
    .required('The lastname is required'),
  email: yup
    .string()
    .email("It must be a valid email")
    .required("The Email is required"),
  'confirm email': yup
    .string()
    .email("It must be a valid email")
    .oneOf([yup.ref("email"), null], "the emails do not match")
    .required("You must confirm your email"),
  password: yup
    .string()
    .min(8, "Must be greater than 8 characters")
    .required("The password is required"),
  'confirm password': yup
    .string()
    .min(8, "Must be greater than 8 characters")
    .oneOf([yup.ref("password"), null], "Passwords do not match")
    .required("confirm your password"),
})


export const validationSchemaLogin = yup.object({
  email: yup
    .string()
    .email("It must be a valid email")
    .required("The Email is required"),
  password: yup
    .string()
    .min(8, "Must be greater than 8 characters")
    .required("The password is required")
})

export const validationSchemaMessage = yup.object({
  title: yup
    .string()
    .required("The title es required"),
  message: yup
    .string()
    .min(8, "Must be greater than 8 characters")
    .required("The message is required")
})

export const validationSchenaLoginForWallet = yup.object({
  name: yup
    .string()
    .min(3, "Must be greater than 3 characters")
    .required('The name is required'),
  lastname: yup
    .string()
    .min(3, "Must be greater than 3 characters")
    .required('The lastname is required'),
  email: yup
    .string()
    .email("It must be a valid email")
    .required("The Email is required")
})
