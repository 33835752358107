import { Box, BoxProps, styled, SxProps } from "@mui/material"

interface Props {
  children: JSX.Element[] | JSX.Element
  styles?: SxProps
}

const BoxGradient = styled(Box)<BoxProps>(({ theme }) => ({
  background:
    "linear-gradient(125deg, rgba(0,184,255,1) 0%, rgba(19,1,123,1) 41%, rgba(19,1,123,1) 81%, rgba(0,184,255,1) 100%)",
  borderRadius: "25px",
  padding: "55px 50px",
  color: "#fff",
  width: '100%',
  overflow: 'hidden',
  [theme.breakpoints.down("md")]: {
    padding: "55px 22px",
  },
}))

const CartContainer = ({ children, styles = {} }: Props) => {
  return (
    <Box
      display="flex"
      alignContent={"center"}
      justifyContent={"center"}
      alignItems={"center"}
      mt={"55px"}
      mb={"55px"}
      width="100%"
    >
      <BoxGradient
        sx={{
          ...styles,
        }}
      >
        {children}
      </BoxGradient>
    </Box>
  )
}

export default CartContainer
