import { Box, styled, SxProps, Typography } from "@mui/material"
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material"
import { useEffect, useState } from "react"

export const Counter = () => {
  const [counter, setCounter] = useState(1)
  const [animation, setAnimation] = useState(false)

  const price = 10.9

  useEffect(() => {
    setAnimation(true)
    setTimeout(() => {
      setAnimation(false)
    }, 510)
  }, [counter])

  return (
    <Box display={"flex"} alignItems="center" gap="20px" my="15px">
      <BoxCounter className={animation ? "gelatine-animation" : ""}>
        <Typography
          variant="body1"
          color="#000"
          fontSize={{xs: "18px", md: "28px"}}
          fontWeight="800"
        >
          {counter}
        </Typography>
        <Box sx={styles}>
          <KeyboardArrowUp
            fontSize="small"
            onClick={!animation ? () => setCounter(counter + 1) : undefined}
            sx={{ cursor: "pointer", fontSize: "18px", fontWeight: "900" }}
          />
          <KeyboardArrowDown
            fontSize="small"
            onClick={
              !animation
                ? () => {
                    if (counter >= 2) 
                      setCounter(counter - 1)
                    
                  }
                : undefined
            }
            sx={{ cursor: "pointer", fontSize: "18px", fontWeight: "900" }}
          />
        </Box>
      </BoxCounter>

      <Box>
        <Typography
          variant="h2"
          fontSize={{ xs: "28px", sm: "38px", md: "48px" ,lg: "58px"}}
          color="#fff"
          fontWeight={"800"}
        >
          {(price * counter).toFixed(2)}{" "}
          <span style={{ fontSize: "18px" }}>USD</span>
        </Typography>
      </Box>
    </Box>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const BoxCounter = styled(Box)(({ theme }) => ({
  borderRadius: "22px",
  backgroundColor: "#fff",
  padding: "5px 20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "10px",
  height: "35px",
}))

const styles: SxProps = {
  display: "grid",
}
