import {
  Link,
  LinkProps,
  styled,
  Typography,
  TypographyProps,
} from "@mui/material"

export const LinkPritter = styled(Link)<LinkProps>(({ theme }) => ({
  textDecoration: "none",
  color: "black",
  fontWeight: "normal",
  "&:hover": {
    color: theme.palette.secondary.dark,
  },
}))

export const TituloPrincipal = styled(Typography)<TypographyProps>(
  ({ theme }) => ({
    fontWeight: "500",
    [theme.breakpoints.up("xs")]: {
      fontSize: "30px",
      maxWidth: "390px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "45px",
      maxWidth: "522px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "45px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "60px",
      maxWidth: '100%'
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "4.5rem",
    },
  })
)

export const SubTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: "400",
  [theme.breakpoints.down("lg")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "18px",
  },
}))
