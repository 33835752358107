import React from "react"

export const useHidden = () => {
    const [checked, setChecked] = React.useState(false)

    const handleChange = () => {
      setChecked((prev) => !prev)
    }

    return {
        checked, 
        handleChange
    }
}
