import { Badge, Divider, List, SxProps } from "@mui/material"
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'

import { AvatarOlineUser, CustomListItem } from "../../app/components/UI"
import { rootImage } from "../../app/core/rootImages"
import { rootRouters } from "../../app/core/rootRouter"
import { useAppSelector } from "../../app/hooks"
import { useMemo } from "react"
import { useWallet } from "../../app/hooks/useWallet"
import { useNavigate } from "react-router-dom"

export const DrawerDashboardItems = () => {
  const navigate = useNavigate()

  const { message, notification } = useAppSelector(state => state)
  const { disconnect } = useWallet()

  const unReadMessages = useMemo(() => message.messages.filter( message => message.isNew ).length, [message])
  const unReadNotification = useMemo(() => notification.notifications.filter( message => !message.isRead ).length, [notification])

  const messageBage = <Badge badgeContent={unReadMessages} color="secondary" sx={{ color: '#fff' }}><img src={rootImage.icons.dasboard.message} /></Badge>
  const notifyBage = <Badge badgeContent={unReadNotification} color="secondary" sx={{ color: '#fff' }}><NotificationsNoneIcon sx={{ color: '#000' }} /></Badge>

  const onClick = () => {
    disconnect()
    navigate('/')
  }

  return (
    <>
      <AvatarOlineUser size={100} />

      <Divider sx={{ my: "10px" }} />

      <List sx={stlyed}>
        <CustomListItem
          text="Dashboard"
          url={`/dashboard${rootRouters.dashboard.home}`}
          icon={<img src={rootImage.icons.dasboard.home} />}
        />
        <CustomListItem
          text="My Profile"
          url={`/dashboard${rootRouters.dashboard.myProfile}`}
          icon={<img src={rootImage.icons.dasboard.myProfile} width="19px" />}
        />
        <CustomListItem
          text="Wallet"
          url={`/dashboard${rootRouters.dashboard.wallet}`}
          icon={<img src={rootImage.icons.dasboard.wallet} />}
        />
        <CustomListItem
          text="Marketplace"
          target="_blank"
          url={'https://marketplacenfanst.devtop.online/'}
          icon={<img src={rootImage.icons.dasboard.nftIcon} />}
        />
        <CustomListItem
          text="Store"
          url={`/dashboard${rootRouters.dashboard.shop.index}`}
          icon={<img src={rootImage.icons.dasboard.shop} />}
        />
        <CustomListItem
          text="Messages"
          url={`/dashboard/messages`}
          icon={messageBage}
        />
        <CustomListItem
          text="Special Events"
          url={`/dashboard${rootRouters.dashboard.specialEvent}`}
          icon={<img src={rootImage.icons.dasboard.lifeShoping} />}
        />
        <CustomListItem
          text="Live Shopping"
          url={`/dashboard${rootRouters.dashboard.live}`}
          icon={<img src={rootImage.icons.dasboard.history} />}
        />
        <CustomListItem
          text="News"
          url={`/dashboard${rootRouters.dashboard.news}`}
          icon={<img src={rootImage.icons.dasboard.newsIcon} width='19px' height={'19px'} />}
        />
        <CustomListItem
          text="Duets"
          url={`/dashboard-duete`}
          target='_blank'
          icon={<img src={rootImage.icons.alianza} height='19px'/>}
        />
        <CustomListItem
          text="Survey"
          url={`/dashboard${rootRouters.dashboard.survey}`}
          icon={<img src={rootImage.icons.dasboard.surveys} />}
        />
        <CustomListItem
          text="Raffles"
          url={`/dashboard${rootRouters.dashboard.raffles}`}
          icon={<img src={rootImage.icons.dasboard.tickets} />}
        />
        <CustomListItem
          text="Notifications"
          url={`/dashboard/notification`}
          icon={notifyBage}
        />
      </List>
      <Divider />

      <List onClick={onClick}>
        <CustomListItem
          text="log out"
          icon={<img src={rootImage.icons.dasboard.logout} />}
        />
      </List>
    </>
  )
}

const stlyed: SxProps = {
  flex: '1 1',
  overflowY: 'scroll',
  transition: 'all .5s',
  "::-webkit-scrollbar": {
    width: '5px',
    transition: 'all .3s',
    visibility: 'hidden'
  },
  "::-webkit-scrollbar-thumb": {
    backgroundColor: "gray",
    borderRadius: '4px',
    transition: 'all .3s',
    visibility: 'hidden'
  },
  "&:hover::-webkit-scrollbar": {
    width: '5px',
    transition: 'all .5s',
    visibility: 'visible'
  },
  "&:hover::-webkit-scrollbar-thumb": {
    backgroundColor: "gray",
    borderRadius: '4px',
    transition: 'all .5s',
    visibility: 'visible'
  },
}