import { MouseEvent, useState } from "react"
import { VisibilityOff, Visibility, HttpsOutlined } from "@mui/icons-material"
import {
  OutlinedInput,
  InputAdornment,
  IconButton,
  Box,
  FormControl,
  InputLabel,
  BoxProps,
  styled,
  FormHelperText,
  useTheme,
} from "@mui/material"

const BoxStyled = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "8px",
}))

export const PasswordInput = ({ label, id, ...props }: Props) => {
  const theme = useTheme()
  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  return (
    <BoxStyled>
      <HttpsOutlined 
        fontSize="large" 
        sx={{ 
          color: props.error ? theme.palette.error.light : "#0000008a",
          mb: props.error ? '29px' : '0px'
        }} 
        />

      <FormControl sx={{ width: "98%" }} variant="outlined">
        <InputLabel htmlFor={`${id}-password`} sx={{ top: "1px" }}>
          {label}
        </InputLabel>
        <OutlinedInput
          fullWidth
          label={label}
          id={`${id}-password`}
          type={showPassword ? "text" : "password"}
          size="medium"
          value={props.value}
          error={props.error}
          onChange={props.onChange}
          onBlur={props.onBlur}
          name={label.toLowerCase()}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                sx={{
                  color: props.error ? theme.palette.error.light : '#0000008a'
                }}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
        {!!props.error && (
          <FormHelperText error id="accountId-error">
            {props.helperText}
          </FormHelperText>
        )}
      </FormControl>
    </BoxStyled>
  )
}

interface Props {
  label: string
  id: string
  value: string
  onChange: any
  onBlur: any
  error: boolean | undefined
  helperText: string | undefined
}
