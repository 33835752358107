import { createSlice } from '@reduxjs/toolkit'
import { UserInterface } from '../../app/interfaces'
import { getLocalStorage, saveLocalStorage } from '../../app/utils'

const local: UserInterface = getLocalStorage('userData')

const initialState: UserInterface = {
   wallet: local.wallet,
   name: local.name,
   lastName: local.lastName,
   role: 1002,
   whiteList: false,
   cart: 0
}

const UserSlice = createSlice({
   name: 'user',
   initialState,
   reducers: {
      newUser(state, action) {
         const { wallet, name, lastName, role } = action.payload
         saveLocalStorage('userData', action.payload)

         state = {
            ...state,
            wallet,
            name,
            lastName,
            role
         }
      },
      setWallet (state, { payload }) {
         saveLocalStorage('userData', { ...state, wallet: payload })
         state.wallet = payload
      },
      addToCard (state) {
         state.cart = (state.cart ? state.cart : 0 ) + 1
      } 
   }
})

export const UserActions = UserSlice.actions
export const UserReducer = UserSlice.reducer