/* eslint-disable @typescript-eslint/no-unused-vars */
import { Avatar, AvatarProps, Badge, BadgeProps, Box, styled, Typography } from "@mui/material"
import { rootImage } from "../../core/rootImages"

export const AvatarOlineUser = ({ size }: Props) => {

  const CustomAvatar = styled(Avatar)<AvatarProps>(({ theme }) => ({
    height: `${size}px`,
    width: `${size}px`,
    fontSize: "45px",
    margin: "auto ",
  }))
  
  const CustomBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    "& .MuiBadge-badge": {
      bottom: "19px",
      right: "19px",
      width: "25px",
      height: "25px",
      borderRadius: "50%",
    },
  }))
  
  return (
    <Box
      mt={'10px'}
      display={"flex"}
      flexDirection="column"
      alignItems={"center"}
      justifyContent={"center"}
    >
      <CustomBadge
        color="success"
        badgeContent=""
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <CustomAvatar alt="Remy Sharp" src={rootImage.avatar.perfil}>RE</CustomAvatar>
      </CustomBadge>
      <Typography fontWeight={"600"} mt="10px" textAlign={"center"}>
        Gerardo Prieto
      </Typography>
    </Box>
  )
}
 
interface Props {
  size: number
}