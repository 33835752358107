import { Box, Stack, styled, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import { rootImage } from "../../../app/core/rootImages"

export default function SectionBuyNFT() {
  return (
    <Stack spacing={2} >
      <Typography variant="h2">
        Pre-sale <strong>NFanTs</strong>
      </Typography>

      <Box>
        <Typography variant="body1">
          NFansT NFT presale offer investors and fans an opportunity to get in on a promising new project before it is launched to the general public.
        </Typography>
        <Typography mt="15px" variant="body1">
          The earlier investors take advantage of the presale offer, the greater their chances of maximizing their returns over the long term. Once the NFT are offially launched, their value – and price – can increase exponentially, offering early adopters far better returns than investors who buy in after the presale ends.
        </Typography>
        <Typography mt="15px" variant='body1'>
          Meanwhile, buyers of pre-sale NFTs will access an exclusive auction for them that will take place a few days prior to the official launch.
        </Typography>
        <Typography mt="15px" variant='body1'>
          You can choose between 4 tiers of membership: 1.000 COMMON, 100 RARE, 10 EXCLUSIVE and 1 UNIQUE.
        </Typography>
        <Typography mt="15px" variant='body1'>
          The membership tier will allow you to have access to or not to certain content or benefits. But if you own a NFT of lower tier, you will have the chances to access to punctual benefits or auctions exclusive of higher tiers by paying a one time extra FEE.-
        </Typography>
      </Box>

      <Stack direction="row" spacing={{ md: 4, xs: 2 }} py="25px">
        <CoinsIcon src={rootImage.icons.common} />
        <CoinsIcon src={rootImage.icons.rare} />
        <CoinsIcon src={rootImage.icons.exclusive} />
        <CoinsIcon src={rootImage.icons.unique} />
      </Stack>
    </Stack>
  )
}

const CoinsIcon = styled("img")(({ theme }) => ({
  width: "19%",
}))
