// interface DataInterface {
//     id: string | number
//     data: Array<{
//         x: number | string | Date
//         y: number | string | Date
//     }>
// }

export const data: any[] = [
  {
    id: "Good",
    label: "Good",
    value: 38,
    color: "hsl(313, 70%, 50%)",
  },
  {
    id: "Very Good",
    label: "Excelent",
    value: 584,
    color: "hsl(83, 70%, 50%)",
  },
  {
    id: "Bad",
    label: "Bad",
    value: 22,
    color: "hsl(12, 70%, 50%)",
  },
  {
    id: "Very Bad",
    label: "Very Bad",
    value: 503,
    color: "hsl(281, 70%, 50%)",
  },
  {
    id: "Medicore",
    label: "Medicore",
    value: 70,
    color: "hsl(187, 70%, 50%)",
  },
]
