import { useMemo, useState } from "react"
import { Box, Dialog, Grid, IconButton, Stack, styled } from "@mui/material"

import { PerfectProducts } from "./Cards/PerfectProducts"
import { FotCombination } from "./Cards/FootCombination"
import { TegnologyContainer } from "./Tegnology/TegnologyContainer"
import { ShopCamisa } from "./Cards/ShopCamisa"
import { NFT } from "./Cards/NFT"
import { Partners } from "./Partners/Partners"
import { SubastaCard } from "./subastas/SubastaCard"
import { rootImage } from "../../../app/core/rootImages"
import { useClock } from "../../../app/hooks"
import { CancelOutlined } from "@mui/icons-material"


export const Home = () => {
  const time = useMemo(() => new Date(new Date().setSeconds( new Date().getSeconds() + 30)).toISOString(), [])
  const { seconds } = useClock(time)

  const [show, setShow] = useState(false)

  if ( seconds === 5 && !show ) 
    setShow(true)

  return (
    <>
      <Box width="100%">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={5} lg={4}>
           <NFT />
          </Grid>

          <Grid item xs={12} sm={7} lg={8}>
           <ShopCamisa />
          </Grid>
        </Grid>
      </Box>

     <TegnologyContainer />

     <Grid container mt={'15px'} spacing={2}>
        <Grid item xs={12} md={6}>
          <SubastaCard 
            imgCamisa={rootImage.camisas.camisaConBalon} 
            timeLeft={"March, 28, 2023"} 
            id={"001"} 
            text={'Official shirt and ball'}
            subText={'Shirt Worn by Kempes in the first game of the 2023 season and the ball with which he scored the three goals.'}
            price={"4000"} />
        </Grid>
        <Grid item xs={12} md={6}>
          <SubastaCard 
            imgCamisa={rootImage.xpVip} 
            timeLeft={"March, 30, 2023"} 
            id={"002"} 
            text={'Live the NFansT experience'}
            subText={'Access a day with the squad, live the pre-game practice from inside, the locker room and concentration. Travel to the stadium with the Players and watch the game from the Official Box.'}
            disabled={true}
            buttonText={'Buy a OneTime Ticket to place a Bid'}
            exclusive
            price={"5000"} />
        </Grid>
     </Grid>

      <Grid container mt={'5px'} spacing={2} rowSpacing={'25px'}>
        <Grid item xs={12} md={6}>
          <PerfectProducts />
        </Grid>
        <Grid item xs={12} md={6}>
          <FotCombination />
        </Grid>
      </Grid>

      <Partners />

      <CustomDialog open={show} onClose={() => setShow(false)}>
        <Box sx={{ background: 'transparent', position: 'relative'}}>
          <Stack direction='row' justifyContent='flex-end' position={'absolute'} top="10px" left='0' right='10px'>
            <IconButton onClick={() => setShow(false)}>
              <CancelOutlined color="error"></CancelOutlined>
            </IconButton>
          </Stack>

          <img src={rootImage.banner.addidas} width='100%' />
        </Box>
      </CustomDialog>
    </>
  )
}

const CustomDialog = styled(Dialog)(() => ({
  boxShadow: 'none',
  background: 'transparent',
  maxHeight: 'auto'
}))