import { Typography, Grid, Collapse, SxProps, Dialog, DialogActions, DialogTitle, Button, Stack } from "@mui/material"
import { KeyboardArrowDownOutlined, KeyboardArrowUp } from "@mui/icons-material"
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'

// My Imports===============================================
import { ChipBox, FormButton } from "../../../../app/Custom"
import { Boxdrop } from "./Boxdrop"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../../store"
import { CardBuyActions } from "../../../../store/slice/CardBuyNFT"
import { useWallet } from "../../../../app/hooks/useWallet"
import { useRequest } from "../../../../app/hooks"
import { apiConfig } from "../../../../api/Api.config"
import { toast } from "react-toastify"
import { authActions } from "../../../../store/slice/auth.slice"
import { useState } from "react"

export const SeleccionarCalidad = () => {
  const { type, checked } = useSelector((state: RootState) => state.cardBuyNFT)

  const { active, connect, loading } = useWallet()
  const { sendRequest, isLoading } = useRequest()

  const [isBuy, setIsBuy] = useState(false)

  const dispatch = useDispatch()

  const handleBuyNFT = async () => {
    if (!active) {
      await connect().then(async (res) => {
        dispatch(authActions.setWallet(res))

        const promise = new Promise((resolve) => {
          setTimeout(() => {
            resolve(
              apiConfig.post("/buyfirstnft", {
                wallet: res,
                nft: type,
              })
            )
          }, 5000)
        })

        sendRequest(promise).then(() => {
          toast.success("The nft's purchase was registered successfully")
          setIsBuy(true)
        })
      })
    } else {
      const { ethereum } = window as any

      const promise = new Promise((resolve) => {
        setTimeout(() => {
          resolve(
            apiConfig.post("/buyfirstnft", {
              wallet: ethereum.selectedAddress,
              nft: type,
            })
          )
        }, 5000)
      })

      sendRequest(promise).then(() => {
        toast.success("The nft's purchase was registered successfully")
        setIsBuy(true)
      })
    }
  }

  return (
    <>
      <Grid container mt="15px" spacing={2}>
        <Grid item xs={8} md={5} position="relative">
          <ChipBox
            bgcolor={"secondary.main"}
            onClick={() => dispatch(CardBuyActions.toggleBoxdrop())}
            sx={{ position: "relative", zIndex: "10", cursor: "pointer" }}
          >
            <Typography
              variant="h5"
              color={"white"}
              fontWeight="800"
              textAlign={"center"}
              ml="10px"
            >
              {type}
            </Typography>

            {checked ? (
              <KeyboardArrowUp fontSize="large" sx={{ color: "#fff" }} />
            ) : (
              <KeyboardArrowDownOutlined
                fontSize="large"
                sx={{ color: "#fff" }}
              />
            )}
          </ChipBox>
          <Collapse in={checked} sx={styles}>
            <Boxdrop />
          </Collapse>
        </Grid>
        <Grid item xs={0} md={2}></Grid>
        <Grid item xs={12} md={5}>
          <FormButton
            fullWidth
            sx={{
              textAlign: "center",
              borderRadius: "25px",
              marginTop: "0 !important",
            }}
            loading={isLoading || loading}
            onClick={handleBuyNFT}
            size="large"
            variant="contained"
            color="primary"
          >
            Buy
          </FormButton>
        </Grid>
      </Grid>
      
      <Dialog
            open={isBuy}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title"> 
              <Stack >
                <CheckCircleOutlinedIcon color='success' fontSize="large" sx={{ fontSize: '48px', margin: 'auto' }} /> 
                Now you have access to the exclusive NFanst community.
              </Stack>
            </DialogTitle>
            <DialogActions>
                <Button href="/dashboard/" variant='contained' color='secondary'>Access</Button>
            </DialogActions>
        </Dialog>
    </>
  )
}

const styles: SxProps = {
  top: "20px",
  left: "12px",
  position: "absolute",
  zIndex: "5",
  width: "90%",
}
