import { Box, BoxProps, Grid, styled } from '@mui/material'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { useRequest } from '../../../app/hooks'
import { Member } from './member/Member'
import { ProfileCard } from './profileCard/ProfileCard'

export const MyProfile = () => {
    const  { isLoading, sendRequest } = useRequest()
    const [saved, setSaved] = useState(false)

    const handleSave = () => {
        const promise = new Promise((res) => {
            setTimeout(() =>  res(true) , 8000)
        })


        sendRequest(promise).then((res) => {
            if (res) {
                toast.success('Profile save')
                setSaved(true)
            }else {
                toast.warning('ups, algo salio mal')
            }
        })
    }

    return (
        <BasicCardContainer sx={{ background: '#fff' }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} display='flex'>
                    <ProfileCard handleClick={handleSave} loading={isLoading}  saved={saved}/>
                </Grid>

                <Grid item xs={12} md={6} display='flex'>
                  <Member saved={saved} />
                </Grid>
            </Grid>
        </BasicCardContainer>
    )
}

export const BasicCardContainer = styled(Box)<BoxProps>(({ theme }) => ({
    borderRadius: '23px',
    padding: '33px 55px',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      padding: '33px 12px',
    },
  }))
  