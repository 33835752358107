import { useNavigate } from "react-router-dom"

export const NewsCard = ({ newsData, id }: Props) => {
  const { title, date, description } = newsData
  const navigate = useNavigate()

  return (
    <>
      <figure className="snip1493" onClick={() => navigate(`/dashboard/news/${id}`)}>
        <div className="image">
          <img
            src={newsData.images[0]}
            alt="ls-sample1"
            height={'215px'}
          />
        </div>
        <figcaption>
          <div className="date">
            <span className="day">{date.day}</span>
            <span className="month">{date.month}</span>
          </div>
          <h3>{title}</h3>
          <p>
            {description}
          </p>
          <footer>
            <div className="views">
              <i className="ion-eye"></i>{newsData.views}
            </div>
            <div className="love">
              <i className="ion-heart"></i>{newsData.likes}
            </div>
            <div className="comments">
              <i className="ion-chatboxes"></i>{newsData.comments}
            </div>
          </footer>
        </figcaption>
      </figure>
    </>
  )
}

interface Props {
  newsData: {
    date: {
      day: string
      month: string
    },
    title: string
    description: string
    views: string | number,
    likes: number,
    comments: number,
    images: string[]
  },
  id: string | number
}