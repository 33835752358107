/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react"
import InputUnstyled from "@mui/base/InputUnstyled"
import { styled } from "@mui/system"
import { Typography } from "@mui/material"

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
}

const grey = {
  50: "#f6f8fa",
  100: "#eaeef2",
  200: "#d0d7de",
  300: "#afb8c1",
  400: "#8c959f",
  500: "#6e7781",
  600: "#57606a",
  700: "#424a53",
  800: "#32383f",
  900: "#24292f",
}

const StyledInputElement = styled("input")(
  ({ theme }) => `
  width: 98%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 12px;
  border-radius: 12px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  box-shadow: 0px 4px 30px

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    outline: 3px solid ${theme.palette.mode === "dark" ? blue[500] : blue[200]};
  }
`
)

const CustomInput = React.forwardRef(function CustomInput(
  props: React.InputHTMLAttributes<HTMLInputElement>,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <InputUnstyled slots={{ input: StyledInputElement }} {...props} ref={ref} />
  )
})

export function ContactInput({ placeholder, type, value, label, required = false }: Props) {
  return (
    <>
      <Typography sx={{ color: "#fff" }}>{label}</Typography>
      <CustomInput
        aria-label={`input-${type}`}
        placeholder={placeholder}
        type={type}
        value={value}
        required={required}
      />
    </>
  )
}

interface Props {
  placeholder: string
  type: string
  value: any
  label: string
  required?: boolean
}
