import { Checkbox, Stack, TableBody, TableCell, TableRow } from '@mui/material'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ButtonCreateNew, StyledTableCell, StyledTableRow } from '../../../app/Custom'
import { CustomTable, EnhancedTableHead } from '../../../app/components/UI'
import { Data, HeadCell, Order, RowsI } from '../../../app/interfaces'
import { createData, getComparator, stableSort } from '../../../app/utils/tableActions'
import { RootState } from '../../../store'
import { tableActions } from '../../../store/slice/table.slice'

function AdminNews() {
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<keyof Data>('catgs')
  const [dense, setDense] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const { page, selected } = useSelector((state: RootState) => state.table)
  const dispatch = useDispatch()

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick: any = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(tableActions.handleSelectAllClick({ checked: event.target.checked, rows }))
  }

  const isSelected = (name: string) => selected.indexOf(name) !== -1

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  return (
    <Stack spacing={4}>
      <ButtonCreateNew>Create New</ButtonCreateNew>
      <CustomTable
        rows={rows}
        title={'News'}
        dense={dense}
        setDense={setDense}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      >
        <EnhancedTableHead
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={rows.length}
          headCells={headCells}
        />
        <TableBody>
          {stableSort<any>(rows, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              const isItemSelected = isSelected(row.title)
              const labelId = `enhanced-table-checkbox-${index}`

              return (
                <StyledTableRow
                  hover
                  onClick={() => dispatch(tableActions.selectNewRow({ name: row.title }))}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.title}
                  selected={isItemSelected}
                >
                  <StyledTableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        'aria-labelledby': labelId,
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center" id={labelId} scope="row" padding="none">
                    {row.title}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.author}</StyledTableCell>
                  <StyledTableCell align="center">{row.catgs}</StyledTableCell>
                  <StyledTableCell align="center">{row.tags}</StyledTableCell>
                  <StyledTableCell align="center">{row.date}</StyledTableCell>
                </StyledTableRow>
              )
            })}
          {emptyRows > 0 && (
            <TableRow
              style={{
                height: (dense ? 33 : 53) * emptyRows,
              }}
            >
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </CustomTable>
    </Stack>
  )
}

const rows: RowsI[] = [
  createData({
    title: 'Cupcake',
    author: 'Romario D',
    catgs: 'Compras',
    tags: 'ddd',
    date: String(new Date(Date.now()).toLocaleDateString()),
  }),
  createData({
    title: 'Donut',
    author: 'María V',
    catgs: 'Compras',
    tags: '3234',
    date: String(new Date(Date.now()).toLocaleDateString()),
  }),
  createData({
    title: 'Eclair',
    author: 'Román T',
    catgs: 'Compras',
    tags: '3234',
    date: String(new Date(Date.now()).toLocaleDateString()),
  }),
  createData({
    title: 'Frozen yoghurt',
    author: 'Raúl T',
    catgs: 'Compras',
    tags: '3234',
    date: String(new Date(Date.now()).toLocaleDateString()),
  }),
  createData({
    title: 'Gingerbread',
    author: 'Oscar B',
    catgs: 'Compras',
    tags: '3234',
    date: String(new Date(Date.now()).toLocaleDateString()),
  }),
  createData({
    title: 'Honeycomb',
    author: 'Miguel A',
    catgs: 'Compras',
    tags: '3234',
    date: String(new Date(Date.now()).toLocaleDateString()),
  }),
  createData({
    title: 'Ice cream sandwich',
    author: 'Tristán R',
    catgs: 'Compras',
    tags: '3234',
    date: String(new Date(Date.now()).toLocaleDateString()),
  }),
  createData({
    title: 'Jelly Bean',
    author: 'Oviel R',
    catgs: 'Compras',
    tags: '3234',
    date: String(new Date(Date.now()).toLocaleDateString()),
  }),
  createData({
    title: 'KitKat',
    author: 'Martín M',
    catgs: 'Compras',
    tags: '',
    date: String(new Date(Date.now()).toLocaleDateString()),
  }),
  createData({
    title: 'Lollipop',
    author: 'Eduard T',
    catgs: 'Compras',
    tags: '3234ff',
    date: String(new Date(Date.now()).toLocaleDateString()),
  }),
]

const headCells: HeadCell[] = [
  {
    id: 'title',
    numeric: false,
    disablePadding: true,
    label: 'Title',
  },
  {
    id: 'author',
    numeric: false,
    disablePadding: false,
    label: 'Author',
  },
  {
    id: 'catgs',
    numeric: false,
    disablePadding: false,
    label: 'Categories',
  },
  {
    id: 'tags',
    numeric: false,
    disablePadding: false,
    label: 'Tags',
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: 'Date',
  },
]

export default AdminNews
