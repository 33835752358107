import { Menu } from '@mui/icons-material'
import { IconButton, Stack } from '@mui/material'
import { Link } from 'react-router-dom'

import { shortWallet } from '../../../../wallet/ConnectWallet'
import { BtnPrimary } from '../../../Custom'
import { useAppSelector } from '../../../hooks'

export const NavDashboard: any = ({ setOpen }: Props) => {
    const { wallet } = useAppSelector( state => state.auth )

    return (
        <Stack
            direction="row"
            width="100%"
            justifyContent={'space-between'}
            mb={{ lg: '25px', xs: '35px' }}
        >
            <IconButton
                onClick={() => setOpen(true)}
                sx={{ visibility: { lg: 'hidden', sx: 'visible' } }}
            >
                <Menu />
            </IconButton>
            <Link to='/dashboard/my-profile' style={{ textDecoration: 'none', color: '#fff' }}>
                <BtnPrimary variant="contained" color="secondary" sx={{ width: 'auto' }}>
                    {shortWallet(String(wallet))}
                </BtnPrimary>
            </Link>
        </Stack>
    )
}

interface Props {
    setOpen(open: boolean): void
}