/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Typography, styled, BoxProps, SxProps } from "@mui/material"
import { CSSProperties } from "react"
import { rootImage } from "../../core/rootImages"

export const BoxChip = ({ text }: Props) => {
  return (
    <Chip>
      <BoxCentrado>
        <Box sx={cicularStyles} >
          <img src={rootImage.icons.star} style={styles} />
        </Box>
        <Typography variant={'body2'} textAlign={'left'} fontSize={{ xs: '12px', md: '13px'}} fontWeight="500">{text}</Typography>
      </BoxCentrado>
    </Chip>
  )
}


interface Props {
  text: string
}

const BoxCentrado = styled(Box)<BoxProps>((props) => ({
  display: "flex",
  alignItems: "center",
  width:  '75%',
  gap: "25px",
}))

const Chip = styled(Box)<BoxProps>(({ theme }) => ({
  background: `linear-gradient(90deg, rgb(26 97 255) 0%, ${theme.palette.secondary.main} 100%)`,
  borderRadius: "65px",
  color: "#fff",
  padding: "8px 20px",
  height: '100px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  width: '90%',
  maxWidth: '400px',
  margin: 'auto'
}))

const styles: CSSProperties = {
  width: '45px',
}

const cicularStyles: SxProps = { 
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  left: '-5%',
  borderRadius: '50%',
  backgroundColor: '#fff',
  height: '60px',
  width: '60px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}
