import { createSlice } from "@reduxjs/toolkit"

type TypeNFT = "Common" | "Rare" | "Exclusive" | "Unique" | "Exclusive"
interface CardBuyI {
  type: TypeNFT
  price: string
  amount: number
  totalAmount: number
  checked: boolean
}

const NfTTypes: any = {
  common: {
    type: "common",
    price: "10 USDT",
    amount: 10000,
    totalAmount: 10000,
  },
  rare: {
    type: "rare",
    price: "20 USDT",
    amount: 1000,
    totalAmount: 1000,
  },
  exclusive: {
    type: "exclusive",
    price: "50 USDT",
    amount: 100,
    totalAmount: 100,
  },
  unique: {
    type: "unique",
    price: "100 USDT",
    amount: 10,
    totalAmount: 10,
  },
}

const initialState: CardBuyI = {
  type: "Common",
  price: "10 USDT",
  amount: 10000,
  totalAmount: 10000,
  checked: false,
}

const CardBuySlice = createSlice({
  name: "card buy",
  initialState,
  reducers: {
    changeNFT(state, actions) {
      const { type } = actions.payload

      state.type =
        NfTTypes[type].type.split("").shift()?.toUpperCase() +
        NfTTypes[type].type.split("").slice(1).join("")
      state.price = NfTTypes[type].price
      state.amount = NfTTypes[type].amount
      state.totalAmount = NfTTypes[type].totalAmount
    },
    toggleBoxdrop(state) {
      state.checked = !state.checked
    },
  },
})

export const CardBuyActions = CardBuySlice.actions
export const CardBuyReducer = CardBuySlice.reducer
