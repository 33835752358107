import { CSSProperties } from "react"
import { Box } from "@mui/material"

export function ContenedorCentrado({ children, sx = {} }: Props) {
  return (
    <Box
      maxWidth={"1400px"}
      sx={{
        ...sx,
        width: {
          xs: "90%",
          md: "85%",
          xl: "85%",
        },
        margin: "0 auto 0",
      }}
    >
      {children}
    </Box>
  )
}

interface Props {
  children: JSX.Element | JSX.Element[]
  sx?: CSSProperties
}
