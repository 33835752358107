import { Box, Paper, Stack, styled } from "@mui/material"
import { rootImage } from "../../../../app/core/rootImages"

export const Member = ({ saved }: Props) => {

  const img = [rootImage.canertDisabled, rootImage.carnetDigital]

  const url = saved ? img[1] : img[0]


  return (
    <Box display={"block"} margin=' 0 auto'>
      <PaperGray elevation={3}>
        <Stack>
          <img src={url} style={{ margin: 'auto', maxWidth: '100%', maxHeight: '430px' }} />

          <Box mt="10px">
            <Stack
              direction={"row"}
              justifyContent="space-between"
              m="0 auto"
              maxWidth={"250px"}
            >
              <img width={"45px"} src={rootImage.redes.facebook} />
              <img width={"45px"} src={rootImage.redes.ws} />
              <img width={"45px"} src={rootImage.redes.instagram} />
              <img width={"45px"} src={rootImage.redes.twitter} />
            </Stack>
          </Box>
        </Stack>
      </PaperGray>
    </Box>
  )
}

interface Props {
    saved: boolean
}

const PaperGray = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.business.lightBackground,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2, 4),
  height: "100%"
}))

