import * as React from "react"
import { useFormik } from "formik"
import Button from "@mui/material/Button"
import { styled } from "@mui/material/styles"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import { PermIdentityOutlined, EmailOutlined } from "@mui/icons-material"
import { DobleInput, NormalInput, validationSchenaLoginForWallet } from "../../Forms"
import FormGroup from "@mui/material/FormGroup/FormGroup"
import { CircularProgress, FormControlLabel, Stack, Switch } from "@mui/material"
import { toast } from "react-toastify"
import { useNavigate } from "react-router"
import { useRequest } from "../../../hooks"
import { apiConfig } from "../../../../api/Api.config"
import { useDispatch } from "react-redux"
import { UserActions } from "../../../../store/slice/User.slice"

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}))

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  // onClose: () => void
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
    </DialogTitle>
  )
}

export function CustomizedDialogs({ setOpen, open, wallet }: Props) {
  const dispatch = useDispatch()
  const navigate = useNavigate() 
  
  const { sendRequest, isLoading } = useRequest()

  const handleClose = () => {
    setOpen(false)
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      lastname: "",
      email: "",
      admin: true,
    },
    validationSchema: validationSchenaLoginForWallet,
    onSubmit: async (values: any) => {
      const sendData = {
        "names": values.name,
        "lastnames": values.lastname,
        "email": values.email,
        "role": values.admin ? 1001 : 1002,
        wallet,
      }

      await sendRequest(apiConfig.post('/signup', sendData)).then(() => {
        toast.success('Se a creado el usuario con exito')
        dispatch(UserActions.newUser({
          ...values,
          role: sendData.role,
          wallet,
        }))
        navigate('/dashboard/')
      })
      handleClose()
    },
  })

  return (
    <div>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
        >
          Datos
        </BootstrapDialogTitle>
        <form action="" onSubmit={formik.handleSubmit}>
          <DialogContent dividers sx={{ px: '10px' }}>
            <Stack spacing={2}>
              <DobleInput
                Icon={PermIdentityOutlined}
                label1={"Name"}
                label2={"Lastname"}
                value={formik.values.name}
                value2={formik.values.lastname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                error2={
                  formik.touched.lastname && Boolean(formik.errors.lastname)
                }
              />

              <NormalInput
                Icon={EmailOutlined}
                label={"Email"}
                type={"Email"}
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched["email"] && Boolean(formik.errors["email"])
                }
                helperText={formik.errors.email}
              />

              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      name="admin"
                      onChange={formik.handleChange}
                      value={formik.values.admin}
                    />
                  }
                  label={"Admin"}
                />
              </FormGroup>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button autoFocus variant='contained' color='secondary' type="submit">
                { 
                  isLoading ? <CircularProgress /> : 'Save'
                }
            </Button>
          </DialogActions>
        </form>

      </BootstrapDialog>
    </div>
  )
}

interface Props {
  setOpen(props: boolean): void
  open: boolean
  wallet: string | null | undefined
}
