import { createSlice} from '@reduxjs/toolkit'

const initialState = {
   whiteList: false,
   isActive: false,
   isValidWallet: false,
   wallet: '',
   useId: '',
}

const authSlice = createSlice({
   name: 'auth',
   initialState,
   reducers: {
    isActiveMetamask (state, { payload }) {
       state.isActive = payload
    },
    setWhiteList (state, { payload }) {
       state.whiteList = payload
    },
    setWallet (state, { payload }) {
        state.wallet = payload
    },
    setId (state, { payload }) {
      state.useId = payload
    }
   }
 })

export const authActions = authSlice.actions
export const authReducer = authSlice.reducer