import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'
import { WarningOutlined } from '@mui/icons-material'
import LoadingButton from '@mui/lab/LoadingButton'
import Typography from '@mui/material/Typography'


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function AlertDialogSlide({ loading, onClick, title, subTitle }: Props) {

  const handleClick = () => {
    onClick()
  }  

  return (
      <Dialog
        open={true}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle display={'flex'} alignItems='center' gap='10px'>
            <WarningOutlined color="error" fontSize='large' />
            Warning
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            { title }


          </DialogContentText>
          <DialogContentText mt="10" display={loading ? 'block' : 'none'}>
            <Typography color="secondary">Please wait...</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton  
            variant='contained'
            color="secondary"
            loading={loading}
            onClick={handleClick}
            >
           { subTitle || 'Connect Wallet'}
          </LoadingButton>
        </DialogActions>
      </Dialog>
  )
}

interface Props {
    loading: boolean
    onClick(): any
    title: string
    subTitle?: string
}