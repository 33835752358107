import {
  Box,
  FormControlLabel,
  Paper,
  Switch,
  Table,
  TableContainer,
  TablePagination,
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../store'
import { tableActions } from '../../../../store/slice/table.slice'
import { EnhancedTableToolbar } from './TableToolbar'

export default function CustomTable({
  children,
  rows = [],
  title,
  dense,
  setDense,
  rowsPerPage,
  setRowsPerPage,
}: Props) {
  const { page, selected } = useSelector((state: RootState) => state.table)
  const dispatch = useDispatch()

  const handleChangePage = (event: unknown, newPage: number) => {
    dispatch(tableActions.handlePage({ newPage }))
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    dispatch(tableActions.handlePage({ newPage: 0 }))
  }

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} title={title} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            {children}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </Box>
  )
}

interface Props {
  rows?: any[]
  title?: string
  children?: JSX.Element | JSX.Element[]
  dense: any
  setDense: any
  rowsPerPage: any
  setRowsPerPage: any
}
