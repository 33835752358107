import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListItem,
  SxProps,
} from "@mui/material"
import { Link, useLocation } from "react-router-dom"

export const CustomListItem = ({ text, icon, url = null, target = '' }: Props) => {
  const location = useLocation()
  console.log(location.pathname.split('/')[2], location.pathname.split('/')[2])
  return (
    <ListItem disablePadding sx={  url?.split('/')[2] === location.pathname.split('/')[2] ? currentStyle : {}} style={{ transition: 'all .2s'}}>
      <Link to={url ? url : ''} target={target} style={{  width: '100%', textDecoration: "none", color: "#000"}}>
        <ListItemButton>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={text} sx={{ minWidth: '120px' }} />
        </ListItemButton>
      </Link>
    </ListItem>
  )
}

interface Props {
  text: string
  icon: any
  url?: string | null
  target?: '_blank' | string
}

const currentStyle: SxProps = {
  borderLeft: '#00b8ff solid 8px',
  px: '2px'
}