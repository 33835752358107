import React, { CSSProperties } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { Grid, IconButton } from "@mui/material"
import { Stack } from "@mui/material"
import ListIcon from "@mui/icons-material/List"

// My imports -----------------------------------------------
import { rootImage } from "../../../core/rootImages"
import { ImageLogo, LinkPritter, BtnPrimary } from "../../../Custom"
import { UIActions } from "../../../../store/slice/UI.slice"
import { useWallet } from "../../../hooks/useWallet"
import { useAppSelector, useRequest } from "../../../hooks"
import { apiConfig } from "../../../../api/Api.config"
import { shortWallet } from "../../../../wallet/ConnectWallet"
import { authActions } from "../../../../store/slice/auth.slice"

export const Navbar:any = () => {
  const auth = useAppSelector(state => state.auth)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { active, connect } = useWallet()
  const { sendRequest } = useRequest()


  const connectWallet = async(event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    if (active) {
      return navigate('/dashboard/')
    }

    await connect().then(async (res) => {
      console.log(res)
      const { ethereum } = window as any
      const currentWallet = ethereum.selectedAddress

      dispatch(authActions.setWallet(currentWallet))

      await sendRequest(apiConfig.get(`/validatewallet/${currentWallet}`)).then(() => {
        navigate('/dashboard/')
      })
    })
  }

  const scroll = (id: string) => {    
    setTimeout(() => {
      const element = document.getElementById(id) 
      if(element)
        element?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    })
  } 

  return (
    <>
      <Grid container sx={styles}>
        <Grid
          item
          xs={12}
          md={4}
          lg={2}
          display={"flex"}
          justifyContent="space-between"
          alignItems={"center"}
          className="nav-bar"
        >
          <Link to="/">
            <ImageLogo src={rootImage.logo.logo1} />
          </Link>
          <IconButton
            onClick={() => dispatch(UIActions.toggleSideBar())}
            sx={{ display: { sx: "block", md: "none" } }}
          >
            <ListIcon fontSize="large" />
          </IconButton>
        </Grid>

        <Grid
          item
          xs={0}
          md={12}
          lg={10}
          display="flex"
          justifyContent={"flex-end"}>
          <Stack
            sx={{ display: { md: "flex", xs: "none" }, alignItems: "center" }}
            direction={"row"}
            spacing={{ lg: 3 }}
            width={{ xs: '100%', lg: 'auto' }}
            justifyContent={'space-between'}
          >
            <Link to="/buy-nft" style={{ color: '#000', textDecoration: 'none'}}>
              Buy NFanst
            </Link>
            <Link onClick={() => scroll('about')} style={{ color: '#000', textDecoration: 'none' }} to="/#about">About</Link>
            <Link  style={{ color: '#000', textDecoration: 'none' }} to="https://nfanst.gitbook.io/nfanst-exclusive-community/" target={"_blank"}>Whitepaper</Link>
            <Link onClick={() => scroll('membership-benefits')} style={{ color: '#000', textDecoration: 'none' }} to="/#membership-benefits">
              Benefits
            </Link>
            <Link onClick={() => scroll('roadmap')} style={{ color: '#000', textDecoration: 'none' }} to="/#roadmap">Roadmap</Link>
            <Link onClick={() => scroll('FAQs')} style={{ color: '#000', textDecoration: 'none' }} to="/#FAQs">FAQs</Link>
            <Link onClick={() => scroll('contact')} style={{ color: '#000', textDecoration: 'none' }} to="/#contact">Contact</Link>

            <BtnPrimary
              variant="contained"
              size="large"
              onClick={connectWallet}
              sx={{ width: "auto" }}
            >
              { active ? shortWallet(String(auth.wallet)) : "Connect Wallet"}
            </BtnPrimary>
          </Stack>
        </Grid>
      </Grid>
    </>
  )
}

const styles: CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  padding: "15px 0 0 0",
  alignItems: "center",
}