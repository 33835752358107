import {
  createTheme,
  Stack,
  Button,
  darken,
  Avatar,
  styled,
} from "@mui/material"
import { DeleteOutline } from "@mui/icons-material"
import { rootImage } from "../../../../app/core/rootImages"



export const HeaderCard = () => {
  function importData() {
    const input: any = document.createElement("input")
    input.type = "file"
    input.onchange = (e: any) => {
      const files: Blob[] = Array.from(input?.files)
      handleFiles(files)
      const url = URL.createObjectURL(files[0])

      console.log(url)
      console.log(files)
      console.log(e)
    }
    input.click()
  }

  return (
    <Header>
      <AvatarUser  alt="Raul" id='img-previwe' src={rootImage.avatar.perfil} >
    
      </AvatarUser>

      <Stack spacing={1} justifyContent="center">
        <ChangeButton size="small" variant="contained" type="button" onClick={importData}>
          Change phote
        </ChangeButton>
        <DeleteButton
          size="small"
          variant="contained"
          color="secondary"
          startIcon={<DeleteOutline />}
        >
          Delete
        </DeleteButton>
      </Stack>
    </Header>
  )
}

function handleFiles(files: any) {
  for (let i = 0; i < files.length; i++) {
    const file = files[i]
    const imageType = /image.*/

    if (!file.type.match(imageType)) {
      continue
    }

    const img: any = document.createElement("img")
    img.style.width = '110px'
    img.style.height = '110px'

    img.classList.add("obj")
    img.file = file

    const preview = document.querySelector('#img-previwe')
    preview!.innerHTML = ''
    preview!.appendChild(img)

    const reader = new FileReader()
    reader.onload = (function(aImg:any) { return function(e:any) { aImg.src = e.target.result } })(img)
    reader.readAsDataURL(file)
  }
}

const theme = createTheme()

const Header = styled("header")(({ theme }) => ({
  display: "flex",
  marginBottom: theme.spacing(1),
  [theme.breakpoints.up("xs")]: {
    gap: theme.spacing(1),
  },
  [theme.breakpoints.up("md")]: {
    gap: theme.spacing(4),
  },
}))

const DeleteButton = styled(Button)(({ theme }) => ({
  background: "#FFF",
  color: "#000000a8",
  boxShadow: theme.shadows[3],
  "&:hover": {
    backgroundColor: darken("#fff", 0.125),
  },
}))

const ChangeButton = styled(Button)(({ theme }) => ({
  background: theme.palette.secondary.main,
  color: "#FFF",
  "&:hover": {
    backgroundColor: darken(theme.palette.secondary.main, 0.125),
  },
}))

const AvatarUser = styled(Avatar)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    height: "90px",
    width: "90px ",
  },
  [theme.breakpoints.up("lg")]: {
    height: "110px",
    width: "110px ",
  },
}))
