import { Navbar, SideBar } from "../UI"
import { AppFooter } from "./AppFooter"
import { ContenedorCentrado } from "./ContenedorCentrado"

export function LayoutHomePage({ children }: Props): JSX.Element {
  return (
    <>
      <ContenedorCentrado>
        <Navbar />
        <SideBar />
      </ContenedorCentrado>

      {children}
      <AppFooter />
    </>
  )
}

interface Props {
  children: JSX.Element | JSX.Element[]
}
