import { InfoOutlined } from '@mui/icons-material'
import { Box, DialogTitle, Stack, DialogContent, CircularProgress, Typography } from '@mui/material'

export const LoadingModal = () => {
    return (
        <Box>
            <DialogTitle>
                <Stack direction='row' alignItems={'center'} spacing={4} gap='10px'>
                    <InfoOutlined color='info' fontSize="large"></InfoOutlined>
                        Auction: data is being loaded
                </Stack>
            </DialogTitle>

            <DialogContent>
                <Stack maxHeight={'45px'} justifyContent='center'>
                    <CircularProgress size={'medium'} color='primary' sx={{ width: '45px', margin: 'auto' }} />
                </Stack>
                <Typography color='primary' variant='subtitle1' textAlign={'center'}>Pleas Wait...</Typography>
            </DialogContent>
        </Box>
    )
}
