import { Box, Grid, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import { rootImage } from "../../../../app/core/rootImages"
import { ChipBox } from "../../../../app/Custom"
import { RootState } from "../../../../store"

export const CardDescription = () => {
  const { amount, totalAmount, price, type } = useSelector(
    (state: RootState) => state.cardBuyNFT
  )

  return (
    <Box>
      <Box position={'relative'}>
        <img
          src={rootImage.tikects.common}
          style={{ width: "100%", paddingTop: "10px", visibility: 'hidden' }}
        />
        <img
          src={rootImage.tikects.common}
          className={`NFT-image-type ${type === 'Common' ? 'show' : 'hidden'}` }
          style={{ width: "100%", paddingTop: "10px" }}
        />
        <img
          src={rootImage.tikects.rare}
          className={`NFT-image-type ${type === 'Rare' ? 'show' : 'hidden'}` }
          style={{ width: "100%", paddingTop: "10px" }}
        />
        <img
          src={rootImage.tikects.exclusive}
          className={`NFT-image-type ${type === 'Exclusive' ? 'show' : 'hidden'}` }
          style={{ width: "100%", paddingTop: "10px" }}
        />
        <img
          src={rootImage.tikects.unique}
          className={`NFT-image-type ${type === 'Unique' ? 'show' : 'hidden'}` }
          style={{ width: "100%", paddingTop: "10px" }}
        />
      </Box>
      <Grid container mt="15px">
        <Grid item xs={12} md={5.5}>
          <Typography variant="h6" fontWeight={"900"}>
            {price}
          </Typography>
        </Grid>
        <Grid item xs={0} md={1}></Grid>

        <Grid item xs={12} md={5.5}>
          <ChipBox bgcolor={"white !important"}>
            <Typography variant="h6" fontWeight={400}>
              {type}
            </Typography>

            <Typography variant="h6" fontWeight={"500"}>
              {amount}/<strong>{totalAmount}</strong>
            </Typography>
          </ChipBox>
        </Grid>
      </Grid>
    </Box>
  )
}
